import React, { useState, useEffect } from 'react';
import IcoPage3 from '../../../assets/images/nav/ico-menu3-page.svg';
import IcoNoProject from '../../../assets/images/noData/ico-no-project.svg';
import PagingTable from '../../../components/atoms/PagingTable';
import AdminContainer from '../../../components/templates/Container/AdminContainer';
import Checkbox from '../../../components/atoms/Checkbox';
import RoundBadge from '../../../components/atoms/RoundBadge';
import TdEllipsis from '../../../components/atoms/TdEllipsis';
import NoData from '../../../components/atoms/NoData';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/atoms/Button';
import IcoFileUpload from '../../../assets/images/button/ico-file-upload.svg';
import PopupReportDownload from '../../../popup/PopupReportDownload';
import usePopupToggle from '../../../hooks/custom/usePopupToggle';
import query from 'hooks/RQuery/utils';
import { useRecoilState } from 'recoil';
import { projectDetailState } from 'store/Atoms';
import styled from 'styled-components';

const WorkPlaceList = () => {
  const companyId = sessionStorage.getItem('companyId');
  const [projectDetail, setProjectDetail] = useRecoilState(projectDetailState);
  const navigate = useNavigate();
  const [cur, setCur] = useState(1);
  const [selItem, setSelItem] = useState('');
  const [popupState, setPopupState] = useState({
    reportDown: false,
  });
  const onTogglePopup = usePopupToggle(popupState, setPopupState);
  const downUrl = query.project.useGetShp(selItem);

  // 사업장 목록 조회
  const { data } = query.project.useGetProjects({ companyId, pageNum: cur, size: 10 });
  const { content: projectList } = data || {};

  const [projects, setProjects] = useState([]); // 사업장 목록 (checked 포함)

  // 사업장 목록에 checked 추가
  useEffect(() => {
    if (projectList?.length > 0) {
      setProjects(projectList.map((ele) => ({ ...ele, checked: false })));
    }
  }, [projectList]);

  // 상세 화면으로 이동
  const onClickRow = (item) => {
    navigate(`detail?projectId=${item.projectId}`);
    setProjectDetail(item);
  };

  //누락 필지 다운로드
  useEffect(() => {
    if (downUrl.data) {
      const down = document.createElement('a');
      down.href = downUrl.data;
      down.click();
      down.remove();
      setSelItem('');
    }
  }, [downUrl.data]);
  return (
    <>
      <AdminContainer
        title={'사업장 관리'}
        icon={IcoPage3}
        headRight={
          <button type={'button'} style={{ color: '#00B074', fontSize: 16, fontWeight: 500 }} onClick={() => navigate('/workPlace/add')}>
            + 사업장 추가
          </button>
        }
        content={
          <PagingTable
            totalPages={data?.totalPages}
            currentPage={cur}
            setCurrentPage={setCur}
            caption={'사업장 목록'}
            colgroup={['auto', 260, 80, 150, 249, 150, 200]}
            hasTotal={true}
            thead={
              <tr>
                <th style={{ paddingLeft: '20px' }}>사업명</th>
                <th>발주처</th>
                <th>총 회차</th>
                <th>진행</th>
                <th>기간</th>
                <th>보고서</th>
                <th>누락필지</th>
              </tr>
            }
            tbody={
              <>
                {projects.length > 0 ? (
                  projects.map((item: any, idx) => {
                    return (
                      <tr key={`project_${cur}_${idx}`} onClick={() => onClickRow(item)}>
                        <td style={{ paddingLeft: '20px' }}>
                          <TdEllipsis str={item.projectName || '-'} />
                          {/* <TruncatedText text={item.projectName + item.projectName || '-'} /> */}
                        </td>
                        <td>{item.inspectorCompanyName || '-'}</td>
                        <td>{String(item.surveyCount) + '회차' || '-'}</td>
                        <td>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <RoundBadge num={item.inProgressSurveyNo} />
                            &nbsp; {item.status ? (item.status === 'READY' ? '대기중' : item.status === 'IN_PROGRESS' ? '진행중' : item.status === 'COMPLETED' ? '사업종료' : '') : '-'}
                          </div>
                        </td>
                        <td>
                          {item.surveyBeginDate} ~ {item.surveyEndDate}
                        </td>
                        <td>
                          <Button
                            $buttonType={'medium'}
                            text={'보고서 다운로드'}
                            icon={IcoFileUpload}
                            $iconSize={20}
                            $iconSpacing={6}
                            onClick={(e) => {
                              e.stopPropagation(); // 버블링 막기
                              onTogglePopup('reportDown');
                            }}
                          />
                        </td>
                        <td>
                          {item.status === 'IN_PROGRESS' ? (
                            <Button
                              $buttonType={'medium'}
                              text={'누락 SHP 파일 다운로드'}
                              icon={IcoFileUpload}
                              $iconSize={20}
                              $iconSpacing={6}
                              disabled={item.status !== 'IN_PROGRESS'}
                              onClick={(e) => {
                                e.stopPropagation(); // 버블링 막기
                                setSelItem(item.projectId);
                              }}
                            />
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="noHover">
                    <td colSpan={7} rowSpan={10} style={{ height: 600 }}>
                      <NoData text={'등록된 사업장이 없습니다.'} icon={IcoNoProject} color={'#3F3F3F'} />
                    </td>
                  </tr>
                )}
              </>
            }
          />
        }
      />
      {popupState.reportDown ? <PopupReportDownload onClose={() => onTogglePopup('reportDown')} projectId={selItem} /> : null}
    </>
  );
};

export default WorkPlaceList;

const TruncatedTextStyle = {
  Wrap: styled.div`
    position: relative; /* 툴팁의 위치 설정을 위해 */
    cursor: pointer;
    > .text {
      width: calc(100% - 60px);
      display: -webkit-box;
      -webkit-line-clamp: 2; /* 최대 줄 수 */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5em; /* 줄 높이 */
      max-height: 3em; /* line-height * 줄 수 */
    }
    &:hover {
      > .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
    > .tooltip {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s ease;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 10;
      background-color: #333;
      color: #fff;
      padding: 5px;
      border-radius: 4px;
      width: max-content;
      max-width: 200px;
      white-space: normal;
      line-height: 1.5;
      margin-top: 5px;
      font-size: 12px;
    }
  `,
};

function TruncatedText({ text }) {
  return (
    <TruncatedTextStyle.Wrap className="truncate-two-lines">
      <p className="text">{text}</p>
      <div className="tooltip">{text}</div> {/* 툴팁에 전체 텍스트 표시 */}
    </TruncatedTextStyle.Wrap>
  );
}
