import * as apiUrl from './apiUrl';
import { request } from '../../../service/farmEzCommon';

//팜맵 지도데이터 요청
export const farmMapData = async ({ apiKey, domain, bjdCd, mapType, apiVersion, columnType }) => {
  const { data } = await request.post(
    apiUrl.farmMap.map,
    { apiKey, domain, bjdCd, mapType, apiVersion, columnType },
    {
      params: {
        bjdCd: bjdCd,
      },
    },
  );
  return data;
};
