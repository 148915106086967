import React from 'react';
import styled from 'styled-components';

const TdEllipsisStyle = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
  > * {
    display: table-cell;

    overflow: hidden;

    text-overflow: ellipsis;
  }
`;

const TdEllipsis = ({ str }) => {
  return (
    <TdEllipsisStyle>
      <div>{str}</div>
    </TdEllipsisStyle>
  );
};

export default TdEllipsis;
