import React, { useEffect, useState } from 'react';
import './App.scss';
import Container from 'components/templates/Container/Container';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import Login, { LoginForm, SelectSite } from 'pages/Login';
import NotFound from 'pages/NotFound';

import WorkPlaceList from './pages/admin/WorkPlace/WorkPlaceList';
import WorkPlaceAdd from './pages/admin/WorkPlace/WorkPlaceAdd';
import Map from 'pages/Map';
import ClientList from './pages/admin/Client/ClientList';
import ClientAdd from './pages/admin/Client/ClientAdd';
import ClientDetail from './pages/admin/Client/ClientDetail';
import InspectorList from './pages/admin/Inspector/InspectorList';
import InspectorDetail from './pages/admin/Inspector/InspectorDetail';
import ReaderList from './pages/admin/reader/ReaderList';
import ReaderDetail from './pages/admin/reader/ReaderDetail';
import WorkPlaceDetail from './pages/admin/WorkPlace/WorkPlaceDetail';
import Step3 from 'pages/admin/WorkPlace/DetailStep/Step3';
import Step1 from 'pages/admin/WorkPlace/DetailStep/Step1';
import Step2 from 'pages/admin/WorkPlace/DetailStep/Step2';
import Confirm from './components/templates/Confirm';
import { useRecoilValue } from 'recoil';
import { confirmState } from './store/Atoms';

function App() {
  /*  --------------------- */

  // [현재] 시스템 점검 여부 상태 (예시로 하드코딩, 실제로는 API나 상태 관리로 처리)
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  useEffect(() => {
    const fetchServerStatus = async () => {
      // 예시: 서버에서 시스템 점검 여부를 받아오는 부분
      setIsMaintenanceMode(true);
    };
    //  fetchServerStatus();
  }, []);

  /*  --------------------- */
  // 1) 토큰 확인 함수
  const isAuthenticated = () => {
    const token = sessionStorage.getItem('token');
    return !!token;
  };

  // 2) 프로젝트 선택 확인 함수
  const isProjectId = () => {
    const projectId = sessionStorage.getItem('projectId');
    return !!projectId;
  };

  // 1단계) 인증정보가 없을때 보호된 컴포넌트
  const Step1ProtectedRoute = ({ element }) => {
    if (!isAuthenticated()) {
      return <Navigate to={`/login`} replace />;
    }
    return element;
  };

  // 2단계) 선택된 프로젝트가 있을때 보호된 컴포넌트
  const Step2ProtectedRoute = ({ element }) => {
    if (!isProjectId()) {
      return <Navigate to={`/login/selectSite`} replace />;
    }
    return element;
  };

  // 인증정보 + 선택된 프로젝트가 있을때 - 볼 수 없는 컴포넌트
  const PublicRoute = ({ element }) => {
    if (isAuthenticated() && isProjectId()) {
      return <Navigate to={`/`} replace />;
    }
    return element;
  };

  // --------------------- //

  // 권한 관리
  const getUserRole = () => {
    return sessionStorage.getItem('auth') || ''; // 예: ROLE_MV, ROLE_ADMIN, ROLE_INSPECTOR, ROLE_WORKER
  };

  const RoleProtectedRoute = ({ allowedRoles, element }) => {
    const role = getUserRole();
    if (!isAuthenticated()) {
      return <Navigate to="/login" replace />;
    }
    if (!allowedRoles?.includes(role)) {
      return <Navigate to="/unauthorized" replace />;
    }
    return element;
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Step1ProtectedRoute element={<Container getUserRole={getUserRole} />} />, // 2단계 인증 완료전 접근 불가
      children: [
        {
          path: '',
          element: <Map />,
        },
        {
          path: '/map',
          element: <Map />,
        },
        {
          path: '/workPlace',
          element: null,
          children: [
            { path: '', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<WorkPlaceList />} /> },
            { path: 'add', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<WorkPlaceAdd />} /> },
            // { path: '', element: <WorkPlaceList /> },
            // { path: 'add', element: <WorkPlaceAdd /> },
            // { path: 'detail', element: <WorkPlaceDetail /> },
            {
              path: 'detail',
              element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<WorkPlaceDetail />} />,
              children: [
                { path: '', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<Step1 />} /> },
                { path: 'worker', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<Step2 />} /> },
                { path: 'round', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<Step3 />} /> },
              ],
            },
            // {
            //   path: 'detail',
            //   element: <WorkPlaceDetail />,
            //   children: [
            //     { path: '', element: <Step1 /> },
            //     { path: 'worker', element: <Step2 /> },
            //     { path: 'round', element: <Step3 /> },
            //   ],
            // },
          ],
        },
        {
          path: '/client',
          element: null,
          children: [
            { path: '', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<ClientList />} /> },
            {
              path: 'add',
              element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<ClientAdd />} />,
            },
            {
              path: 'detail/:id',
              element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<ClientDetail />} />,
            },
          ],
        },
        {
          path: '/inspector',
          element: null,
          children: [
            { path: '', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<InspectorList />} /> },
            {
              path: 'detail',
              element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<InspectorDetail />} />,
            },
          ],
        },
        {
          path: '/reader',
          element: null,
          children: [
            { path: '', element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<ReaderList />} /> },
            {
              path: 'detail',
              element: <RoleProtectedRoute allowedRoles={['ROLE_ADMIN']} element={<ReaderDetail />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'login',
      element: <PublicRoute element={<Login isMaintenanceMode={isMaintenanceMode} />} />, // 로그아웃 전에 접근 불가
      children: [
        {
          path: '',
          element: <LoginForm />,
          errorElement: <div>로그인 폼</div>,
        },
        {
          path: 'selectSite',
          element: <Step1ProtectedRoute element={<SelectSite />} />, // 1단계 인증 완료전 접근 불가
          errorElement: <div>현장 선택 영역</div>,
        },
      ],
    },
    {
      path: '*', // 모든 다른 경로는 404 페이지로 처리
      element: <NotFound />,
    },
  ]);
  ////TEST!!!

  return (
    <>
      <div style={{ position: 'fixed', left: 0, top: 0, width: 0, height: 0, overflow: 'hidden' }}>
        <input type={'text'} id={'form_id'} />
        <input type={'password'} id={'form_password'} />
      </div>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
