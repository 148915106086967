import { Query, QueryKey, useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
// import usePopup from "popup/popupjs/hook/usePopup";

interface MutationProps {
  fetchApi: any;
  title?: string;
  successText?: string;
  errorText?: string;
  invalidFunc?: any;
  invalidQuery?: QueryKey[] | readonly unknown[];
  retry?: number;
}

// hook - useMutation
// 성공 & 실패 - 결과 팝업 오픈
export default function useMutateHook({ fetchApi, title, successText, invalidQuery, errorText, retry }: MutationProps): UseMutationResult<object, AxiosError, object> {
  // const { addPopup, removeCurrentPopup } = usePopup(); //결과 팝업
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: fetchApi,
    onSuccess(data, variables, context) {
      // 쿼리 무효화가 필요할때 (invalidQuery : 쿼리 배열)
      if (invalidQuery) {
        invalidQuery?.map((keyArr) => queryClient.invalidateQueries({ queryKey: [...keyArr] }));
      }
    },
    onError(error: any, variables, context) {
      // console.log(error?.response);
      // alert(`${error?.response?.data?.errorMsg || errorText}`);
    },
    retry: retry,
  });
}
