import { useQuery } from '@tanstack/react-query';
import { getAddress } from '../service/address';
import queryKeys from '../keys/queryKeys';

export function useGetAddress() {
  return useQuery({
    queryKey: [queryKeys.addressKeys.all],
    queryFn: () => getAddress(),
  });
}
