import React, { useEffect, useState } from 'react';
import AdminSubContainer, { AdminSubContainerStyle } from '../../../components/templates/Container/AdminSubContainer';
import TabContent, { NextStep, Section } from '../../../components/atoms/TabContent';
import { useRecoilState } from 'recoil';
import { formState } from '../../../store/Atoms';
import { useLocation, useNavigate } from 'react-router-dom';
import Step1 from './DetailStep/Step1';
import Step2 from './DetailStep/Step2';
import PopupPostCode from '../../../popup/PopupPostCode';
import query from '../../../hooks/RQuery/utils';

const initForm = {
  addr: '',
  deptName: '',
  email: '',
  inspectorCompanyId: '',
  name: '',
  role: '',
  stdg: '',
  tel: '',
  zipcode: null,
};

const ClientDetail = () => {
  const navigate = useNavigate();
  const [form, setForm] = useRecoilState(formState);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tabList, setTabList] = useState([
    { name: '1. 기본 정보', pass: true, active: true },
    { name: '2. 작물 정보', pass: true, active: false },
  ]);

  //팝업 on/off 관리
  const [popupState, setPopupState] = useState({
    postCode: false,
  });

  //팝업 토글
  const onTogglePopup = (key) => {
    setPopupState({ ...popupState, [key]: !popupState[key] });
  };

  //form 객체 세팅(recoil 저장)
  useEffect(() => {
    setForm(initForm);
  }, []);

  //수정모드 토글
  const onToggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <AdminSubContainer
      title={isEditMode ? '발주처 정보 수정' : '발주처 정보'}
      onNavigate={() => navigate('/client')}
      content={
        <>
          <AdminSubContainerStyle.Content>
            <TabContent
              tabList={tabList}
              setTabList={setTabList}
              type={2}
              height={'calc(100% - 102px)'}
              content={
                <>
                  {/* 탭별 on/off*/}
                  {tabList[0].active ? (
                    <>
                      <Step1 isEditMode={isEditMode} setIsEditMode={setIsEditMode} onToggleEditMode={onToggleEditMode} onTogglePopup={() => onTogglePopup('postCode')} />
                      {popupState.postCode ? <PopupPostCode onClose={() => onTogglePopup('postCode')} /> : null}
                    </>
                  ) : null}
                  {tabList[1].active ? <Step2 isEditMode={isEditMode} setIsEditMode={setIsEditMode} onToggleEditMode={onToggleEditMode} /> : null}
                </>
              }
            />
          </AdminSubContainerStyle.Content>
        </>
      }
    />
  );
};

export default ClientDetail;
