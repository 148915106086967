import * as apiUrl from './apiUrl';
import { request } from '../../../service/farmEzCommon';

// 관리자 - 발주처 목록 조회
export const getClients = async ({ companyId, pageNum, size }) => {
  const { data } = await request.get(apiUrl.client.get, { params: { companyId, pageNum: pageNum - 1, size } });
  return data.datas;
};

//발주처 추가
export const addClient = async ({ companyId, name, role, addr, zipcode, stdg, tel, email, deptName }) => {
  const { data } = await request.post(apiUrl.client.add, { companyId, name, role, addr, zipcode, stdg, tel, email, deptName });
  return data;
};

//발주처 상세정보
export const getClientDetail = async ({ inspectorCompanyId }) => {
  const { data } = await request.get(apiUrl.client.detail(inspectorCompanyId));
  return data.datas;
};

//발주처 수정
export const editClient = async ({ inspectorCompanyId, name, role, addr, zipcode, stdg, tel, email, deptName }) => {
  const { data } = await request.patch(apiUrl.client.edit(inspectorCompanyId), { inspectorCompanyId, name, role, addr, zipcode, stdg, tel, email, deptName });
  return data;
};

// 관리자 - 발주처 작물코드 조회
export const getCrops = async ({ inspectorCompanyId }) => {
  const { data } = await request.get(apiUrl.client.crops(inspectorCompanyId));
  return data.datas;
};

// 관리자 - 작물코드 추가
export const addCrops = async ({ inspectorCompanyId, inspectorCrops }) => {
  const { data } = await request.post(apiUrl.client.crops(inspectorCompanyId), { inspectorCrops });
  return data;
};

// 관리자 - 작물코드 삭제
export const removeCrops = async ({ inspectorCompanyId, inspectorCrops }) => {
  const newArr: any = [];
  inspectorCrops.map((i) => {
    if (i.inspectorCropId) {
      newArr.push(i.inspectorCropId);
    }
  });
  const { data } = await request.delete(apiUrl.client.crops(inspectorCompanyId), { data: { inspectorCropIds: newArr } });
  return data;
};

// 관리자 - 작물코드 수정
export const editCrops = async ({ inspectorCompanyId, inspectorCrops }) => {
  const { data } = await request.patch(apiUrl.client.crops(inspectorCompanyId), { inspectorCrops });
  return data;
};
