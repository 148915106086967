import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import IcoLogo from '../../../assets/images/nav/logo.svg';
import IcoMenu1 from '../../../assets/images/nav/ico-menu1.svg';
import IcoMenu1A from '../../../assets/images/nav/ico-menu1-active.svg';
import IcoMenu2 from '../../../assets/images/nav/ico-menu2.svg';
import IcoMenu2A from '../../../assets/images/nav/ico-menu2-active.svg';
import IcoMenu3 from '../../../assets/images/nav/ico-menu3.svg';
import IcoMenu3A from '../../../assets/images/nav/ico-menu3-active.svg';
import IcoMenu4 from '../../../assets/images/nav/ico-menu4.svg';
import IcoMenu4A from '../../../assets/images/nav/ico-menu4-active.svg';
import IcoMenu5 from '../../../assets/images/nav/ico-menu5.svg';
import IcoMenu5A from '../../../assets/images/nav/ico-menu5-active.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import UserSetting from '../Map/UserSetting';

const NavSt = {
  Wrap: styled.div`
    /* position: fixed;
    left: 0;
    z-index: 9; */
    width: 96px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-right: 1px solid #d9d9d9;
    box-sizing: border-box;
  `,
  Logo: styled.h1`
    height: 72px;
    border-radius: 0 0 8px 8px;
    background: #024751 url(${IcoLogo}) no-repeat center;
  `,
  Inner: styled.div`
    flex: 1;
    padding: 12px 12px 48px;
    //height: calc(100% - 96px);
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  List: styled.ul`
    width: 100%;
  `,
  Item: styled.li<{ src: string; src2: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 78px;
    color: #a5b7b7;
    font-size: 13px;
    font-weight: 500;
    border-radius: 8px;
    letter-spacing: -1px;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    &:before {
      content: '';
      width: 32px;
      height: 32px;
      background: url(${(p) => (p.src ? p.src : '')}) no-repeat center;
      margin-bottom: 6px;
    }
    &.active,
    &:hover {
      background: #ceefd480;
      color: #00b074;
      &:before {
        background: url(${(p) => (p.src2 ? p.src2 : '')}) no-repeat center;
      }
    }
  `,
};

function Navigation(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([
    { name: '판독', Ico: IcoMenu1, IcoA: IcoMenu1A, active: true, path: '' },
    { name: '발주처 관리', Ico: IcoMenu2, IcoA: IcoMenu2A, active: false, path: 'client' },
    { name: '사업장 관리', Ico: IcoMenu3, IcoA: IcoMenu3A, active: false, path: 'workPlace' },
    { name: '검수자 관리', Ico: IcoMenu4, IcoA: IcoMenu4A, active: false, path: 'inspector' },
    { name: '판독자 관리', Ico: IcoMenu5, IcoA: IcoMenu5A, active: false, path: 'reader' },
  ]);

  //   console.log(location);

  // 새로고침 후에 active 유지
  useEffect(() => {
    // 경로를 '/'로 분리한 후, 메인 경로만 가져오기
    const mainPath = location.pathname.split('/')[1]; // workPlace
    setList((prev) => {
      return [...prev].map((ele) => (ele.path === mainPath ? { ...ele, active: true } : { ...ele, active: false }));
    });
  }, [location]);

  // 메뉴 클릭
  const onClickMenu = (item) => {
    setList((prev) => {
      return [...prev].map((ele) => (ele.name === item.name ? { ...ele, active: true } : { ...ele, active: false }));
    });
    navigate(`/${item?.path}`);
  };
  return (
    <NavSt.Wrap>
      <NavSt.Logo></NavSt.Logo>
      <NavSt.Inner>
        <NavSt.List>
          {list.map((item) => {
            return (
              <NavSt.Item key={item.name} src={item.Ico} src2={item.IcoA} className={item.active ? 'active' : undefined} onClick={() => onClickMenu(item)}>
                {item.name}
              </NavSt.Item>
            );
          })}
        </NavSt.List>
        <UserSetting $isAdmin={true} />
      </NavSt.Inner>
    </NavSt.Wrap>
  );
}

export default Navigation;
