import axios from 'axios';
import { urlBuilder } from './common';

// 필지 상세 정보 조회
export const getFarmData_go = async ({ pnu }) => {
  const baseUrl = `https://apis.data.go.kr/B552895/getFarmmapService/`;
  const key = 'X%2BsCREG8A6ypgumaD9V02dcFuk9%2F0e6WMSrLcN3O1Pi02IXmD6fuyIVMADIL%2BDvu6T961N8naqCDAhgROY9ccw%3D%3D';
  const key2 = 'X+sCREG8A6ypgumaD9V02dcFuk9/0e6WMSrLcN3O1Pi02IXmD6fuyIVMADIL+Dvu6T961N8naqCDAhgROY9ccw==';

  const types = {
    area: 'getAreaBasedFarmmapInfo',
    coordinate: 'getCoordinateBasedFarmmapInfo',
  };

  const params = {
    serviceKey: key2,
    //  numOfRows: 1000,
    //  pageNo: 1,
    type: 'json', //xml
    pnuCode: pnu,
    //  positionX: 993814.3568818928,
    //  positionY: 1970429.7755634887,

    //  radius: 1000,
    //  intpCode: '02',
    //  pnwlHistory: '01',
    //  changeCode: '01',
  };

  const response = await axios.get(baseUrl + types['coordinate'], { params: params });
  console.log(response);
};

// ----  팜맵API --- //
const localKey = 'zHELONoKFUXlhfET0OkR';
const localDomain = 'http://localhost:3030/';
const farmKey = process.env.NODE_ENV === 'development' ? localKey : 'zbmZLvLvWQjHwd7Gw6qh';
const farmDomain = process.env.NODE_ENV === 'development' ? localDomain : 'http://move-map.kr/';

export const getFarmmapObj = async () => {
  const baseURL = '/farmmapApi/farmapApi.do';
  const params = {
    apiKey: farmKey,
    domain: farmDomain,
  };
  const response = await axios.get(baseURL, { params: params });
  //   console.log(response);
  console.log(response.data);

  return response.data;
};

export const getFarmData = async ({ pnu }) => {
  const baseURL = '/farmmapApi/getFarmmapDataSeachPnu.do';

  const params = {
    apiKey: farmKey,
    domain: farmDomain,
    pnu: pnu,
    apiVersion: 'v2',
  };

  const response = await axios.get(baseURL, { params: params });

  return response.data;
};

//
export const getFarmMapWFS = ({ bbox, startindex }) => {
  const baseURL = '/farmmapApi/wfs.do';

  return urlBuilder(baseURL, {
    service: 'wfs',
    version: '2.0.0',
    request: 'GetFeature',
    outputformat: 'json',
    typename: 'farm_map_api',
    bbox: bbox + ',EPSG:5186',
    //  propertyname: 'id,uid,clsf_nm,pnu,ldcg_cd,stdg_cd,stdg_addr',
    sortby: 'asc',
    startindex: startindex,
    count: 200,
    srsname: 'EPSG:5186', // 3857
    apiKey: farmKey,
    domain: farmDomain,
  });
};

export const getFarmMapData = ({ bbox, startindex }) => {
  const baseURL = '/farmmapApi/wfs.do';

  return urlBuilder(baseURL, {
    service: 'wfs',
    version: '2.0.0',
    request: 'GetFeature',
    outputformat: 'json',
    typename: 'farm_map_api',
    bbox: bbox + ',EPSG:5186',
    //  propertyname: 'id,uid,clsf_nm,pnu,ldcg_cd,stdg_cd,stdg_addr',
    sortby: 'asc',
    startindex: startindex,
    count: 200,
    srsname: 'EPSG:5186', // 3857
    apiKey: farmKey,
    domain: farmDomain,
  });
};

// 주소 리스트 API (법정동코드 기반)
// type : SIDO, SIGNGU, EMD, LI
// (팜맵 웹에서 공식적으로 제공하고 있는 API는 아님)
export const postBjdCd = async (type, searchCd = '') => {
  const form = new FormData();
  //   form.append('tableNm', 't_clfm_frm_map_2023');
  form.append('tableNm', 'farm_map_2023');
  form.append('TYPE', type); // SIGNGU
  form.append('searchCd', searchCd);

  const { data } = await axios.post('/common/getSelectExistCodeListAjax.do', form);

  return data.data;
};

// 404에러로 사용불가
// 법정동 코드를 보내서 필지 Extent 가져오는 API
// (팜맵 웹에서 공식적으로 제공하고 있는 API는 아님)
export const postFarmMapListExtent = async ({ code }) => {
  const form = new FormData();
  form.append('limit', '50');
  form.append('order', 'pnu');
  form.append('sort', 'asc');

  form.append('selJibun', code?.li?.bjcd || code?.emd?.bjcd || ''); // 최종 코드(8 또는 10자리)
  form.append('ctprvn', code?.sido?.bjcd || ''); // 시 코드 (2자리)
  form.append('signgu', code?.signgu?.bjcd || ''); // 시군구 코드 (5자리)
  form.append('emd', code?.emd?.bjcd || ''); // 읍면동 코드 (8자리)
  form.append('ri', code?.li?.bjcd || ''); // 리 코드 (10자리)

  form.append('brank', '');
  form.append('fromdt', '');
  form.append('todt', '');
  form.append('pnu', '');
  form.append('bzRgno', '');
  form.append('landCode', '');
  form.append('fromArea', '');
  form.append('toArea', '');
  form.append('selectFromFarmCnt', '');
  form.append('selectToFarmCnt', '');
  form.append('selectFromFarmArea', '');
  form.append('selectToFarmArea', '');
  form.append('selectFromFarmRatio', '');
  form.append('selectToFarmRatio', '');
  form.append('selectFromBaseArea', '');
  form.append('selectToBaseArea', '');

  form.append('dtype', 'BA2');
  form.append('yy', '2023');
  form.append('offset', '0');

  const { data } = await axios.post('/common/getFarmMapListExtentAjax.do', form);

  return data.data;
};
