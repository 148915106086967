import React, { ReactNode, SetStateAction } from 'react';
import styled from 'styled-components';
import IcoTab2A from '../../assets/images/tab/ico-tab-type2-active.svg';
import IcoTab2D from '../../assets/images/tab/ico-tab-type2-disabled.svg';
import IcoTab2 from '../../assets/images/tab/ico-tab-type2.svg';
import { useNavigate, useLocation } from 'react-router-dom';
export const TabContentStyle = {
  TabList: styled.ul<{ type: number }>`
    display: flex;
    align-items: center;
    margin-bottom: ${(p) => (p.type === 2 ? '12px' : p.type === 3 ? '24px' : '20px')};
    padding: ${(p) => (p.type === 2 ? '0 40px' : p.type === 3 ? '0 0 16px 0' : 0)};
    height: ${(p) => (p.type === 2 ? '52px' : 'auto')};
    box-sizing: border-box;
    border-bottom: 2px solid ${(p) => (p.type === 2 ? '#31C16E99' : p.type === 3 ? '#E7EAEE' : 'none')};
  `,
  TabItem: styled.li<{ type: number }>`
    position: relative;
    flex: ${(p) => (p.type === 2 || p.type === 3 ? 'none' : 1)};
    padding: ${(p) => (p.type === 3 ? '0 6px' : '0 0 0 5px')};
    color: ${(p) => (p.type === 2 ? '#00B074' : '#3f3f3f')};
    font-size: 16px;
    font-weight: ${(p) => (p.type === 2 ? 600 : 400)};
    display: flex;
    align-items: center;
    height: ${(p) => (p.type === 2 || p.type === 3 ? 'auto' : '35px')};
    &:not(:last-child) {
      margin-right: ${(p) => (p.type === 2 ? '32px' : p.type === 3 ? '12px' : '7px')};
    }
    &:before {
      display: ${(p) => (p.type === 2 ? 'block' : 'none')};
      content: '';
      width: 20px;
      height: 20px;
      background: url(${IcoTab2}) no-repeat center;
      margin-right: 6px;
    }
    &:after {
      display: ${(p) => (p.type === 2 || p.type === 3 ? 'none' : 'block')};
      position: absolute;
      left: 0;
      top: ${(p) => (p.type === 3 ? 'calc(100% + 16px)' : 'calc(100% + 3px)')};
      content: '';
      width: 100%;
      height: ${(p) => (p.type === 3 ? '2px' : '3px')};
      border-radius: 1px;
      background: #dadada;
    }
    &.pass {
      cursor: pointer;
      font-weight: 600;
      &:after {
        background: #1db983;
      }
    }
    &.active {
      font-weight: 600;
      color: ${(p) => (p.type === 2 ? '#001a21' : p.type === 3 ? '#00B074' : '#001a21')};
      &:after {
        ${(p) => p.type === 3 && 'display:block'};
      }
      &:before {
        background-image: url(${IcoTab2A});
      }
    }
    &.disabled {
      color: #a5a5a5;
      &:before {
        background-image: url(${IcoTab2D});
      }
    }
  `,
  Content: styled.div<{ type?: number; height?: number | string }>`
    height: ${(p) => (typeof p.height === 'number' ? p.height + 'px' : typeof p.height === 'string' ? p.height : p.type === 2 ? 'calc(100% - 66px)' : 'calc(100% - 93px)')};
    background: ${(p) => (p.type === 2 || p.type === 3 ? 'none' : '#fff')};
    box-shadow: ${(p) => (p.type === 2 || p.type === 3 ? 'none' : '0 1px 6px 0 rgba(16, 24, 40, 0.15);')};
    border-radius: 12px;
  `,
  Inner: styled.div<{ type?: number }>`
    height: ${(p) => (p.type === 2 || p.type === 3 ? '100%' : 'calc(100% - 48px)')};
    padding: ${(p) => (p.type === 2 ? '0 40px' : p.type === 3 ? 0 : '24px 40px')};
  `,
  ContBox: styled.div`
    height: 100%;
  `,
};

export const Section = styled.section`
  /* width: 1116px; */
  padding: 20px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #e5e5ec;
  }
  &:first-child {
    padding: 0 0 20px 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

export const NextStep = styled.div`
  margin-top: 20px;
  box-sizing: border-box;
  border-top: 2px solid rgba(49, 193, 110, 0.6);
  display: flex;
  flex-direction: row-reverse;
  padding-top: 12px;
`;

export interface TabContentProps {
  tabList: { name: string; pass: boolean; active: boolean; path?: string; disabled?: boolean }[];
  setTabList: React.Dispatch<SetStateAction<{ name: string; pass: boolean; active: boolean; path?: string; disabled?: boolean }[]>>;
  content: ReactNode;
  type?: number;
  height?: number | string;
}

const TabContent = ({ tabList, setTabList, content, type = 1, height }: TabContentProps) => {
  const navigate = useNavigate();
  //탭 클릭이벤트
  const onTabClick = (item) => {
    const newTabList = [...tabList];
    newTabList.map((i) => {
      i.active = item.name === i.name;
    });
    setTabList(newTabList);

    // path가 있는 경우
    if (item?.path) {
      navigate(item.path);
    }
  };

  return (
    <>
      <TabContentStyle.TabList type={type}>
        {tabList.map((item) => {
          return (
            <TabContentStyle.TabItem
              key={item.name}
              className={`${item.active ? 'active' : ''} ${item.pass ? 'pass' : ''} ${item?.disabled && 'disabled'}`}
              type={type}
              onClick={() => {
                if (item.pass) {
                  onTabClick(item);
                }
              }}
            >
              {item.name}
            </TabContentStyle.TabItem>
          );
        })}
      </TabContentStyle.TabList>
      <TabContentStyle.Content type={type} height={height}>
        <TabContentStyle.Inner type={type}>
          <TabContentStyle.ContBox>{content}</TabContentStyle.ContBox>
        </TabContentStyle.Inner>
      </TabContentStyle.Content>
    </>
  );
};

export default TabContent;
