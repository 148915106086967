import { useQuery } from '@tanstack/react-query';
import { uploadImages } from '../service/image';
import useMutateHook from '../../useMutateHook';
import api from '../service';

//사진파일 업로드
export function useUploadImages(files) {
  return useMutateHook({
    fetchApi: () => uploadImages(files),
    //  retry: 3,
  });
}

export function useUploadImagesFarmmap() {
  return useMutateHook({
    fetchApi: ({ surveyId, files }) => api.image.uploadFarmmapImages({ surveyId, files }),
    //  retry: 3,
  });
}
