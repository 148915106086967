import React, { useEffect, useState } from 'react';
import { NextStep, Section } from '../../../../components/atoms/TabContent';
import { LabelInputStyle } from '../../../../components/atoms/LabelInput';
import Button from '../../../../components/atoms/Button';
import ScrollTable from '../../../../components/atoms/ScrollTable';
import Checkbox from '../../../../components/atoms/Checkbox';
import TdEllipsis from '../../../../components/atoms/TdEllipsis';
import { PagingTableStyle } from '../../../../components/atoms/PagingTable';
import NoData from '../../../../components/atoms/NoData';
import IcoFileUpload from 'assets/images/button/ico-file-upload.svg';
import IcoAddUser from 'assets/images/noData/ico-user-add.svg';
import IcoStepNext from 'assets/images/button/ico-step-next.svg';
import IcoStepDone from 'assets/images/button/ico-step-done.svg';
import query from '../../../../hooks/RQuery/utils';
import PopupAddReader from '../../../../popup/PopupAddReader';
import PopupAddInspector from '../../../../popup/PopupAddInspector';
import { member } from '../../../../hooks/RQuery/service/apiUrl';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { confirmState } from '../../../../store/Atoms';
import IcoStepDoneDisabled from '../../../../assets/images/button/ico-step-done-disabled.svg';

const Step2 = () => {
  const companyId = sessionStorage.getItem('companyId');
  const { projectId } = useOutletContext<any>();
  const [confirm, setConfirm] = useRecoilState(confirmState);
  //원본
  const [originReaderList, setOriginReaderList] = useState([]);
  const [originInspectorList, setOriginInspectorList] = useState([]);

  //원본에서 수정이 적용된 데이터
  const [readerList, setReaderList] = useState([]);
  const [inspectorList, setInspectorList] = useState([]);

  //추가되는것들 담는곳
  const [addReaderList, setAddReaderList] = useState([]);
  const [addInspectorList, setAddInspectorList] = useState([]);

  //삭제되는것들 담는곳
  const [removeReaderList, setRemoveReaderList] = useState([]);
  const [removeInspectorList, setRemoveInspectorList] = useState([]);

  //등록된 판독자, 검수자 리스트
  const memberList = query.project.useGetProjectMembers(projectId);

  //판독자, 검수자 추가(저장버튼)
  const addMembers = query.project.useAddProjectMembers({ projectId: projectId, contracts: addReaderList, inspectors: addInspectorList });

  //판독자, 검수자 삭제(저장버튼)
  const removeMembers = query.project.useRemoveProjectMembers({ contracts: removeReaderList, inspectors: removeInspectorList });

  const [count, setCount] = useState(0);

  //원본과 수정본의 배열에 각각 담음
  useEffect(() => {
    if (memberList.data) {
      const datas = memberList.data;
      const newArr1: any = [];
      const newArr2: any = [];
      datas?.contracts.map((i) => {
        newArr1.push({ ...i, checked: false });
      });
      setReaderList(newArr1);
      setOriginReaderList(newArr1);
      datas?.inspectors.map((i) => {
        newArr2.push({ ...i, checked: false });
      });
      setInspectorList(newArr2);
      setOriginInspectorList(newArr2);
    }
  }, [memberList.data]);

  //팝업 상태관리
  const [popupState, setPopupState] = useState({
    reader: false,
    inspector: false,
  });

  //팝업 토글
  const onTogglePopup = (key) => {
    setPopupState({ ...popupState, [key]: !popupState[key] });
  };

  //전체 선택/해제
  const onCheckAll = (type) => {
    const newArr: any = type === 1 ? [...readerList] : [...inspectorList];
    if (newArr.length === newArr.filter(({ checked }) => checked).length) {
      newArr.map((i) => {
        i.checked = false;
      });
    } else {
      newArr.map((i) => {
        i.checked = true;
      });
    }
    type === 1 ? setReaderList(newArr) : setInspectorList(newArr);
  };

  //체크박스 클릭
  const onCheck = (type, id) => {
    const newArr: any = type === 1 ? [...readerList] : [...inspectorList];
    newArr.find((i) => i.memberId === id)!.checked = !newArr.find((i) => i.memberId === id)?.checked;
    type === 1 ? setReaderList(newArr) : setInspectorList(newArr);
  };

  //삭제버튼 클릭
  const onRemoveRow = (id, type) => {
    if (type === 'inspector') {
      const newArr = [...inspectorList];
      setInspectorList(newArr.filter(({ memberId }) => memberId !== id));
    } else if (type === 'contract') {
      const newArr = [...readerList];
      setReaderList(newArr.filter(({ memberId }) => memberId !== id));
    }
  };

  //저장버튼 클릭
  const onSave = () => {
    setConfirm({
      ...confirm,
      show: true,
      message: '변경된 사항을 저장하시겠습니까?',
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: () => {
        //원본과 비교하여 삭제할것과 추가할것 데이터 담기
        const newAddInspectorList: any = [];
        const newRemoveInspectorList: any = [];
        const newAddReaderList: any = [];
        const newRemoveReaderList: any = [];

        //원본에 없는 데이터 추출(추가된 데이터)
        inspectorList.map((i: any) => {
          if (!originInspectorList.some(({ memberId }) => memberId === i.memberId)) {
            newAddInspectorList.push(i);
          }
        });
        readerList.map((i: any) => {
          if (!originReaderList.some(({ memberId }) => memberId === i.memberId)) {
            newAddReaderList.push(i);
          }
        });
        setAddInspectorList(newAddInspectorList);
        setAddReaderList(newAddReaderList);

        //원본에서 빠진 데이터 추출(삭제된 데이터)
        originInspectorList.map((i: any) => {
          if (!inspectorList.some(({ memberId }) => memberId === i.memberId)) {
            newRemoveInspectorList.push(i);
          }
        });
        originReaderList.map((i: any) => {
          if (!readerList.some(({ memberId }) => memberId === i.memberId)) {
            newRemoveReaderList.push(i);
          }
        });
        setRemoveInspectorList(newRemoveInspectorList);
        setRemoveReaderList(newRemoveReaderList);
        setConfirm({ ...confirm, show: false });
        const promises: any = [];

        if (!!(newAddInspectorList.length + newAddReaderList.length)) {
          promises.push(
            new Promise((resolve, reject) => {
              addMembers.mutate(
                { projectId: projectId, contracts: addReaderList, inspectors: addInspectorList },
                {
                  onSuccess: (res) => {
                    resolve(res);
                  },
                  onError: (error) => {
                    reject(error);
                  },
                },
              );
            }),
          );
        }

        if (!!(newRemoveInspectorList.length + newRemoveReaderList.length)) {
          promises.push(
            new Promise((resolve, reject) => {
              removeMembers.mutate(
                { contracts: removeReaderList, inspectors: removeInspectorList },
                {
                  onSuccess: (res) => {
                    resolve(res);
                  },
                  onError: (error) => {
                    reject(error);
                  },
                },
              );
            }),
          );
        }

        Promise.all(promises)
          .then((res) => {
            console.log(res);
            setConfirm({ ...confirm, show: true, message: res[0]?.message || '변경 사항이 저장되었습니다.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
          })
          .catch((error) => {
            console.log(error);
            // 에러 처리
            setConfirm({ ...confirm, show: true, message: error?.response.data?.message || '변경 실패. 관리자에게 문의하세요.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
          });
      },
    });
  };

  return (
    <div style={{ height: '100%' }}>
      <Section style={{ height: 'calc(100% - 82px - 39px)' }}>
        <LabelInputStyle.Wrap style={{ height: '100%' }} $alignItem={'flex-start'}>
          <div style={{ height: '36px', display: 'flex' }}>
            <LabelInputStyle.Key>판독자 / 검수자</LabelInputStyle.Key>
          </div>
          <div style={{ width: '100%', height: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              {/* 판독자 */}
              <div
                style={{
                  flex: 1,
                  paddingRight: 16,
                  boxSizing: 'border-box',
                  borderRight: '1px dashed #D9D9D9',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 14,
                  }}
                >
                  <span style={{ color: '#00B074', fontWeight: 600, fontSize: 16 }}>판독자</span>
                  <Button $buttonType={'medium'} text={'판독자 추가'} icon={IcoFileUpload} $iconSize={20} $iconSpacing={6} onClick={() => onTogglePopup('reader')} />
                </div>
                <ScrollTable
                  caption={'판독자'}
                  colgroup={[60, 100, 'auto', 100]}
                  height={readerList.length > 0 ? '' : 'calc(100% - 50px)'}
                  $minHeight={'calc(100% - 50px)'}
                  $maxHeight={'calc(100% - 50px)'}
                  thead={
                    <tr>
                      <th scope={'col'}></th>
                      <th scope={'col'}>이름</th>
                      <th scope={'col'}>이메일</th>
                      <th scope={'col'}></th>
                    </tr>
                  }
                  tbody={
                    <>
                      {readerList.length ? (
                        readerList.map((i: any) => {
                          return (
                            <tr key={i.memberId} className={i.checked ? 'checked' : undefined}>
                              <td>
                                <Checkbox id={'reader_' + i.memberId} checked={i.checked} onChange={() => onCheck(1, i.memberId)} />
                              </td>
                              <td>{i.name}</td>
                              <td>
                                <TdEllipsis str={i.email} />
                              </td>
                              <td>
                                <PagingTableStyle.BtnRowRemove className={`btnRemove ${i.checked ? 'checked' : undefined}`} type={'button'} onClick={() => onRemoveRow(i.memberId, 'contract')}></PagingTableStyle.BtnRowRemove>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className={'noHover'}>
                          <td colSpan={5}>
                            <NoData text={'판독자를 추가하세요.'} icon={IcoAddUser} $iconSize={54} color={'#3F3F3F'} />
                          </td>
                        </tr>
                      )}
                    </>
                  }
                />
              </div>
              {/* 판독자 끝 */}

              {/* 검수자 */}
              <div style={{ flex: 1, paddingLeft: 16, boxSizing: 'border-box' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flex: 1,
                    marginBottom: 14,
                  }}
                >
                  <span style={{ color: '#00B074', fontWeight: 600, fontSize: 16 }}>검수자</span>
                  <Button $buttonType={'medium'} text={'검수자 추가'} icon={IcoFileUpload} $iconSize={20} $iconSpacing={6} onClick={() => onTogglePopup('inspector')} />
                </div>
                <ScrollTable
                  caption={'검수자'}
                  colgroup={[60, 100, 'auto', 100]}
                  height={inspectorList.length > 0 ? '' : 'calc(100% - 50px)'}
                  $minHeight={'calc(100% - 50px)'}
                  $maxHeight={'calc(100% - 50px)'}
                  thead={
                    <tr>
                      <th scope={'col'}></th>
                      <th scope={'col'}>이름</th>
                      <th scope={'col'}>이메일</th>
                      <th scope={'col'}></th>
                    </tr>
                  }
                  tbody={
                    <>
                      {inspectorList.length ? (
                        inspectorList.map((i: any) => {
                          return (
                            <tr key={i.memberId} className={i.checked ? 'checked' : undefined}>
                              <td>
                                <Checkbox id={'inspector_' + i.memberId} checked={i.checked} onChange={() => onCheck(2, i.memberId)} />
                              </td>
                              <td>{i.name}</td>
                              <td>
                                <TdEllipsis str={i.email} />
                              </td>
                              <td>
                                <PagingTableStyle.BtnRowRemove className={`btnRemove ${i.checked ? 'checked' : undefined}`} type={'button'} onClick={() => onRemoveRow(i.memberId, 'inspector')}></PagingTableStyle.BtnRowRemove>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className={'noHover'}>
                          <td colSpan={5}>
                            <NoData text={'검수자를 추가하세요.'} icon={IcoAddUser} $iconSize={54} color={'#3F3F3F'} />
                          </td>
                        </tr>
                      )}
                    </>
                  }
                />
              </div>
              {/* 검수자 끝 */}
            </div>
            <NextStep>
              <Button $buttonType={'large'} text={'저장하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={onSave} $iconDisabled={IcoStepDoneDisabled} />
            </NextStep>
          </div>
        </LabelInputStyle.Wrap>
      </Section>
      {popupState.reader ? <PopupAddReader involve={readerList} setInvolve={setReaderList} onClose={() => onTogglePopup('reader')} /> : null}
      {popupState.inspector ? <PopupAddInspector involve={inspectorList} setInvolve={setInspectorList} onClose={() => onTogglePopup('inspector')} /> : null}
    </div>
  );
};

export default Step2;
