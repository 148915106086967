import axios from 'axios';

const key_Url = {
  development: {
    key: 'C5FECDF0-854B-3CC7-A712-BCD273B673DF', // 만료일 4/2
    url: 'http://localhost:3030',
  },
  dev: {
    key: 'A7A1B592-547F-3ED5-AC4E-E3428FEF35E1', // 만료일 4/30
    url: 'http://farmez-dev.move-map.kr', // 개발 서버
  },
  origin: {
    key: 'EF38E598-7319-3217-A349-29045D91546F', // 만료일 4/30
    url: 'http://farmez.move-map.kr', // 운영 서버
  },
};
// export const domainUrl = isDevelop ? 'http://localhost:3030' : 'http://move-map.kr';
// export const myKey = isDevelop ? 'C5FECDF0-854B-3CC7-A712-BCD273B673DF' : 'E1FB3D79-62B7-382C-B4B5-4EB8D940860F'; // ynk, PORT=3030
// export const domainUrl = 'http://m-map.kr';
// export const myKey = 'BF7996E6-41F5-3B51-9A94-3F2C692B2DC7'; // m-map

const deployKey = 'origin'; // dev, origin (FIXME: 배포할때마다 수동 변경!)
const isDevelop = process.env.NODE_ENV === 'development';
export const domainUrl = isDevelop ? key_Url['development'].url : key_Url[deployKey].url;
export const myKey = isDevelop ? key_Url['development'].key : key_Url[deployKey].key;

// '&' join 형태
export const urlBuilder = (host: string, query = {}) => {
  const param = Object.entries(query)
    .map(([key, value]: any) => (value ? `${key}=${encodeURIComponent(value)}` : ''))
    .join('&');
  return `${host}?${param}`;
};

export const paramsBuilder = (typenames: string[], srs: string, bbox?: any, startindex?: number) => {
  return {
    //  VERSION: '1.1.0',
    VERSION: '2.0.0',
    SERVICE: 'WFS',
    REQUEST: 'GetFeature',
    TYPENAME: typenames?.toString(), // lt_c_uq111,
    BBOX: bbox?.toString(),
    SRSNAME: srs, // EPSG:900913
    OUTPUT: 'application/json',
    EXCEPTIONS: 'application/json',
    KEY: myKey,
    DOMAIN: domainUrl,
    FILTER: '',
    STARTINDEX: startindex,
    //  PROPERTYNAME: 'mnum,sido_cd,sigungu_cd,dyear,dnum,ucode,bon_bun,bu_bun,uname,sido_name,sigg_name,ag_geom',
    //  FORMAT: 'image/png',
    //  MAXFEATURES: 1000,
    //  OUTPUT: 'GML2',
    //  EXCEPTIONS: 'text/xml',
  };
};

/* --------------------------- */

// 연속지적도형정보
//(전산화된 지적도 및 임야도의 도면상 경계점을 연결하여 연속된 형태로 작성한 도면정보(측량자료로 사용하지 못하는 참조용 도면정보)
export const getData = async () => {
  let searchUrl = '/ned/wms/CtnlgsSpceService?';
  let param = '';
  param += `layer=${'dt_d002'}`;
  param += `&crs=${'EPSG:5186'}`;
  param += `&bbox=${'37.5663895970887,127.17179010651,37.5680556673634,127.174674782947'}`;
  param += `&width=${'915'}`;
  param += `&height=${'700'}`;
  param += `&format=${'image/png'}`;
  param += `&key=${myKey}`;
  let reqUrl = encodeURI(searchUrl + param);

  let response = await axios.get(reqUrl);

  console.log(response);
  return response;
};

// 연속 지적도 검색 (point(x, y))
export const getFeatureData = async ({ x, y }: { x?: any; y?: any }) => {
  // let searchUrl = "http://api.vworld.kr/req/data?";
  let searchUrl = '/req/data?';
  let param = 'service=data';
  param += `&request=${'GetFeature'}`;
  param += `&data=${'LP_PA_CBND_BUBUN'}`; // 연속 지적도
  param += `&geomFilter=POINT(${x} ${y})`;
  //  param += `&geomFilter=POINT(126.9706312565548 37.55424209787157)`;
  //  param += `&attrFilter=pnu:=:${'검색값'}`;
  param += `&format=${'json'}`;
  param += `&crs=${'EPSG:5186'}`;
  param += `&key=${myKey}`;
  param += `&domain=${domainUrl}`;

  let reqUrl = encodeURI(searchUrl + param);

  let { data } = await axios.get(reqUrl);

  return data;
};

// WFS
export const getWFSData = async () => {
  let searchUrl = '/req/wfs?';
  let param = 'SERVICE=WFS';
  param += `&REQUEST=${'GetFeature'}`;
  param += `&TYPENAME=${'lt_c_uq111'}`;
  //   param += `&BBOX=${''}`;
  param += `&PROPERTYNAME=${'mnum,sido_cd,sigungu_cd,dyear,dnum,ucode,bon_bun,bu_bun,uname,sido_name,sigg_name,ag_geom'}`;
  param += `&VERSION=${'1.1.0'}`;
  param += `&MAXFEATURES=${'1000'}`;
  param += `&SRSNAME=${'EPSG:5186'}`;
  param += `&OUTPUT=${'GML2'}`;
  param += `&EXCEPTIONS=${'text/xml'}`;
  param += `&KEY=${myKey}`;
  param += `&DOMAIN=${domainUrl}`;

  //   let reqUrl = encodeURI(searchUrl + param);

  //  let reqUrl = urlBuilder('/req/wfs', paramsBuilder(['lt_c_uq111'], 'EPSG:4326'));
  let reqUrl = urlBuilder('/req/wfs', paramsBuilder(['lt_c_adsido_info'], 'EPSG:5186'));

  let { data } = await axios.get(reqUrl);

  return data;
};

export const requestGeoJson = async (layerId: string, url: string): Promise<any> => {
  try {
    const { data } = await axios.get<any>(url, {
      responseType: 'json',
      baseURL: '/',
    });
    if (data) {
      const { features, numberReturned, bbox } = data;
      return {
        features: features,
        length: numberReturned,
        bbox: bbox,
        layerId: layerId,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const request = axios.create({
  baseURL: '/api',
});

export function countCharacters(str) {
  // 정규표현식 패턴 정의
  var patterns = {
    english: /[a-zA-Z]/g, // 영문 알파벳
    korean: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, // 한글
    space: /\s/g, // 공백(띄어쓰기)
    number: /\d/g, // 숫자
    parentheses: /[()-]/g, // 괄호
  };

  // 개수를 담을 객체 초기화
  var counts = {
    english: 0,
    korean: 0,
    space: 0,
    number: 0,
    parentheses: 0,
  };

  // 문자열을 순회하며 패턴에 해당하는 개수 세기
  for (var key in patterns) {
    var regex = patterns[key];
    var matches = str.match(regex);
    counts[key] = matches ? matches.length : 0;
  }

  //계산
  const a = ((counts.korean * 11) / 11) * 0.28;
  const b = ((counts.english * 7) / 11) * 0.28;
  const c = ((counts.number * 7) / 11) * 0.28;
  const d = ((counts.parentheses * 4) / 11) * 0.28;
  const e = ((counts.space * 4) / 11) * 0.28 + 0.7;

  // 결과 반환
  return a + b + c + d + e;
}
