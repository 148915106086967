import proj4 from 'proj4';
import { Point } from 'ol/geom';
import { transform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { Projection, addCoordinateTransforms, addProjection, get as getProjection, getTransform } from 'ol/proj';

import { rotate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
export const baseProjection = 'EPSG:5179';
export const targetProjection = 'EPSG:5186';

interface TransProjection {
  latitude: number;
  longitude: number;
  targetProjection?: string;
}

export const extent5179 = [90112, 1192896, 2187264, 2765760];

/** Projection 설정 */
proj4.defs('WGS84', '+proj=longlat +datum=WGS84 +no_defs');
proj4.defs('EPSG:5176', '+proj=tmerc +lat_0=38 +lon_0=129.0028902777778 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs +towgs84=-115.80,474.99,674.11,1.16,-2.31,-1.63,6.43');
proj4.defs('EPSG:5183', '+proj=tmerc +lat_0=38 +lon_0=129 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +units=m +no_defs');
proj4.defs('EPSG:5179', '+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
proj4.defs('EPSG:5186', '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs');
proj4.defs('EPSG:5187', '+proj=tmerc +lat_0=38 +lon_0=129 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs');
proj4.defs('EPSG:5185', '+proj=tmerc +lat_0=38 +lon_0=125 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs');
proj4.defs('EPSG:5174', '+proj=tmerc +lat_0=38 +lon_0=127.0028902777778 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs');
register(proj4);

/** 4326 좌표계 ---> 5179 반환타입 ARR */
export const trans4326To5186 = ({ latitude, longitude }: TransProjection) => {
  return new Point(transform([longitude, latitude], 'EPSG:4326', baseProjection)).getCoordinates();
};
/** 5186 좌표계 ---> 5179 반환타입 ARR */
export const trans5186To5179 = ({ latitude, longitude }: TransProjection) => {
  return new Point(transform([longitude, latitude], 'EPSG:5186', baseProjection)).getCoordinates();
};

export const transProjection = ({ latitude, longitude, targetProjection }: TransProjection) => {
  return new Point(transform([longitude, latitude], targetProjection, baseProjection)).getCoordinates();
};

export const transWgsTo5186 = (array) => {
  return proj4('WGS84', 'EPSG:5186', array);
};

export const trans5186ToWgs = (array) => {
  return proj4('EPSG:5186', 'WGS84', array);
};

export function rotateProjection(projection, angle, extent) {
  function rotateCoordinate(coordinate, angle, anchor) {
    var coord = rotate([coordinate[0] - anchor[0], coordinate[1] - anchor[1]], angle);
    return [coord[0] + anchor[0], coord[1] + anchor[1]];
  }

  function rotateTransform(coordinate) {
    return rotateCoordinate(coordinate, angle, getCenter(extent));
  }

  function normalTransform(coordinate) {
    return rotateCoordinate(coordinate, -angle, getCenter(extent));
  }

  var normalProjection = getProjection(projection);

  var rotatedProjection = new Projection({
    code: normalProjection!.getCode() + ':' + angle.toString() + ':' + extent.toString(),
    units: normalProjection!.getUnits(),
    extent: extent,
  });
  addProjection(rotatedProjection);

  addCoordinateTransforms(
    'EPSG:4326',
    rotatedProjection,
    function (coordinate) {
      return rotateTransform(transform(coordinate, 'EPSG:4326', projection));
    },
    function (coordinate) {
      return transform(normalTransform(coordinate), projection, 'EPSG:4326');
    },
  );

  addCoordinateTransforms(
    'EPSG:3857',
    rotatedProjection,
    function (coordinate) {
      return rotateTransform(transform(coordinate, 'EPSG:3857', projection));
    },
    function (coordinate) {
      return transform(normalTransform(coordinate), projection, 'EPSG:3857');
    },
  );

  addCoordinateTransforms(
    'EPSG:5186',
    rotatedProjection,
    function (coordinate) {
      return rotateTransform(transform(coordinate, 'EPSG:5186', projection));
    },
    function (coordinate) {
      return transform(normalTransform(coordinate), projection, 'EPSG:5186');
    },
  );

  // also set up transforms with any projections defined using proj4
  if (typeof proj4 !== 'undefined') {
    var projCodes = Object.keys(proj4.defs);
    projCodes.forEach(function (code) {
      var proj4Projection = getProjection(code);
      if (!getTransform(proj4Projection!, rotatedProjection)) {
        addCoordinateTransforms(
          proj4Projection!,
          rotatedProjection,
          function (coordinate) {
            return rotateTransform(transform(coordinate, proj4Projection!, projection));
          },
          function (coordinate) {
            return transform(normalTransform(coordinate), projection, proj4Projection!);
          },
        );
      }
    });
  }

  return rotatedProjection;
}
