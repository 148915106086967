import React from 'react';
import styled from 'styled-components';
import IcoCheckbox from 'assets/images/checkbox/ico-checkbox.svg';
import IcoCheckboxChecked from 'assets/images/checkbox/ico-checkbox-checked.svg';

//checkbox & radio
export interface CheckboxProps {
  ref?: React.MutableRefObject<null>;
  id?: string;
  text?: string | number;
  name?: string;
  value?: string | number;
  type?: 'radio' | 'checkbox' | string;
  defaultChecked?: boolean;
  checked?: boolean;
  style?: object;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: number;
  fontSize?: number;
  $sameColor?: boolean;
  disabled?: boolean;
  customLabel?: any;
  onClick?: any;
}

const CheckboxDefaultStyle = {
  Wrap: styled.div`
    position: relative;
    overflow: hidden;
    height: 100%;
  `,
  Label: styled.label<CheckboxProps>`
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '14px')};
    color: ${(p) => (p.$sameColor ? '#001a21' : '#7e8888')};
    font-weight: 500;
    &:before {
      cursor: pointer;
      content: '';
      width: ${(p) => (p.size ? p.size + 'px' : '18px')};
      height: ${(p) => (p.size ? p.size + 'px' : '18px')};
      transition: 0.4s;
      box-sizing: border-box;
      background: ${(p) => (p.type === 'radio' ? '#fff' : `#f1f1f5 url(${IcoCheckbox}) no-repeat center`)};
      transition: 0.4s;
      box-sizing: border-box;
      margin-right: ${(p) => (p.text ? '8px' : 0)};
      border: ${(p) => (p.type === 'radio' ? '5px solid #E5E5EC' : '1px solid #d1d1d1')};
      border-radius: ${(p) => (p.type === 'radio' ? '50%' : ' 2px')};
    }
  `,
  Input: styled.input`
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    font-size: 0;
    width: 0;
    height: 0;
  `,
};

export const CheckboxStyle = {
  ...CheckboxDefaultStyle,
  Input: styled(CheckboxDefaultStyle.Input)`
    &:checked + ${CheckboxDefaultStyle.Label} {
      color: #001a21;
      &:before {
        background-color: #d1f1e6;
        ${(p) => p.type !== 'radio' && `background-image: url(${IcoCheckboxChecked})`};
        border: ${(p) => (p.type === 'radio' ? '5px' : '1px')} solid #00b074;
      }
    }
  `,
};
const Checkbox = ({ ref, id, text, name, type, value, defaultChecked, checked, style, onChange, onClick, size, fontSize, $sameColor, disabled, customLabel }: CheckboxProps) => {
  return (
    <CheckboxStyle.Wrap style={style} ref={ref}>
      <CheckboxStyle.Input id={id} name={name} type={type === 'radio' ? 'radio' : 'checkbox'} defaultChecked={defaultChecked} checked={checked} onChange={onChange} value={type === 'radio' ? value : value} disabled={disabled} />
      <CheckboxStyle.Label htmlFor={id} type={type} text={text} size={size} fontSize={fontSize} $sameColor={$sameColor} onClick={onClick}>
        {customLabel || text}
      </CheckboxStyle.Label>
    </CheckboxStyle.Wrap>
  );
};

export default Checkbox;
