import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { AdminContainerStyle } from './AdminContainer';
import IcoBack from '../../../assets/images/nav/ico-back.svg';

export const AdminSubContainerStyle = {
  ...AdminContainerStyle,
  Wrap: styled(AdminContainerStyle.Wrap)`
    background: #f9f9f9;
  `,
  Head: styled(AdminContainerStyle.Head)`
    margin-bottom: 14px;
  `,
  TitleWrap: styled.div`
    display: flex;
    align-items: center;
  `,
  Title: styled.h2`
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #3f3f3f;
    font-weight: 600;
  `,
  Back: styled.button`
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background: url(${IcoBack}) no-repeat center;
  `,
  Cont: styled(AdminContainerStyle.Cont)`
    height: calc(100% - 86px);
  `,
  Content: styled.div`
    height: calc(100% - 38px);
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px 0 rgba(16, 24, 40, 0.15);
  `,
};

interface AdminSubContainerProps {
  title?: string;
  headRight?: ReactNode;
  content?: ReactNode;
  onNavigate?: () => void;
}

const AdminSubContainer = ({ title, headRight, content, onNavigate }: AdminSubContainerProps) => {
  return (
    <AdminSubContainerStyle.Wrap>
      <AdminSubContainerStyle.Inner>
        <AdminSubContainerStyle.Head>
          <AdminSubContainerStyle.TitleWrap>
            <AdminSubContainerStyle.Back type={'button'} onClick={onNavigate}>
              <span className={'hidden'}>이전페이지</span>
            </AdminSubContainerStyle.Back>
            <AdminSubContainerStyle.Title>{title}</AdminSubContainerStyle.Title>
          </AdminSubContainerStyle.TitleWrap>
          {headRight ? headRight : null}
        </AdminSubContainerStyle.Head>
        <AdminSubContainerStyle.Cont>{content ? content : null}</AdminSubContainerStyle.Cont>
      </AdminSubContainerStyle.Inner>
    </AdminSubContainerStyle.Wrap>
  );
};

export default AdminSubContainer;
