import React, { useEffect, useState } from 'react';
import AdminSubContainer, { AdminSubContainerStyle } from '../../../components/templates/Container/AdminSubContainer';
import TabContent, { NextStep, Section } from '../../../components/atoms/TabContent';
import { useRecoilState } from 'recoil';
import { formState } from '../../../store/Atoms';
import { useNavigate } from 'react-router-dom';
import Step1 from './AddStep/Step1';
import Step2 from './AddStep/Step2';
import PopupPostCode from '../../../popup/PopupPostCode';

const initForm = {
  name: '',
  type: '',
  zipcode: '',
  address: '',
  detailAddress: '',
  area: '',
  phone: '',
  email: '',
  department: '',
};

const ClientAdd = () => {
  const navigate = useNavigate();
  const [form, setForm] = useRecoilState(formState);

  const [tabList, setTabList] = useState([
    { name: '1. 기본 정보', pass: true, active: true },
    { name: '2. 작물 정보', pass: false, active: false },
  ]);

  //팝업 on/off 관리
  const [popupState, setPopupState] = useState({
    postCode: false,
  });

  //팝업 토글
  const onTogglePopup = (key) => {
    setPopupState({ ...popupState, [key]: !popupState[key] });
  };

  //다음 단계(idx는 현재스텝의 다음 index값)
  const onNextStep = (idx) => {
    const newArr = [...tabList];
    newArr.map((i, index) => {
      i.pass = index <= idx;
      i.active = index === idx;
    });
    setTabList(newArr);
  };

  //form 객체 세팅(recoil 저장)
  useEffect(() => {
    setForm(initForm);
  }, []);

  return (
    <AdminSubContainer
      title={'발주처 추가'}
      onNavigate={() => navigate('/client')}
      content={
        <AdminSubContainerStyle.Content>
          <TabContent
            tabList={tabList}
            setTabList={setTabList}
            type={2}
            height={'calc(100% - 102px)'}
            content={
              <>
                {/* 탭별 on/off*/}
                {tabList[0].active ? (
                  <>
                    <Step1 onTogglePopup={() => onTogglePopup('postCode')} onNextStep={onNextStep} />
                    {popupState.postCode ? <PopupPostCode onClose={() => onTogglePopup('postCode')} /> : null}
                  </>
                ) : null}
                {tabList[1].active ? <Step2 /> : null}
              </>
            }
          />
        </AdminSubContainerStyle.Content>
      }
    />
  );
};

export default ClientAdd;
