// 이메일 체크
export function checkEmail(str) {
  // 기본 정규식
  const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g;
  const RFCSpeciall = '!#$%&*+-/=?^_`{|}~'; //RFC 표준에서 허용되는 특수문자
  const special = '._+-'; // Gmail, Outlook 등 주로 허용하는 특수문자 ([%]는 일부에서만 허용)

  // 정규식을 동적으로 생성(특수문자)
  const emailRegex = new RegExp(`^[a-zA-Z0-9${special}]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$`, 'g');

  // 특정 도메인 설정
  const domain = 'example.com';
  const domainReg = new RegExp(`^[a-zA-Z0-9${special}]+@${domain.replace('.', '\\.')}$`, 'g');

  if (emailRegex.test(str)) {
    return true;
  } else {
    return false;
  }
}

// 특수 문자 체크
export function checkSpecial(str) {
  const regExp = /[!?@#$%^&*():;+-=~{}<>\_\[\]\|\\\"\'\,\.\/\`\₩]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
}

//비밀번호 체크(영문 및 숫자 8자리 체크)
export function checkPassword(str) {
  const regExp = /^(?=.*[a-zA-Z])(?=.*\d)[\w!@#$%^&*()-+=]{8,20}$/;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
}

//전화번호 체크(휴대폰 및 일반전화)
export const checkTel = (str) => {
  const phoneRegExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  const telRegExp = /^\d{2,3}-?\d{3,4}-?\d{4}$/;
  if (phoneRegExp.test(str) || telRegExp.test(str)) {
    return true;
  } else {
    return false;
  }
};
