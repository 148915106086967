import axios from 'axios';
import { urlBuilder } from 'ol/util';
import { myKey } from './common';

// https://api.vworld.kr
// [브이월드] get Juso From Coord
export const getJusoFromCoord = async ({ centerCoord, proj }) => {
  const params = {
    point: `${centerCoord[0]} , ${centerCoord[1]}`,
    crs: proj,
    //  point: '126.978275264,37.566642192',
    //  crs: 'epsg:4326',
    service: 'address',
    request: 'getAddress',
    key: myKey,
    type: 'both', // parcel, road
    version: '2.0',
    format: 'json',
  };
  const { data } = await axios.get(`/req/address`, { params });
  return data.response;
};

// [브이월드] get Coord(point) From Juso
export const getCoordFromJuso = async ({ juso, proj }) => {
  const params = {
    address: juso, // 사실 full 주소를 써야함 '강원특별자치도 강릉시 강동면'
    crs: proj,
    service: 'address',
    request: 'getCoord',
    key: myKey,
    type: 'parcel',
    version: '2.0',
    format: 'json',
  };
  const { data } = await axios.get(`/req/address`, { params });
  return data.response;
};
