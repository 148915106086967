import * as apiUrl from './apiUrl';
import { request } from '../../../service/farmEzCommon';

//유저의 사업리스트 조회
export const getProjects = async (memberId) => {
  const { data } = await request.get(apiUrl.member.projects(memberId));
  return data;
};

// 유저의 비밀번호 변경
export const patchChangePassword = async ({ memberId, newPassword }) => {
  const { data } = await request.patch(apiUrl.member.changePassword(memberId), { newPassword });
  return data;
};
//이메일 중복체크
export const getDuplicateEmail = async (email) => {
  const { data } = await request.get(apiUrl.member.duplicate, {
    params: {
      email: email,
    },
  });
  return data;
};

//판독자/검수자 추가
export const addMember = async ({ email, name, companyId, inspectorCompanyId, password }) => {
  const { data } = await request.post(apiUrl.member.addUser, {
    email,
    name,
    companyId,
    inspectorCompanyId,
    password,
  });
  return data;
};

//판독자/검수자 수정
export const modifyMember = async ({ email, name, companyId, password, memberId }) => {
  const { data } = await request.put(apiUrl.member.modifyUser(memberId), {
    email,
    name,
    companyId,
    password,
  });
  return data;
};

//판독자/검수자 삭제
export const removeMember = async ({ memberIds }) => {
  const form = new FormData();
  memberIds.map((i) => {
    form.append('memberIds[]', i);
  });
  const { data } = await request.delete(apiUrl.member.deleteUser, { data: form });
  return data;
};

//판독자/검수자 목록
export const getMembers = async ({ type, companyId, inspectorCompanyId, condition, pageNum, size }) => {
  const { data } = await request.get(apiUrl.member.get, {
    params: {
      type,
      companyId,
      inspectorCompanyId,
      condition,
      pageNum: pageNum - 1,
      size,
    },
  });
  return data.datas;
};
