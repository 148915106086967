import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import LoginBg from 'assets/images/login/login-bg.png';
import BannerTitle from 'assets/images/login/login-banner-title.svg';
import BannerLogo from 'assets/images/login/banner-logo.svg';
import LoginLogo from 'assets/images/login/login-logo.svg';
import IcoBuilding from 'assets/images/login/ico-building.svg';
import IcoLocation from 'assets/images/login/ico-location.svg';
import IcoCheck from 'assets/images/login/ico-check.svg';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import IcoUser from 'assets/images/login/ico-user.svg';
import IcoPassword from 'assets/images/login/ico-password.svg';
import useOnChange from 'hooks/useOnChange';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { checkEmail } from 'util/regCheck';
import useMutateHook from 'hooks/useMutateHook';
import { fetchLogin, request, requestFormData } from 'service/farmEzCommon';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { confirmState, userInfoState } from 'store/Atoms';
import query from 'hooks/RQuery/utils';
import { createKeywordTypeNode } from 'typescript';
import Confirm, { ConfirmStyle } from '../components/templates/Confirm';
import Notice from '../components/templates/Notice';

const LoginStyle = {
  Wrap: styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
  `,
  BannerWrap: styled.div`
    width: 800px;
    height: 100%;
    position: relative;
    .bgShadow {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      background: rgba(4, 146, 108, 0.65);
      filter: blur(25px);
    }
    .bgImage {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9;
      background: url(${LoginBg}) no-repeat center;
      .title {
        width: 722px;
        height: 135px;
        background: url(${BannerTitle}) no-repeat center;
        position: absolute;
        top: 126px;
        right: 0;
      }
      .scriptWrap {
        position: absolute;
        bottom: 88px;
        left: 80px;
        .logo {
          width: 132px;
          height: 24.47px;
          background: url(${BannerLogo}) no-repeat center;
        }
        .script {
          margin-top: 16.45px;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  `,
  FormWrap: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .copyRight {
      margin-top: auto;
      margin-bottom: 48px;
      color: #7d8888;
      font-weight: 500;
    }
  `,
  LoginWrap: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 185px;
    .logo {
      margin-bottom: 56px;
      width: 194.203px;
      height: 36px;
      background: url(${LoginLogo}) no-repeat center;
    }
    .formWrap {
      position: relative;

      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  `,
  SiteWrap: styled.div`
    padding-top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icoMain {
      margin-bottom: 24px;
      width: 64px;
      height: 64px;
      background: url(${IcoBuilding}) no-repeat center;
    }
    .info {
      margin-bottom: 30px;
      font-size: 22px;
      font-weight: 500;
    }
    .listBox {
      margin-bottom: 24px;
      width: 360px;
      height: 220px;
      padding: 20px;
      /* padding-right: 7px; */
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #f0f0f0;
      ul {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-right: 5px;
        &::-webkit-scrollbar {
          width: 8px;
          padding-left: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        li {
          //min-height: 48px;
          padding: 14px 40px 14px 16px;
          display: flex;
          align-items: center;
          gap: 10px;
          box-sizing: border-box;
          background-color: #fff;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
          /* word-break: keep-all; */
          word-break: break-word;
          p {
            line-height: 23px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          &:hover {
            background-color: #d1f1e6;
            /* font-weight: 700; */
          }
          .ico {
            display: inline-block;
            min-width: 24px;
            height: 24px;
            background: url(${IcoLocation}) no-repeat center;
          }
          &.active {
            position: relative;
            background-color: #d1f1e6;
            font-weight: 700;
            &::after {
              /* content: '';
              width: 24px;
              height: 24px;
              background: url(${IcoCheck}) no-repeat center;
              position: absolute;
              top: 50%;
              right: 16px;
              transform: translateY(-50%); */
            }
          }
        }
      }
    }
  `,
  Message: styled.p`
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
    color: #dc5700;
    font-size: 14px;
    font-weight: 400;
  `,
};
function Login({ isMaintenanceMode }) {
  console.log(isMaintenanceMode);
  return (
    <>
      {isMaintenanceMode && <Notice $hasClose={null} />}
      <LoginStyle.Wrap>
        <LoginStyle.BannerWrap>
          <div className="bgImage">
            <div className="title"></div>
            <div className="scriptWrap">
              <div className="logo"></div>
              <p className="script">드론영상 기반 WEB 품목분류 솔루션</p>
            </div>
          </div>
          <div className="bgShadow"></div>
        </LoginStyle.BannerWrap>
        <LoginStyle.FormWrap>
          {/* 라우터로 LoginForm, SelectSite 위치 */}
          <Outlet
            context={{
              data1: 'my name is..',
            }}
          />

          <div className="copyRight">Copyright 2024. Movements All rights reserved.</div>
        </LoginStyle.FormWrap>
      </LoginStyle.Wrap>
    </>
  );
}

export default Login;

// 로그인 입력폼
export function LoginForm() {
  const navigate = useNavigate();
  const setUserInfo = useSetRecoilState(userInfoState);
  const [errorMsg, setErrorMsg] = useState('');
  const [form, setForm, onChange] = useOnChange({
    email: '',
    password: '',
  });
  const { mutate: loginMutate } = useMutateHook({ fetchApi: fetchLogin });

  useEffect(() => {
    if (form?.email) {
      let isEmail = checkEmail(form.email);
      console.log('isEmail', isEmail);
    }
  }, [form]);

  const clickLogin = () => {
    loginMutate(form, {
      onSuccess(data: any, variables, context) {
        //   console.log(data);
        // 세션 저장
        sessionStorage.setItem('token', data?.accessToken);
        sessionStorage.setItem('memberId', data?.memberId);
        sessionStorage.setItem('companyId', data?.companyId);
        sessionStorage.setItem('auth', data?.auth);
        //recoil 저장
        setUserInfo({ ...data }); // 유저 정보

        // interceptor 작업 (토큰)
        request.interceptors.request.use((config) => {
          config.headers['Authorization'] = sessionStorage.getItem('token');
          return config;
        });
        requestFormData.interceptors.request.use((config) => {
          config.headers['Authorization'] = sessionStorage.getItem('token');
          return config;
        });
        // 사업장 선택화면 이동
        navigate('/login/selectSite');
      },
      onError(error: any, variables, context) {
        let status = error?.response.data?.status;
        let msg = error?.response.data?.message;
        setErrorMsg(msg);
      },
    });
  };

  return (
    <>
      {/* 로그인 Form */}
      <LoginStyle.LoginWrap>
        <div className="logo"></div>
        <div className="formWrap">
          <Input
            width={360}
            height={56}
            fontSize={16}
            type="text"
            id="email"
            name="email"
            placeholder="아이디 입력"
            $leftIco={IcoUser}
            onChange={(e) => {
              onChange(e);
              setErrorMsg('');
            }}
            $error={!!errorMsg}
          />
          <Input
            width={360}
            height={56}
            fontSize={16}
            type="password"
            id="password"
            name="password"
            placeholder="패스워드 입력"
            $leftIco={IcoPassword}
            onChange={(e) => {
              onChange(e);
              setErrorMsg('');
            }}
            onKeyDown={clickLogin}
            $error={!!errorMsg}
          />
          {!!errorMsg ? <LoginStyle.Message>{errorMsg}</LoginStyle.Message> : null}
        </div>
        <Button width={360} height={56} text="로그인" disabled={!form.email || !form.password} onClick={clickLogin} />
      </LoginStyle.LoginWrap>
    </>
  );
}

// 사업장 선택 영역
export function SelectSite() {
  const memberId = sessionStorage.getItem('memberId');
  const navigate = useNavigate();
  const { data1 } = useOutletContext<any>(); // <Outlet/> 전달 테스트
  const [selected, setSelected] = useState<any>();
  const [confirm, setConfirm] = useState({
    width: 280,
    message: '',
    rightText: '확인',
    rightFunc: () => {},
    show: false,
  });
  interface projectItemProps {
    projectId: string;
    projectName: string;
    startDate: string;
    endDate: string;
  }
  // 사업장 리스트 조회
  const { data } = query.member.useGetProjects(memberId);
  const { datas: projects } = data || {};

  const clickSite = (ele) => {
    sessionStorage.setItem('projectId', ele.projectId); // 프로젝트ID 세션에 저장
    setSelected({ ...ele });
  };

  const clickEnter = () => {
    navigate('/');
    //  window.location.href = '/';
  };

  useEffect(() => {
    if (projects) {
      if (!projects.length) {
        setConfirm({
          ...confirm,
          show: true,
          message: `등록된 사업장이 없습니다.<br/> ${sessionStorage.getItem('auth') === 'ROLE_ADMIN' ? '사업장 추가 페이지' : '로그인 화면으'}로 이동합니다.`,
          rightFunc: () => {
            if (sessionStorage.getItem('auth') === 'ROLE_ADMIN') {
              window.location.href = '/workPlace/add';
            } else {
              sessionStorage.clear();
              window.location.href = '/';
            }
          },
        });
      } else {
        clickSite(projects[0]);
      }
    }
  }, [projects]);

  return (
    <>
      {/* 사업장 선택 */}
      <LoginStyle.SiteWrap>
        <div className="icoMain"></div>
        <div className="info">사업장을 선택하세요</div>
        <div className="listBox">
          <ul className="cScroll">
            {projects?.map((ele: projectItemProps) => (
              <li key={ele.projectId} className={selected?.projectId === ele.projectId ? 'active' : ''} onClick={() => clickSite(ele)} title={ele?.projectName}>
                <span className="ico"></span>
                <p>{ele?.projectName}</p>
              </li>
            ))}
            {/* {siteArr.map((ele, idx) => (
              <li key={ele.name + idx} className={selected?.name === ele.name ? 'active' : ''} onClick={() => clickSite(ele)}>
                <span className="ico"></span>
                {ele?.name}
              </li>
            ))} */}
          </ul>
        </div>
        <Button
          width={360}
          height={56}
          text="사업장 접속"
          onClick={clickEnter}
          //   disabled={!selected?.projectId}
        />
      </LoginStyle.SiteWrap>
      {confirm.show ? (
        <>
          <ConfirmStyle.Dimmed></ConfirmStyle.Dimmed>
          <ConfirmStyle.Wrap>
            <ConfirmStyle.TextArea dangerouslySetInnerHTML={{ __html: String(confirm.message) }}></ConfirmStyle.TextArea>
            <ConfirmStyle.ButtonArea>
              <Button $buttonType={'large'} height={48} text={confirm.rightText} onClick={confirm.rightFunc} />
            </ConfirmStyle.ButtonArea>
          </ConfirmStyle.Wrap>
        </>
      ) : null}
    </>
  );
}
