import React, { useEffect, useState } from 'react';
import { NextStep, Section } from 'components/atoms/TabContent';
import Button from 'components/atoms/Button';
import IcoStepDone from 'assets/images/button/ico-step-done.svg';
import HelpMessage from 'components/atoms/HelpMessage';
import IcoHelp from 'assets/images/etc/ico-help-message.svg';
import IcoFileUpload from 'assets/images/button/ico-file-upload.svg';
import styled from 'styled-components';
import FileButton from 'components/atoms/FileButton';
import Checkbox from 'components/atoms/Checkbox';
import PopupChangeOrder from 'popup/PopupChangeOrder';
import query from 'hooks/RQuery/utils';
import { getFirstDecimal } from 'util/global';
import useMutateHook from 'hooks/useMutateHook';
import api from 'hooks/RQuery/service';
import queryKeys from 'hooks/RQuery/keys/queryKeys';
import { confirmState } from 'store/Atoms';
import { useRecoilState } from 'recoil';
import IcoStepDoneDisabled from '../../../../../assets/images/button/ico-step-done-disabled.svg';

const RoundStep4Style = {
  HelpWrap: styled.div`
    height: 21px;
    display: flex;
    align-items: center;
    gap: 12px;
    .downBtn {
      color: #00b074;
      text-decoration: underline;
      line-height: 20px;
    }
  `,
  BoxWrap: styled.div`
    width: 100%;
    height: calc(100% - 44px - 52px); // helpMsg, saveBtn
    background-color: #f6f6f6;
    //border: 2px solid #00b074;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  `,
  Box: styled.div`
    flex-grow: 1;
    //width: 380px;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    &:not(:last-child) {
      border-right: 2px solid #00b074;
    }
    > div {
      box-sizing: border-box;
      overflow: hidden;
      height: 56px;
      border: 2px solid #00b074;
      > .title {
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e5f8e8;
        color: #00b074;
        &:not(:last-child) {
          border-right: 2px solid #00b074;
        }
        &.active {
          background-color: #00b074;
          color: #fff;
        }
      }
    }
    ul {
      height: calc(100% - 56px);
      li {
        overflow: hidden;
        border-left: 2px solid #00b074;
        border-right: 2px solid #00b074;
        height: 60px;
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        //border-bottom: 1px solid #e7eaee;
        box-sizing: border-box;
        &:last-child {
          border-bottom: 2px solid #00b074;
          border-radius: 0 0 8px 8px;
          box-sizing: border-box;
        }
        > div {
          display: flex;
          padding: 19px 20px;
          flex: 1;
          background: #f1f1f5;
          border-bottom: 1px solid #e7eaee;
          &:not(:last-child) {
            border-right: 2px solid #00b074;
          }
          label {
            color: #9a9a9a !important;
          }
          &.checked {
            background: #fff;
            label {
              color: #001a21 !important;
            }
          }
        }
        &.disabled {
          background: #f1f1f5;
          color: #9a9a9a;
        }
        .hoverItem {
          margin-left: auto;
          width: 0;
          opacity: 0;
          color: #00b074;
          font-weight: 500;
          text-decoration-line: underline;
          cursor: pointer;
        }
        .labelGroupRow {
          &:hover {
            /* background: #fafbfb; */
            .hoverItem {
              transition: all 0.4s;
              width: auto;
              opacity: 1;
            }
          }
        }

        .text {
          margin-left: 8px;
          font-size: 16px;
        }
        .labelGroup {
          margin-left: 8px;
          display: flex;
          gap: 4px;
          .labelTag {
            display: flex;
            padding: 2px 8px 2px 6px;
            justify-content: center;
            align-items: center;
            gap: 6px;
            border-radius: 16px;
            background: #ecfdf3;
            color: #00b074;
            font-size: 12px;
            &::before {
              display: inline-block;
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #00b074;
            }
          }
        }
      }
    }
  `,
};

const dummyData = [
  {
    text: '기타시설',
    group: ['기타', '시설', '휴경지', '비경지'], // group 유무 -> 순서변경 팝업에 사용
    level: '1',
    checked: false,
  },
  {
    text: '배추',
    level: '1',
    checked: false,
  },
  {
    text: '귤',
    level: '1',
    checked: false,
  },
  {
    text: '콜라비',
    level: '1',
    checked: false,
  },
  {
    text: '무순',
    level: '1',
    checked: false,
  },
  {
    text: '케일',
    level: '1',
    checked: false,
  },
  {
    text: '아스파라거스',
    level: '1',
    checked: false,
  },
  {
    text: '달래',
    level: '1',
    checked: false,
  },
];

const RoundStep3 = ({ surveyId }) => {
  const [confirm, setConfirm] = useRecoilState(confirmState); // 알람창
  const [viewType, setViewType] = useState('1'); // 1: 1회차대기중(입력) 2:회차종료(뷰어모드) 3:회차종료(수정모드)
  const [openPopOrder, setOpenPopOrder] = useState(false);

  const [csvFile, setCsvFile] = useState<any>(null);
  const [csvData, setCsvData] = useState(null);

  const [form, setForm] = useState([...dummyData]);
  //   console.log(form);

  const [codeData, setCodeData] = useState<any>([
    // { checked: false }
  ]); // 보여지는 최종 데이터

  //관리자 - 사업 회차별 작물코드 조회
  const { data: surveyCropList } = query.survey.useGetCrops(surveyId);

  //관리자 - 사업 회차 작물코드 레벨 수정
  const { mutate: updateCropLevel } = query.survey.useUpdateCropLevel({ surveyId });

  // 작물코드 checked용으로 변환
  useEffect(() => {
    let etcArr: any[] = []; // 기타시설
    let cropArr: any[] = []; // 작물

    surveyCropList?.forEach((item) => {
      if (getFirstDecimal(item.level) > 0) {
        etcArr.push(item);
      } else {
        cropArr.push(item);
      }
    });

    let codeData: any[] = []; // state 저장할 데이터
    if (etcArr?.length > 0) {
      const sortedData = etcArr.sort((a, b) => {
        const decimalA = getFirstDecimal(a.level); // 소수점 첫 번째 자리 구하기
        const decimalB = getFirstDecimal(b.level);
        return decimalA - decimalB; // 소수점 첫 번째 자리에 따라 정렬
      });
      let etcObj = {
        group: [],
        text: '기타시설',
        level: Math.floor(sortedData[0]?.level),
        checked: Math.floor(sortedData[0]?.level) !== 1,
      };
      sortedData.map((ele) => {
        //   (etcObj['group'] as any).push(ele.displayName);
        (etcObj['group'] as any).push({ ...ele });
      });

      codeData = [etcObj, ...codeData]; // 첫번째 항목 추가
    }
    if (cropArr?.length > 0) {
      codeData = [
        ...codeData,
        ...cropArr?.map((ele) => ({
          ...ele,
          text: ele.displayName,
          // checked: ele.level !== 1
          checked: true,
        })),
      ];
    }
    setCodeData(codeData); // state 저장
    // 초기화
    if (surveyCropList?.length === 0) {
      setCodeData([]);
    }
  }, [surveyCropList]);

  console.log(codeData);

  // 체크박스 onchange (level2, level3)
  const onChangeCheck = (e, level) => {
    const { name, checked } = e.target;
    // 체크 ON - level과 checked 업데이트
    // 체크 OFF - level : 1로 변경
    let finalLevel = !checked ? 1 : level;
    let finalChecked = finalLevel === 1 ? true : checked;

    setCodeData((prev) => {
      return [...prev].map((ele) => {
        if (ele.text === name) {
          if (ele.group) {
            return { ...ele, level: finalLevel, checked: finalChecked, group: ele.group?.map((item, idx) => ({ ...item, level: finalLevel + (idx + 1) / 10 })) };
          }
          return { ...ele, level: finalLevel, checked: finalChecked };
        }
        return ele;
      });
    });
  };

  // CSV 파일을 파싱하는 함수
  const parseCSV = (csv) => {
    const lines = csv.split('\n'); // 각 줄을 분리
    const headers = lines[0].split(','); // 첫 번째 줄을 헤더로 분리
    const rows = lines.slice(1); // 두 번째 줄부터 데이터를 분리

    const parsedData = rows.map((row) => {
      const values = row.split(','); // 각 줄의 값을 분리
      const obj = {};

      // 각 줄의 데이터를 헤더와 매핑하여 객체로 변환
      headers.forEach((header, index) => {
        obj[header.trim()] = values[index].trim(); // 앞뒤 공백 제거
      });

      return obj;
    });
    console.log(parsedData);
    setCsvData(parsedData); // 파싱된 데이터를 state에 저장
  };

  // 파일이 선택되었을 때 호출되는 핸들러
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // 첫번째 파일

    if (file) {
      const reader = new FileReader();
      reader.onload = function (event: any) {
        const csv = event.target.result; // 파일 내용
        parseCSV(csv); // CSV 파싱 함수 호출
        e.target.value = ''; // input 초기화
      };
      reader.readAsText(file); // CSV 파일을 텍스트로 읽음
    }
  };

  // 저장하기 버튼
  const onClickSave = async () => {
    // [공통사용] viewType : 1 (입력모드) , 3(수정모드)
    let finalData: any[] = [];
    codeData?.map((ele) => (ele.group ? ele.group?.map((item) => finalData.push({ surveyCropId: item.surveyCropId, level: item.level })) : finalData.push({ surveyCropId: ele.surveyCropId, level: ele.level })));
    console.log(finalData);
    //  setViewType('2');

    // 저장 전, 확인 팝업
    setConfirm({
      ...confirm,
      show: true,
      leftText: '취소',
      rightText: '확인',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: async () => {
        await updatedSave()
          .then((res) => {
            setConfirm({ ...confirm, show: true, leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }), message: '저장이 완료되었습니다.' });
          })
          .catch(() => {
            setConfirm({ ...confirm, show: true, leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }), message: '실패. 관리자에게 문의하세요.' });
          });
      },
      message: '변경된 사항을 저장하시겠습니까?',
    });

    // 서버 저장
    async function updatedSave() {
      await updateCropLevel(
        { updateSurveyCrops: finalData },
        {
          onSuccess(data, variables, context) {
            console.log(data);
          },
          onError(error, variables, context) {
            console.log(error);
          },
        },
      );
    }
  };

  return (
    <>
      <Section style={{ width: 1272 }}>
        <HelpMessage
          message={'혼작 시 대표 작물의 지정 기준을 설정하세요.'}
          style={{
            color: '#001A21',
            fontSize: '16px',
            fontWeight: '600',
          }}
        />
      </Section>
      <Section style={{ width: 1272, height: 'calc(100% - 141.5px + 52px)' }}>
        <RoundStep4Style.BoxWrap>
          {/* Level_1 */}
          <RoundStep4Style.Box className="cScroll active">
            <div style={{ display: 'flex', position: 'sticky', top: 0, zIndex: 10 }}>
              <div className="title active" style={{ flex: 1 }}>
                {'Level1'}
              </div>
              <div className="title" style={{ flex: 1 }}>
                {'Level2'}
              </div>
              <div className="title" style={{ flex: 1 }}>
                {'Level3'}
              </div>
            </div>
            <ul>
              {codeData?.map((ele: any, idx) => (
                <li key={idx} style={{ display: 'flex' }}>
                  <div className={` ${ele?.group && viewType !== '2' && 'labelGroupRow'} ${ele?.level === 1 && 'checked'}`}>
                    {/* 뷰어모드 */}
                    {viewType === '2' ? (
                      ele?.group ? (
                        <div className="labelGroup">
                          {ele.group?.map((item, i) => (
                            <span key={`tag_${i}`} className="labelTag">
                              {item.displayName}
                            </span>
                          ))}
                        </div>
                      ) : (
                        ele.text && <p className="text">{ele.text}</p>
                      )
                    ) : /* 입력 | 수정 모드 */
                    ele.group ? (
                      <div className="labelGroup">
                        {ele.group?.map((item, i) => (
                          <span key={`tag_${i}`} className="labelTag">
                            {item.displayName}
                          </span>
                        ))}
                      </div>
                    ) : (
                      <Checkbox
                        $sameColor
                        fontSize={16}
                        text={ele.text}
                        id={'level1' + ele.text}
                        name={ele.text}
                        onChange={!ele?.group ? (e) => onChangeCheck(e, 1) : undefined}
                        checked={ele.checked && ele.level === 1}
                        //  disabled={ele.checked && ele.level !== 1}
                        customLabel={
                          // ele?.group ? (
                          //   <div className="labelGroup">
                          //     {ele.group?.map((item, i) => (
                          //       <span key={`tag_${i}`} className="labelTag">
                          //         {item.displayName}
                          //       </span>
                          //     ))}
                          //   </div>
                          // ) :
                          <p style={{ marginLeft: '8px' }}>{ele.text}</p>
                        }
                      />
                    )}

                    {/* {ele?.group ? (
                    <div className="labelGroup">
                      {ele.group?.map((item, i) => (
                        <span key={`tag_${i}`} className="labelTag">
                          {item.displayName}
                        </span>
                      ))}
                    </div>
                  ) : (
                    ele.text && <p className="text">{ele.text}</p>
                  )} */}
                    {/* labelGroupRow일때만 보임 (viewType !== '2')*/}
                    {/* <div className="hoverItem" onClick={() => setOpenPopOrder(true)}>
                    순서 변경
                  </div> */}
                  </div>
                  <div className={`${ele?.group && viewType !== '2' && 'labelGroupRow'} ${ele?.level === 2 && 'checked'}`}>
                    {/* 뷰어모드 */}
                    {viewType === '2' ? (
                      ele?.group ? (
                        <div className="labelGroup">
                          {ele.group?.map((item, i) => (
                            <span key={`tag_${i}`} className="labelTag">
                              {item.displayName}
                            </span>
                          ))}
                        </div>
                      ) : (
                        ele.text && <p className="text">{ele.text}</p>
                      )
                    ) : /* 입력 | 수정 모드 */
                    ele?.group ? (
                      <div className="labelGroup">
                        {ele.group?.map((item, i) => (
                          <span key={`tag_${i}`} className="labelTag">
                            {item.displayName}
                          </span>
                        ))}
                      </div>
                    ) : (
                      <Checkbox
                        $sameColor
                        fontSize={16}
                        text={ele.text}
                        id={'level2' + ele.text}
                        name={ele.text}
                        onChange={!ele?.group ? (e) => onChangeCheck(e, 2) : undefined}
                        checked={ele.checked && ele.level === 2}
                        //  disabled={ele.checked && ele.level !== 2}
                        customLabel={
                          // ele?.group ? (
                          //   <div className="labelGroup">
                          //     {ele.group?.map((item, i) => (
                          //       <span key={`tag_${i}`} className="labelTag">
                          //         {item.displayName}
                          //       </span>
                          //     ))}
                          //   </div>
                          // ) :
                          <p style={{ marginLeft: '8px' }}>{ele.text}</p>
                        }
                      />
                    )}

                    {/* labelGroupRow일때만 보임 (viewType !== '2')*/}
                    {/* <div className="hoverItem" onClick={() => setOpenPopOrder(true)}>
                    순서 변경
                  </div> */}
                  </div>
                  <div key={`level3_${idx}`} className={` ${ele?.group && viewType !== '2' && 'labelGroupRow'} ${ele?.level === 3 && 'checked'}`}>
                    {/* 뷰어모드 */}
                    {viewType === '2' ? (
                      ele?.group ? (
                        <div className="labelGroup">
                          {ele.group?.map((item, i) => (
                            <span key={`tag_${i}`} className="labelTag">
                              {item.displayName}
                            </span>
                          ))}
                        </div>
                      ) : (
                        ele.text && <p className="text">{ele.text}</p>
                      )
                    ) : /* 입력 | 수정 모드 */
                    ele?.group ? (
                      <div className="labelGroup">
                        {ele.group?.map((item, i) => (
                          <span key={`tag_${i}`} className="labelTag">
                            {item.displayName}
                          </span>
                        ))}
                      </div>
                    ) : (
                      <Checkbox
                        $sameColor
                        fontSize={16}
                        text={ele.text}
                        id={'level3' + ele.text}
                        name={ele.text}
                        onChange={!ele?.group ? (e) => onChangeCheck(e, 3) : undefined}
                        checked={ele.checked && ele.level === 3}
                        //  disabled={ele.checked && ele.level !== 3}
                        customLabel={
                          // ele?.group ? (
                          //   <div className="labelGroup">
                          //     {ele.group?.map((item, i) => (
                          //       <span key={`tag_${i}`} className="labelTag">
                          //         {item.displayName}
                          //       </span>
                          //     ))}
                          //   </div>
                          // ) :
                          <p style={{ marginLeft: '8px' }}>{ele.text}</p>
                        }
                      />
                    )}

                    {/* labelGroupRow일때만 보임 (viewType !== '2') */}
                    <div className="hoverItem" onClick={() => setOpenPopOrder(true)}>
                      순서 변경
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </RoundStep4Style.Box>
        </RoundStep4Style.BoxWrap>

        <NextStep>
          {/* 입력모드 */}
          {viewType === '1' && <Button $buttonType={'large'} text={'저장하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={onClickSave} $iconDisabled={IcoStepDoneDisabled} />}
          {/* 뷰어모드 */}
          {viewType === '2' && <Button $buttonType={'large'} text={'수정하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={() => setViewType('3')} $iconDisabled={IcoStepDoneDisabled} />}
          {/* 수정모드 */}
          {viewType === '3' && <Button $buttonType={'large'} text={'수정완료'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={onClickSave} $iconDisabled={IcoStepDoneDisabled} />}
          {/* <Button $buttonType={'large'} text={'저장하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} /> */}
        </NextStep>
      </Section>

      {/* 순서변경 팝업 */}
      {openPopOrder && <PopupChangeOrder onClose={() => setOpenPopOrder(false)} codeData={codeData} setCodeData={setCodeData} />}
    </>
  );
};

export default RoundStep3;
