import * as apiUrl from './apiUrl';
import { request, requestFormData } from '../../../service/farmEzCommon';
import { project } from './apiUrl';

//회차 리스트조회
export const getRounds = async (projectId) => {
  const { data } = await request.get(apiUrl.project.roundList(projectId));
  return data.datas;
};

// 사업장 추가(주소)
export const addProject = async (params) => {
  const { data } = await request.post(apiUrl.project.add, params, {
    timeout: 600000,
  });
  return data;
};
// 사업장 추가 (shp 파일)
export const addProjectShp = async ({ projectSaveRequest, files }) => {
  const formData = new FormData();

  formData.append('projectSaveRequest', JSON.stringify(projectSaveRequest));

  files.map((file) => {
    formData.append('files', file); // shp 파일 배열
  });

  const { data } = await requestFormData.post(apiUrl.project.addWithShp, formData, { params: {} });
  return data;
};

export const addShpFile = async ({ projectId, encodingType = 'UTF-8', files }: { projectId?: string; encodingType?: string; files?: any }) => {
  console.log(files);
  const formData = new FormData();

  files.map((file) => {
    if (file.name.includes('shp')) {
      formData.append('shpFile', file);
    }
    if (file.name.includes('dbf')) {
      formData.append('dbfFile', file);
    }
    if (file.name.includes('shx')) {
      formData.append('shxFile', file);
    }
    if (file.name.includes('prj')) {
      formData.append('prjFile', file);
    }
  });

  const { data } = await requestFormData.post(apiUrl.project.addShpFile(projectId), formData, { params: { encodingType } });
  return data;
};

// 사업 수정
export const patchProject = async (params) => {
  const { projectId, projectName, prjId, surveyBeginDate, surveyEndDate, surveyCount, ...restParams } = params || {};
  const newParams = {
    projectName,
    prjId,
    surveyBeginDate,
    surveyEndDate,
    surveyCount,
  };

  const { data } = await request.patch(apiUrl.project.patch(projectId), newParams);
  return data;
};

// 사업장 목록 조회
export const getProjects = async ({ companyId, pageNum, size }) => {
  const { data } = await request.get(apiUrl.project.add, { params: { companyId, pageNum: pageNum - 1, size } });
  return data.datas;
};

// 사업장의 필지목록 조회
export const getLots = async (projectId) => {
  //   const id = '1t0bTh0pFEICkbTmBTWV05I03000';
  const { data } = await request.get(apiUrl.project.getLots(projectId));
  return data.datas;
};

//사업 판독자/검수자 조회
export const getProjectMembers = async (projectId) => {
  const { data } = await request.get(apiUrl.project.getUsers, {
    params: { projectId },
  });
  return data.datas;
};

//사업 판독자/검수자 추가
export const addProjectMembers = async ({ projectId, contracts, inspectors }) => {
  const newArr: string[] = [];
  const newArr2: string[] = [];
  contracts.map((i) => {
    newArr.push(i.memberId);
  });
  inspectors.map((i) => {
    newArr2.push(i.memberId);
  });
  const { data } = await request.post(apiUrl.project.addUsers, {
    projectId: projectId,
    contracts: newArr,
    inspectors: newArr2,
  });
  return data;
};

//사업 판독자/검수자 삭제
export const removeProjectMembers = async ({ contracts, inspectors }) => {
  const newArr: string[] = [];
  contracts.map((i) => {
    newArr.push(i.projectMemberId);
  });
  inspectors.map((i) => {
    newArr.push(i.projectMemberId);
  });
  const { data } = await request.delete(apiUrl.project.removeUsers, {
    data: {
      projectMembers: newArr,
    },
  });
  return data;
};

//누락필지 shp 다운로드
export const getShp = async (projectId) => {
  const { data } = await request.get(apiUrl.project.getShp(projectId));
  return data.datas;
};
