import React, { useState, useEffect } from 'react';
import AdminSubContainer, { AdminSubContainerStyle } from '../../../components/templates/Container/AdminSubContainer';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import TabContent from '../../../components/atoms/TabContent';
import Step1 from './DetailStep/Step1';
import Step3 from './DetailStep/Step3';
import SurveyStatus from './common/SurveyStatus';

const WorkPlaceDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryStr = location.search; // ?id=1234
  const searchParams = new URLSearchParams(location.search); // 쿼리 개별 추출
  const projectId = searchParams.get('projectId');

  const [tabList, setTabList] = useState<any>([
    { name: '기본 정보', active: false, pass: true, path: `/workPlace/detail${queryStr}` },
    { name: '인원 정보', active: false, pass: true, path: `/workPlace/detail/worker${queryStr}` },
    { name: '회차별 정보', active: false, pass: true, path: `/workPlace/detail/round${queryStr}` },
  ]);

  // pathname -> 탭 active
  useEffect(() => {
    if (location?.pathname) {
      const curPath = location.pathname + queryStr;
      setTabList((prev) => {
        return [...prev].map((ele) => (ele.path === curPath ? { ...ele, active: true } : { ...ele, acitve: false }));
      });
    }
  }, [location, queryStr]);

  return (
    <AdminSubContainer
      title={'사업장 관리'}
      onNavigate={() => navigate('/workPlace')}
      headRight={<SurveyStatus projectId={projectId} />}
      content={
        <AdminSubContainerStyle.Content>
          <TabContent
            type={2}
            tabList={tabList}
            setTabList={setTabList}
            height={'calc(100% - 64px)'}
            content={
              <>
                <Outlet
                  context={{
                    projectId,
                  }}
                />

                {/* {tabList[0].active ? <Step1 /> : null}
                {tabList[1].active ? <Step3 /> : null} */}
              </>
            }
          />
        </AdminSubContainerStyle.Content>
      }
    />
  );
};

export default WorkPlaceDetail;
