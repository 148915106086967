import { request } from '../../../service/common';
import * as apiUrl from './apiUrl';

//레이어 coordinates 수정
export const modifyLayer = async ({ layerName, featureId, type, coordinates, srid }) => {
  const { data } = await request.put(apiUrl.map.modify(layerName, featureId), {
    geometry: {
      type: type,
      coordinates: coordinates,
    },
    srid: srid,
  });
  return data;
};
