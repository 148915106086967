import { useQuery } from '@tanstack/react-query';
import queryKeys from '../keys/queryKeys';
import api from '../service';
import useMutateHook from 'hooks/useMutateHook';
import { useQueryState } from 'hooks/useQueryState';

// 사업의 회차 리스트 조회
export function useGetRounds(projectId) {
  return useQuery({
    queryKey: [queryKeys.projectKeys.detail(projectId)],
    queryFn: () => api.project.getRounds(projectId),
    enabled: !!projectId,
  });
}

// 신규 사업장 추가(주소)
export function usePostProject(params) {
  return useMutateHook({
    fetchApi: () => api.project.addProject(params),
    invalidQuery: [queryKeys.projectKeys.all],
  });
}

//  신규 사업장 추가(shp)
export function usePostProjectWithShp() {
  return useMutateHook({
    fetchApi: ({ projectSaveRequest, files }) => api.project.addProjectShp({ projectSaveRequest, files }),
    invalidQuery: [],
  });
}

// 사업장 필지 shp 저장
export function usePostShpFile({ projectId, encodingType, files }) {
  return useMutateHook({
    fetchApi: () => api.project.addShpFile({ projectId, encodingType, files }),
    invalidQuery: [],
  });
}

// 사업 수정
export function usePatchProject(params) {
  return useMutateHook({
    fetchApi: () => api.project.patchProject(params),
    // invalidQuery: []
  });
}

// 사업 목록 조회
export function useGetProjects({ companyId, pageNum, size }) {
  return useQueryState({
    queryKey: [queryKeys.projectKeys.list({ companyId, pageNum, size })],
    queryFn: () => api.project.getProjects({ companyId, pageNum, size }),
    enabled: !!companyId,
  });
}

// 사업장의 필지목록 조회
export function useGetLots(projectId) {
  return useQueryState({
    queryKey: [queryKeys.projectKeys.lots(projectId)],
    queryFn: () => api.project.getLots(projectId),
    enabled: !!projectId,
  });
}

//사업 판독자/검수자 조회
export function useGetProjectMembers(projectId) {
  return useQueryState({
    queryKey: [queryKeys.projectKeys.userList],
    queryFn: () => api.project.getProjectMembers(projectId),
    enabled: !!projectId,
  });
}

//사업 판독자/검수자 추가
export function useAddProjectMembers(params) {
  return useMutateHook({
    fetchApi: () => api.project.addProjectMembers(params),
    invalidQuery: [[queryKeys.projectKeys.userList]],
  });
}

//사업 판독자/검수자 삭제
export function useRemoveProjectMembers(params) {
  return useMutateHook({
    fetchApi: () => api.project.removeProjectMembers(params),
    invalidQuery: [[queryKeys.projectKeys.userList]],
  });
}

//누락필지 shp 다운
export function useGetShp(projectId) {
  return useQueryState({
    queryFn: () => api.project.getShp(projectId),
    queryKey: [queryKeys.projectKeys.shp(projectId)],
    enabled: !!projectId,
  });
}
