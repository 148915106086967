import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import IcoFilter from 'assets/images/cropInfoBox/ico-filter.svg';
import IcoReset from 'assets/images/cropInfoBox/ico-reset.svg';
import IcoFilterHover from 'assets/images/cropInfoBox/ico-filter-hover.svg';
import IcoFilterPopup from 'assets/images/cropInfoBox/ico-filter-popup.svg';
import Checkbox from 'components/atoms/Checkbox';
import Button from 'components/atoms/Button';
import { useRecoilState } from 'recoil';
import { confirmState } from '../../../../store/Atoms';

export const CropFilterStyle = {
  Wrap: styled.div`
    position: relative;
    z-index: 10;
    width: 180px;
    background: #fff;
  `,
  Head: styled.div`
    box-sizing: border-box;
    height: 50px;
    border-bottom: 1px solid #74e0bb;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
    padding: 9px 7px;
    display: flex;
    align-items: center;
  `,
  Button: styled.button`
    width: 91px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #001a21;
    font-size: 14px;
    font-weight: 500;
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background: url(${IcoFilter}) no-repeat center;
      margin-right: 6px;
    }
    &:hover {
      background: #e5f8e8;
    }
    &:active {
      background: #ceefd4;
    }
    &.active {
      color: #fff;
      background: #00b074;
      border: 1px solid transparent;
      &:before {
        background-image: url(${IcoFilterHover});
      }
      &:hover {
        background: #008d5d;
      }
      &:active {
        background: #0e7d5f;
      }
    }
  `,
  Reset: styled.button`
    width: 32px;
    height: 32px;
    margin-left: 6px;
    box-sizing: border-box;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #001a21;
    font-size: 14px;
    font-weight: 500;
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background: url(${IcoReset}) no-repeat center;
    }
    &:hover {
      background: #e5f8e8;
    }
    &:active {
      background: #ceefd4;
    }
  `,
  Cont: styled.div`
    height: calc(100% - 50px);
    padding: 0 10px;
  `,
  BadgeGroup: styled.ul`
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    &:not(:last-child) {
      border-bottom: 1px dashed #cfcfcf;
    }
  `,
  Badge: styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    border-radius: 50px;
    background: #ecfdf3;
    color: #027a48;
    font-size: 12px;
    font-weight: 500;
    padding: 0 8px 0 7px;
    margin: 4px;
    &:before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #00b074;
      margin-right: 7px;
    }
  `,
  FilterContain: styled.div`
    position: relative;
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
  `,
  Filter: styled.div`
    display: none;
    position: absolute;
    width: max-content;
    background: #fff;
    top: calc(100% + 4px);
    left: 0;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    &.active {
      display: block;
    }
  `,
  FilterHead: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    box-sizing: border-box;
    padding: 0 16px;
  `,
  FilterTitle: styled.strong`
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #001a21;
    font-weight: 600;
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background: url(${IcoFilterPopup}) no-repeat center;
      margin-right: 8px;
    }
  `,
  FilterClose: styled.button`
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #f3f4f5;
    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 2px;
      height: 12px;
      background: #3f3f3f;
      content: '';
      display: block;
      border-radius: 5px;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `,
  FilterCont: styled.div`
    padding: 4px 16px 20px;
    display: flex;
    align-items: center;
  `,
  FilterCheckList: styled.div`
    flex: 1;
    width: 94px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    height: 250px;
    padding: 12px 20px;
    &:not(:last-child) {
      margin-right: 4px;
    }
    ul {
      padding: 8px 0;
      &:not(:last-child) {
        border-bottom: 1px dashed #cfcfcf;
      }
      li {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  `,
  FilterFoot: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 16px;
  `,
  AllReset: styled.button`
    display: flex;
    align-items: center;
    color: #3f3f3f;
    font-size: 14px;
    font-weight: 500;
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background: url(${IcoReset}) no-repeat center;
      margin-right: 6px;
    }
  `,
};

const CropFilter = ({ onChangeField, formField, setFormField, onChangeBefore, formBefore, onChangeCrop, formCrop, setFormCrop, refetchSurveyLots, resetFilter }) => {
  const [popState, setPopState] = useState(false);
  const [isCheckDisabled, setIsCheckDisabled] = useState(false);
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const onTogglePopup = () => {
    setPopState(!popState);
  };

  const onChangeFieldByAlert = (e) => {
    if (!e.target.checked) {
      if (!isCheckDisabled) {
        onChangeField(e);
      } else {
        setConfirm({ ...confirm, show: true, message: '하나 이상 선택해야합니다.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
      }
    } else {
      onChangeField(e);
    }
  };

  //필터 1뎁스 체크 갯수 확인
  useEffect(() => {
    if (Object.values(formField).filter((i) => i).length > 1) {
      setIsCheckDisabled(false);
    } else {
      setIsCheckDisabled(true);
    }
  }, [formField]);

  // click 이벤트로 옮기기 !
  // 미판독 선택시, 작물 필터 false
  useEffect(() => {
    const isBefore = Object.keys(formBefore)?.[0] === '미판독';
    if (isBefore) {
      let newObj = {};
      Object.keys(formCrop)?.map((key) => (newObj[key] = false));
      setFormCrop(newObj);
    }
  }, [formBefore]);

  // 판독 선택시, 작물 필터 true
  useEffect(() => {
    const isRate = Object.keys(formBefore)?.[0] === '판독';
    if (isRate) {
      let newObj = {};
      Object.keys(formCrop)?.map((key) => (newObj[key] = true));
      setFormCrop(newObj);
    }
  }, [formBefore]);

  // 필드 - 모두 체크될때 자동으로 [전체] 체크 처리 또는 해제
  useEffect(() => {
    // 4개 모두 true인지 확인(every)
    const fieldAllTrue = Object.entries(formField)
      .filter(([key]) => key !== '전체')
      .every(([key, value]) => value);

    if (fieldAllTrue && !formField['전체']) {
      setFormField((prev) => ({ ...prev, 전체: true }));
    }
    if (!fieldAllTrue && formField['전체']) {
      setFormField((prev) => ({ ...prev, 전체: false }));
    }
  }, [formField, setFormField]);

  // 작물 - 모두 체크될때 자동으로 [전체] 체크 처리 또는 해제
  useEffect(() => {
    // 모두 true인지 확인(every)
    const cropArr = Object.entries(formCrop).filter(([key]) => key !== '전체');
    if (cropArr?.length > 0) {
      const cropAllTrue = cropArr.every(([key, value]) => value);
      if (cropAllTrue && !formCrop['전체']) {
        setFormCrop((prev) => ({ ...prev, 전체: true }));
      }
      if (!cropAllTrue && formCrop['전체']) {
        setFormCrop((prev) => ({ ...prev, 전체: false }));
      }
    }
  }, [formCrop, setFormCrop]);

  // 필드 / 작물 - [전체] 선택시
  const onChangeAll = (e) => {
    const { name, checked } = e.target;

    //필지 [전체]
    if (name === 'Allfield') {
      if (checked) {
        setFormField((prev) => {
          let newform = {};
          Object.keys(prev).map((key) => (newform[key] = checked));
          return newform;
        });
      }
    }

    //작물 [전체]
    if (name === '전체') {
      setFormCrop((prev) => {
        let newform = {};
        Object.keys(prev).map((key) => (newform[key] = checked));
        return newform;
      });
    }
  };

  const onClickResetFilter = () => {
    resetFilter(); // 필터 리셋
    refetchSurveyLots(); // 필터적용된 데이터 요청
  };

  // '선택적용' 버튼 클릭
  const onClickDone = () => {
    onTogglePopup(); // 필터창 닫기
    refetchSurveyLots(); // 필터적용된 데이터 요청
  };

  return (
    <CropFilterStyle.Wrap>
      <CropFilterStyle.Head>
        <CropFilterStyle.FilterContain>
          <CropFilterStyle.Row>
            <CropFilterStyle.Button type={'button'} onClick={onTogglePopup} className={popState ? 'active' : undefined}>
              필터
            </CropFilterStyle.Button>
            <CropFilterStyle.Reset type={'button'} onClick={onClickResetFilter}>
              <span className={'hidden'}>초기화</span>
            </CropFilterStyle.Reset>
          </CropFilterStyle.Row>
          <CropFilterStyle.Filter className={popState ? 'active' : undefined}>
            <CropFilterStyle.FilterHead>
              <CropFilterStyle.FilterTitle>필터</CropFilterStyle.FilterTitle>
              <CropFilterStyle.FilterClose type={'button'} onClick={onTogglePopup}>
                <span className={'hidden'}>닫기</span>
              </CropFilterStyle.FilterClose>
            </CropFilterStyle.FilterHead>
            <CropFilterStyle.FilterCont>
              <CropFilterStyle.FilterCheckList className={'cScroll'}>
                <ul>
                  <li>
                    <Checkbox text="전체" id="Allfield" name="Allfield" onChange={onChangeAll} checked={formField?.['전체']} />
                  </li>
                  <li>
                    <Checkbox text="논" id="논" name="논" onChange={onChangeFieldByAlert} checked={formField?.['논']} />
                  </li>
                  <li>
                    <Checkbox text="밭" id="밭" name="밭" onChange={onChangeFieldByAlert} checked={formField?.['밭']} />
                  </li>
                  <li>
                    <Checkbox text="과수" id="과수" name="과수" onChange={onChangeFieldByAlert} checked={formField?.['과수']} />
                  </li>
                  <li>
                    <Checkbox text="시설" id="시설" name="시설" onChange={onChangeFieldByAlert} checked={formField?.['시설']} />
                  </li>
                  <li>
                    <Checkbox text="비경지" id="비경지" name="비경지" onChange={onChangeFieldByAlert} checked={formField?.['비경지']} />
                  </li>
                </ul>
              </CropFilterStyle.FilterCheckList>
              <CropFilterStyle.FilterCheckList className={'cScroll'}>
                <ul>
                  <li>
                    <Checkbox text="판독" id="판독" name="판독여부" onChange={onChangeBefore} checked={formBefore?.['판독']} type={'radio'} />
                  </li>
                  <li>
                    <Checkbox text="미판독" id="미판독" name="판독여부" onChange={onChangeBefore} checked={formBefore?.['미판독']} type={'radio'} />
                  </li>
                </ul>
              </CropFilterStyle.FilterCheckList>
              {formBefore?.['판독'] ? (
                <CropFilterStyle.FilterCheckList className={'cScroll'}>
                  <ul>
                    {Object.keys(formCrop)?.map((key) => (
                      <li key={`crop_${key}`}>
                        <Checkbox text={key} id={`crop${key}`} name={key} onChange={key === '전체' ? onChangeAll : onChangeCrop} checked={formCrop?.[key]} />
                      </li>
                    ))}
                  </ul>
                </CropFilterStyle.FilterCheckList>
              ) : null}
            </CropFilterStyle.FilterCont>
            <CropFilterStyle.FilterFoot>
              <CropFilterStyle.AllReset type={'button'} onClick={resetFilter}>
                전체 초기화
              </CropFilterStyle.AllReset>
              <Button text={'선택 적용'} height={32} padding={12} fontSize={14} onClick={onClickDone} />
            </CropFilterStyle.FilterFoot>
          </CropFilterStyle.Filter>
        </CropFilterStyle.FilterContain>
      </CropFilterStyle.Head>
      <CropFilterStyle.Cont>
        {Object.values(formField).filter((i) => i).length ? (
          <CropFilterStyle.BadgeGroup>
            {Object.keys(formField).map((key) => {
              if (formField[key]) {
                return <CropFilterStyle.Badge key={key}>{key}</CropFilterStyle.Badge>;
              } else {
                return null;
              }
            })}
          </CropFilterStyle.BadgeGroup>
        ) : null}
        {Object.values(formCrop).filter((i) => i).length ? (
          <CropFilterStyle.BadgeGroup>
            {Object.keys(formCrop).map((key) => {
              if (formCrop[key]) {
                return <CropFilterStyle.Badge key={key}>{key}</CropFilterStyle.Badge>;
              } else {
                return null;
              }
            })}
          </CropFilterStyle.BadgeGroup>
        ) : null}
        {Object.values(formBefore).filter((i) => i).length ? (
          <CropFilterStyle.BadgeGroup>
            {Object.keys(formBefore).map((key) => {
              if (formBefore[key]) {
                return <CropFilterStyle.Badge key={key}>{key}</CropFilterStyle.Badge>;
              } else {
                return null;
              }
            })}
          </CropFilterStyle.BadgeGroup>
        ) : null}
      </CropFilterStyle.Cont>
    </CropFilterStyle.Wrap>
  );
};

export default CropFilter;
