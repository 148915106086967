import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Input, { InputProps, InputStyle } from './Input';

export const LabelInputStyle = {
  Wrap: styled.div<LabelInputProps>`
    display: flex;
    align-items: ${(p) => (p.$alignItem === 'flex-start' ? 'flex-start' : 'center')};
    box-sizing: border-box;
    border-bottom: ${(p) => (p.border ? '1px solid rgba(217, 217, 217, 0.62)' : 'none')};
    padding: ${(p) => (p.padding ? p.padding + 'px 0 ' + p.padding + 'px' : 0)};
  `,
  Key: styled.strong<LabelInputProps>`
    display: flex;
    align-items: center;
    min-width: ${(p) => (p.$labelWidth ? p.$labelWidth + 'px' : '150px')};
    line-height: ${(p) => (p.$labelHeight ? p.$labelHeight + 'px' : '23px')};
    font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '16px')};
    font-weight: 600;
    color: #001a21;
    margin-right: 5px;
    margin-top: ${(p) => (p.$alignItem === 'flex-start' ? '13px' : '0')};

    &:after {
      ${(p) => p.required && 'content:"*";color:#00B074;font-size:16px;font-weight:400;margin-left:2px;'}
    }
  `,
  SubText: styled.p`
    font-size: 16px;
    font-weight: 400;
    color: #001a21;
    margin-left: 12px;
  `,
};

export interface LabelInputProps {
  $labelWidth?: number;
  $labelHeight?: number;
  fontSize?: number;
  type?: string;
  marginbottom?: number;
  $alignItem?: 'flex-start';
  label?: string;
  value?: string | number;
  defaultValue?: string | number;
  padding?: number;
  border?: boolean;
  inputWidth?: number;
  inputHeight?: number;
  required?: boolean;
  placeholder?: string;
  //   onChange?: (e) => void;
  rightButton?: ReactNode;
  $error?: boolean;
  message?: string;
  disabled?: boolean;
}

const LabelInput = ({
  $labelWidth,
  fontSize,
  type,
  marginbottom,
  $alignItem,
  label,
  value,
  $error,
  padding,
  border,
  inputWidth,
  inputHeight,
  required,
  placeholder,
  onChange,
  defaultValue,
  rightButton,
  message,
  disabled,
  name,
}: LabelInputProps & InputProps) => {
  return (
    <>
      <LabelInputStyle.Wrap marginbottom={marginbottom} $alignItem={$alignItem} border={border} padding={padding}>
        <LabelInputStyle.Key $labelWidth={$labelWidth} fontSize={fontSize} required={required} $alignItem={$alignItem}>
          {label}
        </LabelInputStyle.Key>
        <Input type={type} name={name} width={inputWidth} height={inputHeight} value={value} defaultValue={defaultValue} fontSize={fontSize} placeholder={placeholder} onChange={onChange} $error={$error} message={message} disabled={disabled} />
        {rightButton ? rightButton : null}
      </LabelInputStyle.Wrap>
      {message ? (
        <InputStyle.message $error={$error} left={$labelWidth}>
          {message}
        </InputStyle.message>
      ) : null}
    </>
  );
};

export default LabelInput;
