import React from 'react';
import styled from 'styled-components';
import IcoFileUpload from '../../assets/images/button/ico-file-upload.svg';

const FileButtonStyle = {
  Wrap: styled.div`
    position: relative;
    display: flex;
  `,
  Input: styled.input`
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    left: 0;
    top: 0;
  `,
  Label: styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    box-sizing: border-box;
    border: 1px solid #00b074;
    color: #00b074;
    width: auto;
    height: 36px;
    padding: 0 12px;
    font-size: 14px;
    border-radius: 4px;
    background: #fff;
    box-shadow: none;
    cursor: pointer;
    &:hover {
      background: #e5f8e8;
    }
    &:active {
      background: #ceefd4;
    }
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      margin-right: 6px;
      background: url(${IcoFileUpload}) no-repeat center;
    }
  `,
  FileName: styled.input`
    width: 210px;
    height: 36px;
    padding: 0 12px;
    border: 1px solid #e5e5ec;
    border-right: 0;
    border-radius: 4px 0 0 4px;
  `,
};

interface FileButtonProps {
  id: string;
  $isMultiple?: boolean;
  $isShowFileName?: boolean;
  fileName?: string;
  text: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileButton = ({ id, $isMultiple, text, onChange, $isShowFileName, fileName }: FileButtonProps) => {
  return (
    <FileButtonStyle.Wrap>
      {$isShowFileName && fileName ? <FileButtonStyle.FileName type={'text'} readOnly={true} value={fileName} /> : ''}
      <FileButtonStyle.Input id={id} type={'file'} multiple={$isMultiple} onChange={onChange} />
      <FileButtonStyle.Label htmlFor={id}>{text}</FileButtonStyle.Label>
    </FileButtonStyle.Wrap>
  );
};

export default FileButton;
