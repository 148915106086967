import React, { useEffect, useState } from 'react';
import { NextStep, Section } from '../../../../../components/atoms/TabContent';
import { LabelInputStyle } from '../../../../../components/atoms/LabelInput';
import Button from '../../../../../components/atoms/Button';
import IcoFilter from '../../../../../assets/images/button/ico-filter.svg';
import styled from 'styled-components';
import HelpMessage from '../../../../../components/atoms/HelpMessage';
import ScrollTable from '../../../../../components/atoms/ScrollTable';
import IcoPlus from '../../../../../assets/images/button/ico-plus.svg';
import IcoMinus from '../../../../../assets/images/button/ico-minus.svg';
import Select from '../../../../../components/atoms/Select';
import IcoStepDone from '../../../../../assets/images/button/ico-step-done.svg';
import Input, { RangeInput } from '../../../../../components/atoms/Input';
import { useRecoilState } from 'recoil';
import { confirmState } from '../../../../../store/Atoms';
import query from '../../../../../hooks/RQuery/utils';
import IcoStepDoneDisabled from '../../../../../assets/images/button/ico-step-done-disabled.svg';

const FilterStyle = {
  Wrap: styled.div`
    width: 100%;
    height: 100%;
  `,
  NoDataWrap: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f6f6f6;
    border: 1px solid #e7eaee;
    box-sizing: border-box;
    border-radius: 8px;
    height: 100%;
  `,
};

const RoundStep1 = ({ surveyId, surveyList }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [confirm, setConfirm] = useRecoilState(confirmState);

  //option
  const [option, setOption] = useState([]);
  const [roundListOption, setRoundListOption] = useState<any>([]);
  //원본 리스트
  const [originFilterList, setOriginFilterList] = useState([]);

  //수정 적용된 리스트
  const [filterList, setFilterList] = useState([]);

  //추가된 리스트
  const [addFilterList, setAddFilterList] = useState([]);

  //삭제된 리스트
  const [removeFilterList, setRemoveFilterList] = useState([]);

  const conditionList = query.survey.useGetConditions(surveyId);
  const addCondition = query.survey.useAddConditions({ surveyId, list: addFilterList });
  const removeCondition = query.survey.useRemoveConditions({ surveyId, list: removeFilterList });

  //회차 옵션 세팅
  useEffect(() => {
    const newArr: any = [];
    //현재 선택된 회차의 이전 회차 index값
    surveyList?.map((i, index) => {
      if (i.surveyStatus === 'COMPLETED') {
        const lotOption: any = [];
        i.surveyCropList.map((j) => {
          lotOption.push({ text: j.cropName, value: j.surveyCropId, active: false });
        });
        newArr.push({ text: i.surveyNo, value: i.surveyId, active: false, surveyCropList: lotOption });
      }
    });
    setRoundListOption(newArr);
  }, [surveyId]);

  //초기 필터리스트 세팅
  useEffect(() => {
    if (conditionList.data) {
      setFilterList(conditionList.data);
      setOriginFilterList(conditionList.data);
    }
  }, [conditionList.data]);

  //회차 선택시
  const onChangeRoundListOption = (e, num) => {
    const value = e.target.ariaValueText;
    const newArr: any = [...roundListOption];
    const newArr2: any = [...filterList];
    newArr.map((i) => {
      i.active = value === String(i.value);
    });
    newArr2[num].targetSurveyNo = newArr.filter(({ active }) => active)[0].text;
    newArr2[num].targetSurveyId = newArr.filter(({ active }) => active)[0].value;
    setFilterList(newArr2);
    setRoundListOption(newArr);
  };

  //작물 선택시 filterList에 값 담음
  const onChangeOption = (e, num) => {
    const value = e.target.ariaValueText;
    const newArr = [...roundListOption];
    const newArr2: any = [...filterList];
    newArr.map((i) => {
      i.surveyCropList.map((j) => {
        j.active = value === j.value;
      });
    });
    newArr2[num].surveyCropId = newArr.filter(({ active }) => active)[0].surveyCropList.filter(({ active }) => active)[0].value;
    newArr2[num].surveyCropName = newArr.filter(({ active }) => active)[0].surveyCropList.filter(({ active }) => active)[0].text;
    setFilterList(newArr2);
    setRoundListOption(newArr);
  };

  //필지 추가 조건 없는 화면에서 조건 추가버튼(수정하기 버튼 공용)
  const onAddFilter = () => {
    const newArr: any = [...filterList];
    newArr.push({ targetSurveyNo: '', targetSurveyId: '', surveyCropId: '', minRemainRate: '0', maxRemainRate: '100' });
    setFilterList(newArr);
    setIsEditMode(true);
  };

  //저장하기. 마지막행에 코드값이 없으면 배열에서 삭제
  const onSave = () => {
    setConfirm({
      ...confirm,
      show: true,
      message: '변경된 사항을 저장하시겠습니까?',
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: () => {
        const newAddArr: any = [];
        const newRemoveArr: any = [];

        filterList.map((i: any) => {
          if (!originFilterList.some(({ surveyCropId }) => surveyCropId === i.surveyCropId)) {
            if (i.surveyCropId) {
              newAddArr.push(i);
            }
          }
        });
        setAddFilterList(newAddArr);

        originFilterList.map((i: any) => {
          if (!filterList.some(({ surveyCropId }) => surveyCropId === i.surveyCropId)) {
            newRemoveArr.push(i);
          }
        });
        setRemoveFilterList(newRemoveArr);
        setConfirm({ ...confirm, show: false });

        const promises: any = [];

        if (!!newAddArr.length) {
          promises.push(
            new Promise((resolve) => {
              addCondition.mutate(
                { surveyId, list: addFilterList },
                {
                  onSuccess: (res) => {
                    resolve('success');
                    console.log(res);
                  },
                },
              );
            }),
          );
        }

        if (!!newRemoveArr.length) {
          promises.push(
            new Promise((resolve) => {
              removeCondition.mutate(
                { surveyId, list: removeFilterList },
                {
                  onSuccess: (res) => {
                    resolve('success');
                    console.log(res);
                  },
                },
              );
            }),
          );
        }

        Promise.all(promises)
          .then(() => {
            setConfirm({
              ...confirm,
              show: true,
              message: '변경이 완료되었습니다.',
              leftText: undefined,
              rightText: '확인',
              rightFunc: () => {
                setConfirm({ ...confirm, show: false });
                setIsEditMode(false);
              },
            });
            setFilterList([...filterList].filter(({ name, surveyCropName }) => name && surveyCropName));
          })
          .catch((error) => {
            // 에러 처리
            setConfirm({ ...confirm, show: true, message: '변경 실패. 관리자에게 문의하세요.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
          });
      },
    });
  };

  //필지 추가 조건 row 추가
  const onAddRow = (num) => {
    const newArr: any = [...filterList];
    //선택한 작물(코드값) 있음
    if (!!newArr[num].surveyCropId) {
      //최대값이 최소값보다 크거나 같음
      if (Number(newArr[num].minRemainRate) <= Number(newArr[num].maxRemainRate)) {
        newArr.push({ targetSurveyNo: '', surveyCropId: '', targetSurveyId: '', minRemainRate: '0', maxRemainRate: '100' });
        setFilterList(newArr);
        const newOption: any = [...roundListOption];
        //옵션 초기화
        newOption.map((i) => {
          i.active = false;
          const cropList: any = [];
          i.surveyCropList.map((j) => {
            cropList.push({ ...j, active: false });
          });
          i.surveyCropList = cropList;
        });
        setOption(newOption);
      } else {
        //최대값이 최소값보다 작을때
        setConfirm({ ...confirm, message: '최대값이 최소값보다 작습니다.', show: true, leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
      }
    } else {
      //선택한 작물(코드값) 없을때
      setConfirm({ ...confirm, message: '작물을 선택하세요', show: true, leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
    }
  };

  //row 삭제
  const onRemoveRow = (num) => {
    const newArr: any = [...filterList];
    setFilterList(newArr.filter((i, index) => index !== num));

    if (num > 0) {
      //삭제 후 마지막 row select 변경
      const newArr2: any = [...option];
      newArr2.map((i) => {
        i.active = String(i.value) === String(newArr[num - 1].surveyCropId);
      });
      setOption(newArr2);
    }
  };

  //잔여량 입력 (key = min or max)
  const onChangeValue = (e, num, key) => {
    const value = e.target.value;
    const newArr: any = [...filterList];
    newArr[num][key] = value;
    setFilterList(newArr);
  };
  return (
    <div style={{ height: '100%' }}>
      <Section style={{ height: 'calc(100% - 39px)' }}>
        <LabelInputStyle.Wrap style={{ height: '100%' }} $alignItem={'flex-start'}>
          <LabelInputStyle.Key>필지 추가 조건</LabelInputStyle.Key>
          <div style={{ width: 1116, height: '100%' }}>
            {filterList.length ? (
              isEditMode ? (
                <>
                  <ScrollTable
                    $minHeight={'calc(100% - 82px)'}
                    caption={'필지 추가 조건'}
                    colgroup={[60, 140, 140, 'auto', 120]}
                    thead={
                      <tr>
                        <th scope={'col'} style={{ paddingLeft: 20 }}>
                          #
                        </th>
                        <th scope={'col'}>회차</th>
                        <th scope={'col'}>작물</th>
                        <th scope={'col'}>잔여량(%)</th>
                        <th scope={'col'}></th>
                      </tr>
                    }
                    tbody={
                      <>
                        {filterList.map((i: any, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ paddingLeft: 20 }}>{index + 1}</td>
                              <td>
                                {filterList.length - 1 === index ? (
                                  <Select placeHolder={'선택'} direction={filterList.length > 5 ? 'upper' : undefined} $borderColor={'#E5E5EC'} option={roundListOption} onChange={(e) => onChangeRoundListOption(e, index)} width={120} height={40} />
                                ) : (
                                  i.targetSurveyNo + '회차'
                                )}
                              </td>
                              <td>
                                {filterList.length - 1 === index ? (
                                  <Select
                                    placeHolder={'선택'}
                                    direction={filterList.length > 5 ? 'upper' : undefined}
                                    $borderColor={'#E5E5EC'}
                                    option={roundListOption.filter(({ active }) => active)[0]?.surveyCropList}
                                    onChange={(e) => onChangeOption(e, index)}
                                    width={120}
                                    height={40}
                                  />
                                ) : (
                                  i.surveyCropName
                                )}
                              </td>
                              <td>
                                {filterList.length - 1 === index ? (
                                  <RangeInput>
                                    <Input width={112} height={40} type={'number'} min={'0'} max={String(i.maxRemainRate) || '100'} placeholder={'최소값'} onChange={(e) => onChangeValue(e, index, 'minRemainRate')} value={String(i.minRemainRate)} />
                                    <span>~</span>
                                    <Input width={112} height={40} type={'number'} min={String(i.minRemainRate) || '0'} max={'100'} placeholder={'최대값'} onChange={(e) => onChangeValue(e, index, 'maxRemainRate')} value={String(i.maxRemainRate)} />
                                  </RangeInput>
                                ) : (
                                  <p>
                                    <span style={{ color: '#00B074' }}>{i.minRemainRate}%</span> ~ <span style={{ color: '#00B074' }}>{i.maxRemainRate}%</span> 까지 필지 추가
                                  </p>
                                )}
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    marginRight: 20,
                                  }}
                                >
                                  {filterList.length - 1 === index ? <Button $buttonType={'large'} width={40} height={40} padding={10} icon={IcoPlus} $iconSize={15} style={{ marginRight: 6 }} onClick={() => onAddRow(index)} /> : null}
                                  {filterList.length > 1 ? <Button $buttonType={'medium'} width={40} height={40} padding={10} icon={IcoMinus} $iconSize={15} onClick={() => onRemoveRow(index)} /> : null}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    }
                  />
                  <NextStep>
                    <Button $buttonType={'large'} text={'저장하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={onSave} $iconDisabled={IcoStepDoneDisabled} />
                  </NextStep>
                </>
              ) : (
                <>
                  <ScrollTable
                    $minHeight={'calc(100% - 82px)'}
                    caption={'필지 추가 조건'}
                    colgroup={[60, 140, 140, 'auto', 120]}
                    thead={
                      <tr>
                        <th scope={'col'} style={{ paddingLeft: 20 }}>
                          #
                        </th>
                        <th scope={'col'}>회차</th>
                        <th scope={'col'}>작물</th>
                        <th scope={'col'}>잔여량(%)</th>
                      </tr>
                    }
                    tbody={
                      <>
                        {filterList.map((i: any, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ paddingLeft: 20 }}>{index + 1}</td>
                              <td>{i.targetSurveyNo + '회차'}</td>
                              <td>{i.surveyCropName}</td>
                              <td>
                                <p>
                                  <span style={{ color: '#00B074' }}>{i.minRemainRate}%</span> ~ <span style={{ color: '#00B074' }}>{i.maxRemainRate}%</span> 까지 필지 추가
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    }
                  />
                  <NextStep>
                    <Button $buttonType={'large'} text={'수정하기'} $icoPosition={'right'} onClick={onAddFilter} />
                  </NextStep>
                </>
              )
            ) : (
              <FilterStyle.Wrap>
                <FilterStyle.NoDataWrap>
                  <HelpMessage message={'다음 회차에 <b>추가 할 필지</b>를 선택해주세요.'} style={{ marginBottom: 16 }} />
                  <Button $buttonType={'medium'} text={'필지 추가 조건 추가'} icon={IcoFilter} $iconSize={20} $iconSpacing={6} onClick={onAddFilter} />
                </FilterStyle.NoDataWrap>
              </FilterStyle.Wrap>
            )}
          </div>
        </LabelInputStyle.Wrap>
      </Section>
    </div>
  );
};

export default RoundStep1;
