import axios from 'axios';

export const request = axios.create({
  baseURL: '/farm-ez/api',
  headers: {
    'Content-Type': 'application/json',
    Authorization: sessionStorage.getItem('token'),
  },
});

// form-data 전송용 request
export const requestFormData = axios.create({
  baseURL: '/farm-ez/api',
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: sessionStorage.getItem('token'),
  },
});

// 로그인
export const fetchLogin = async (params: { email: string; password: string }) => {
  const { data } = await request.post(`/auth/login`, params);

  return data.datas;
};
//
