import React, { useEffect, useState } from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import Select from '../components/atoms/Select';
import NoData from '../components/atoms/NoData';
import IcoLot from '../assets/images/noData/ico-lot.svg';
import PagingTable from '../components/atoms/PagingTable';
import Checkbox from '../components/atoms/Checkbox';
import SearchInput from '../components/molcures/SearchInput';
import { useRecoilState } from 'recoil';
import { confirmState, formState } from '../store/Atoms';
import DaumPostcodeEmbed from 'react-daum-postcode';

const Content = {
  Inner: styled.div`
    padding: 24px 0;
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    > * {
      flex: 1;
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  `,
};

const PopupPostCode = ({ onClose }) => {
  const [form, setForm] = useRecoilState(formState);

  const completeHandler = (data) => {
    const { address, zonecode } = data;
    setForm({ ...form, zipcode: zonecode, addr: address });
  };

  return (
    <PopupTemplate
      title={'주소 찾기'}
      width={668}
      isDimmed={true}
      onClose={onClose}
      content={
        <Content.Inner>
          <DaumPostcodeEmbed style={{ height: 600 }} onComplete={completeHandler} onClose={onClose} />
        </Content.Inner>
      }
    />
  );
};

export default PopupPostCode;
