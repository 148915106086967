const MainKey = {
  MAP: 'map',
  ADDRESS: 'address',
  IMAGE: 'image',
  PROJECT: 'project',
  MEMBER: 'member',
  CLIENT: 'client',
  FARM_MAP: 'farmMap',
  SURVEY: 'survey',
};

export const mapKeys = {
  all: [MainKey.MAP],
};

export const addressKeys = {
  all: [MainKey.ADDRESS],
};

//image-controller
export const imageKeys = {
  upload: [MainKey.IMAGE],
};

//project-controller
export const projectKeys = {
  all: [MainKey.PROJECT],
  list: (params) => [MainKey.PROJECT, { ...params }],
  detail: (projectId) => [MainKey.PROJECT, 'detail', { projectId }],
  lots: (projectId) => [MainKey.PROJECT, 'lots', { projectId }],
  userList: [MainKey.PROJECT, 'user'],
  shp: (projectId) => [MainKey.PROJECT, 'shp', { projectId }],
};

//inspector 발주처 - 검수
export const clientKeys = {
  all: [MainKey.CLIENT],
  list: (params) => [MainKey.CLIENT, { ...params }],
  detail: (inspectorCompanyId) => [MainKey.CLIENT, 'detail', { inspectorCompanyId }],
  edit: (inspectorCompanyId) => [MainKey.CLIENT, 'edit', { inspectorCompanyId }],
  crops: (inspectorCompanyId) => [MainKey.CLIENT, 'crops', { inspectorCompanyId }],
};

//member-controller
export const memberKeys = {
  all: [MainKey.MEMBER],
  list: (params) => [MainKey.MEMBER, { ...params }],
  projects: (memberId) => [MainKey.MEMBER, 'projects', { memberId }],
  duplicate: [MainKey.MEMBER, 'duplicate'],
  addUser: [MainKey.MEMBER, 'addUser'],
  modifyUser: [MainKey.MEMBER, 'modifyUser'],
};

//farmmap-controller
export const farmMapKeys = {
  all: [MainKey.FARM_MAP],
};

// 사업 회차(surveyId)
export const surveyKeys = {
  all: [MainKey.SURVEY],
  surveyData: ({ surveyFarmMapId }) => [MainKey.SURVEY, 'surveyData', { surveyFarmMapId }],
  lots: ({ surveyId, pageNum, stdgCd, surveyCropIds }) => [MainKey.SURVEY, 'lots', { surveyId, pageNum, stdgCd }],
  singleLot: ({ surveyId, surveyFarmMapId }) => [MainKey.SURVEY, 'lot', { surveyId, surveyFarmMapId }],
  crops: (surveyId) => [MainKey.SURVEY, 'crops', { surveyId }],
  getCondition: (surveyId) => [MainKey.SURVEY, 'condition', { surveyId }],
  addCondition: (surveyId) => [MainKey.SURVEY, 'condition', { surveyId }],
  removeCondition: (surveyId) => [MainKey.SURVEY, 'condition', { surveyId }],
  map: (surveyId) => [MainKey.SURVEY, 'map', { surveyId }],
  duplicatedImage: (surveyId) => [MainKey.SURVEY, 'duplicatedImage', { surveyId }],
  uploadImageList: (surveyId) => [MainKey.SURVEY, 'uploadImageList', { surveyId }],
  inspect: (surveyFarmMapId) => [MainKey.SURVEY, 'inspect', { surveyFarmMapId }],
  chart: (surveyId) => [MainKey.SURVEY, 'chart', { surveyId }],
};

// 필수 !! invalidate 필요
const queryKeys = {
  MainKey,
  mapKeys,
  addressKeys,
  imageKeys,
  projectKeys,
  clientKeys,
  memberKeys,
  farmMapKeys,
  surveyKeys,
};
export default queryKeys;
