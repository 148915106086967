import { useEffect, useState } from 'react';
import styled from 'styled-components';
import IcoMenu from 'assets/images/map/ico-menu.svg';
import CropInfoBox from '../InfoBox/CropInfoBox';
import { useRecoilState } from 'recoil';
import { confirmState, stdgCdState } from '../../../store/Atoms';

const CropInfoBtnSt = {
  Button: styled.button<{ $isAdmin?: boolean }>`
    position: fixed;
    top: 80px;
    top: ${(p) => (p.$isAdmin ? '16px' : '80px')};
    right: 18px;
    z-index: 10;
    width: 48px;
    height: 48px;
    background-color: #024751;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icoMenu {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(${IcoMenu}) no-repeat center;
    }
  `,
};

export function CropInfo({ round, isSingleLot, setIsSingleLot, singleFarmMapId, showCropInfo, setShowCropInfo, onChangeFeatureStatus }) {
  //   const [showCropInfo, setShowCropInfo] = useState(false); // 작물 정보 팝업 오픈
  const isAdmin = sessionStorage.getItem('auth') === 'ROLE_ADMIN';
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [stdgCd] = useRecoilState(stdgCdState);

  return (
    <>
      <CropInfoBtnSt.Button
        $isAdmin={isAdmin}
        onClick={() => {
          if (stdgCd.code) {
            setShowCropInfo(true);
            setIsSingleLot(false);
          } else {
            setConfirm({ ...confirm, show: true, message: '주소를 먼저 선택하세요.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
          }
        }}
      >
        <span className="icoMenu"></span>
      </CropInfoBtnSt.Button>

      {showCropInfo && <CropInfoBox close={() => setShowCropInfo(false)} round={round} isSingleLot={isSingleLot} setIsSingleLot={setIsSingleLot} singleFarmMapId={singleFarmMapId} isAdmin={isAdmin} onChangeFeatureStatus={onChangeFeatureStatus} />}
    </>
  );
}
