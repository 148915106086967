import React, { useEffect, useState } from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import IcoInfo from '../assets/images/button/ico-info.svg';
import IcoInfoDisabled from '../assets/images/button/ico-info-disabled.svg';
import Pagination from '../components/atom/Pagination';
import IcoPic from '../assets/images/button/ico-pic.svg';
import IcoPicCompletion from '../assets/images/button/ico-pic-completion.svg';

import ImageW from '../assets/images/image-w.png';
import ImageZ from '../assets/images/image-z.png';
import IcoPass from '../assets/images/button/ico-pass.svg';
import IcoNext from '../assets/images/button/ico-next.svg';
import IcoNextDisabled from '../assets/images/button/ico-next-disabled.svg';
import IcoNoData from '../assets/images/noData/ico-no-image.svg';
import NoData from '../components/atoms/NoData';
import query from '../hooks/RQuery/utils';
import { ActiveAreaSt } from 'components/templates/InfoBox/_style';
import { useRecoilState } from 'recoil';
import { confirmState } from '../store/Atoms';
import { formatNumber } from 'util/global';

const Content = {
  Wrap: styled.div`
    display: flex;
    height: 744px;
  `,
  LeftCol: styled.div`
    width: 304px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-right: 1px solid #d9d9d9;
    background: #f0f0f0;
  `,
  RightCol: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #f0f0f0;
  `,
  Head: styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    background: #ffffff;
    box-sizing: border-box;
    border-bottom: 1px solid #d9d9d9;
  `,
  Cont: styled.div`
    height: calc(100% - 40px);
  `,
  ListCont: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 40px);
    box-sizing: border-box;
  `,
  Foot: styled.div`
    display: flex;
    align-items: center;
    height: 64px;
    box-sizing: border-box;
    border-top: 1px solid #d9d9d9;
  `,
  ButtonArea: styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
  `,
  PicType: styled.div`
    width: 236px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    color: #00b074;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 500;
  `,
  PicBox: styled.div`
    flex: 1;
    height: 100%;
    padding: 8px 12px;
    box-sizing: border-box;
    &:first-child {
      border-right: 1px solid #d9d9d9;
    }
  `,
  Scroll: styled.div`
    margin-top: 10px;
    height: calc(100% - 58px);
  `,
  Tip: styled.p`
    padding-left: 16px;
    color: #00b074;
    font-size: 14px;
    font-weight: 500;
  `,
  Count: styled.div`
    display: flex;
    align-items: center;
    color: #00b074;
    font-size: 14px;
    font-weight: 500;
    span {
      margin-left: 4px;
      color: #024751;
      font-weight: 600;
    }
  `,
  List: styled.ul`
    height: calc(100% - 16px);
    padding: 8px 12px;
  `,
  Item: styled.li`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 44px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e9e7e7;
    padding: 0 16px;
    background: #fff;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
    &.completion {
      background: #ceefd4;
      border-color: #b0dfb9;
      span {
        background: #00b074;
      }
    }
    &.active {
      border: 2px solid #00b074;
      padding: 0 15px;
    }
    strong {
      color: #024751;
      font-size: 14px;
      font-weight: 500;
      margin-right: 8px;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      margin-left: 4px;
      background: #024751;
      &:nth-child(2) {
        margin-left: auto;
      }
    }
  `,
};

const PopupDuplicationPic = ({ onClose, round, onChangeFeatureStatus }) => {
  const surveyId = round.filter(({ active }) => active)[0]?.value;
  const duplicatedList = query.survey.useDuplicatedImages(surveyId);
  const submitDuplicate = query.survey.useDeleteDuplicateImage(surveyId);
  const [list, setList] = useState<any>([]);
  const [confirm, setConfirm] = useRecoilState(confirmState);

  // 중복 이미지 리스트 state 저장
  useEffect(() => {
    if (duplicatedList.data) {
      const newArr: any = [];
      duplicatedList.data.map((i, index) => {
        const newwideImages: any = [];
        const newzoomImages: any = [];
        i.wideImages.map((j) => {
          newwideImages.push({ ...j, active: false, selected: false });
        });

        i.zoomImages.map((j) => {
          newzoomImages.push({ ...j, active: false, selected: false });
        });
        newArr.push({ ...i, wideImages: newwideImages, zoomImages: newzoomImages, active: index === 0 });
      });
      setList(newArr);
    }
  }, [duplicatedList.data]);

  //사진선택
  const onPickImage = (type: 'W' | 'Z', item) => {
    const newArr = [...list];
    newArr.map((i) => {
      if (type === 'W') {
        i.wideImages.map((j) => {
          j.active = j.imageId === item.imageId;
          j.selected = j.imageId !== item.imageId;
        });
      } else if (type === 'Z') {
        i.zoomImages.map((j) => {
          j.active = j.imageId === item.imageId;
          j.selected = j.imageId !== item.imageId;
        });
      }
    });
    setList(newArr);
  };

  //건너뛰기
  const onJump = () => {
    const newArr = [...list];
    const index = newArr.findIndex(({ active }) => active);
    newArr.map((i, idx) => {
      i.active = index + 1 === idx;
      //이미지 선택 초기화
      i.wideImages.map((j) => {
        j.active = false;
        j.selected = false;
      });
      i.zoomImages.map((j) => {
        j.active = false;
        j.selected = false;
      });
    });

    setList(newArr);
  };

  //필지 선택
  const onClickLot = (num) => {
    const newArr = [...list];
    newArr.map((i, idx) => {
      i.active = num === idx;
      //이미지 선택 초기화
      i.wideImages.map((j) => {
        j.active = false;
        j.selected = false;
      });
      i.zoomImages.map((j) => {
        j.active = false;
        j.selected = false;
      });
    });
    setList(newArr);
  };

  //다음사진(저장)
  const onSubmit = () => {
    const surveyFarmMapId = list.filter(({ active }) => active)[0].surveyFarmMapId;
    const wideImageId = list.filter(({ active }) => active)[0].wideImages.filter(({ active }) => active)[0].imageId;
    const zoomImageId = list.filter(({ active }) => active)[0].zoomImages.filter(({ active }) => active)[0].imageId;
    setConfirm({
      ...confirm,
      show: true,
      message: '사진을 선택하시겠습니까?<br>(선택하지 않은 사진은 삭제됩니다)',
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: () => {
        //저장
        submitDuplicate.mutate(
          { surveyId, surveyFarmMapId, wideImageId, zoomImageId },
          {
            onSuccess: (res: any) => {
              onChangeFeatureStatus(list, 1, true);
              duplicatedList.refetch();
              setConfirm({
                ...confirm,
                show: true,
                message: res?.message || '사진 최종선택 완료',
                leftText: undefined,
                rightText: '확인',
                rightFunc: () => setConfirm({ ...confirm, show: false }),
              });
            },
            onError: (err: any) => {
              setConfirm({
                ...confirm,
                show: true,
                message: err?.response?.data?.message || '요청에 실패하였습니다.',
                leftText: undefined,
                rightText: '확인',
                isError: true,
                rightFunc: () => setConfirm({ ...confirm, show: false }),
              });
            },
          },
        );
      },
    });
  };

  //다음사진(저장) 버튼 disabled여부
  const isDisabled = () => {
    const wideImageId = list?.filter(({ active }) => active)[0]?.wideImages.filter(({ active }) => active)[0]?.imageId;
    const zoomImageId = list?.filter(({ active }) => active)[0]?.zoomImages.filter(({ active }) => active)[0]?.imageId;
    return !(!!wideImageId && !!zoomImageId);
  };

  return (
    <PopupTemplate
      title={'사진 중복 확인'}
      width={824}
      $type={2}
      isDimmed={true}
      onClose={onClose}
      content={
        <Content.Wrap>
          <Content.LeftCol>
            <Content.Head style={{ justifyContent: 'center', background: list.length ? '#fff' : 'transparent' }}>
              <Content.Count>
                중복 사진 필지 / <span>{formatNumber(list.length)}건</span>
              </Content.Count>
            </Content.Head>
            <Content.Cont style={{ display: 'block' }}>
              {list.length ? (
                <Content.List className="cScroll">
                  {list.map((item, index) => {
                    return (
                      <Content.Item key={item.surveyFarmMapId} className={item.active ? 'active' : ''} onClick={() => onClickLot(index)}>
                        <strong>{item.mapId}</strong>
                        <span style={item.wideImages.length > 1 ? { background: '#FF7600' } : {}}>{item.wideImages.length}</span>
                        <span style={item.wideImages.length > 1 ? { background: '#FF7600' } : {}}>{item.zoomImages.length}</span>
                      </Content.Item>
                    );
                  })}
                </Content.List>
              ) : null}
            </Content.Cont>
          </Content.LeftCol>
          <Content.RightCol>
            {list.length ? (
              <Content.Head>
                <Content.Tip>* 필지 하나 당 하나의 사진만 선택해서 등록하세요.</Content.Tip>
              </Content.Head>
            ) : null}
            <Content.Cont style={{ height: 'calc(100% - 64px)' }}>
              {list.length ? (
                <>
                  <Content.ListCont>
                    <Content.PicBox>
                      <Content.PicType>근경</Content.PicType>
                      <Content.Scroll className={'cScroll'}>
                        {list
                          .filter(({ active }) => active)
                          .map((i) => {
                            return i.wideImages?.map((item) => {
                              return (
                                <ActiveAreaSt.PicCard key={item.imageId} className={item.active ? 'active' : item.selected ? 'disabled' : ''} style={{ width: 'auto' }}>
                                  <ActiveAreaSt.PicCardInner>
                                    <ActiveAreaSt.PicBox>
                                      <ActiveAreaSt.PicTitle>{item.fileName}</ActiveAreaSt.PicTitle>
                                      <img src={item.thumbnail} alt={item.fileName + '_원경'} />
                                      <Button $buttonType={'medium'} text={'사진 선택'} $iconSize={24} icon={IcoPic} $iconSpacing={6} $isCompletion={item.active} $iconCompletion={IcoPicCompletion} onClick={() => onPickImage('W', item)} />
                                    </ActiveAreaSt.PicBox>
                                  </ActiveAreaSt.PicCardInner>
                                </ActiveAreaSt.PicCard>
                              );
                            });
                          })}
                      </Content.Scroll>
                    </Content.PicBox>
                    <Content.PicBox>
                      <Content.PicType>원경</Content.PicType>
                      <Content.Scroll className={'cScroll'}>
                        {list
                          .filter(({ active }) => active)
                          .map((i) => {
                            return i.zoomImages?.map((item) => {
                              return (
                                <ActiveAreaSt.PicCard key={item.imageId} className={item.active ? 'active' : item.selected ? 'disabled' : ''} style={{ width: 'auto' }}>
                                  <ActiveAreaSt.PicCardInner>
                                    <ActiveAreaSt.PicBox>
                                      <ActiveAreaSt.PicTitle>{item.fileName}</ActiveAreaSt.PicTitle>
                                      <img src={item.thumbnail} alt={item.fileName + '_근경'} />
                                      <Button $buttonType={'medium'} text={'사진 선택'} $iconSize={24} icon={IcoPic} $iconSpacing={6} $isCompletion={item.active} $iconCompletion={IcoPicCompletion} onClick={() => onPickImage('Z', item)} />
                                    </ActiveAreaSt.PicBox>
                                  </ActiveAreaSt.PicCardInner>
                                </ActiveAreaSt.PicCard>
                              );
                            });
                          })}
                      </Content.Scroll>
                    </Content.PicBox>
                  </Content.ListCont>
                  <Content.Foot>
                    <Content.ButtonArea>
                      <Button $buttonType={'large'} text={'건너뛰기'} height={36} icon={IcoPass} $icoPosition={'right'} $iconSize={24} $iconSpacing={6} padding={12} fontSize={14} style={{ marginRight: 8 }} onClick={onJump} />
                      <Button text={'다음사진'} height={36} icon={IcoNext} $icoPosition={'right'} $iconSize={24} $iconSpacing={6} padding={12} fontSize={14} onClick={onSubmit} disabled={isDisabled()} $iconDisabled={IcoNextDisabled} />
                    </Content.ButtonArea>
                  </Content.Foot>
                </>
              ) : (
                <NoData text={duplicatedList?.isFetching ? '필지 데이터를 불러오는 중입니다.' : '중복되는 사진을<br/>모두 삭제 처리하였습니다.'} icon={IcoNoData} $iconSize={48} fontSize={16} padding={[79, 79]} />
              )}
            </Content.Cont>
          </Content.RightCol>
        </Content.Wrap>
      }
    />
  );
};

export default PopupDuplicationPic;
