import React, { useEffect, useState } from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import Select from '../components/atoms/Select';
import NoData from '../components/atoms/NoData';
import IcoLot from '../assets/images/noData/ico-lot.svg';
import PagingTable from '../components/atoms/PagingTable';
import Checkbox from '../components/atoms/Checkbox';
import SearchInput from '../components/molcures/SearchInput';
import { useRecoilState } from 'recoil';
import { confirmState } from '../store/Atoms';
import query from '../hooks/RQuery/utils';
import ScrollTable from '../components/atoms/ScrollTable';

const Content = {
  Inner: styled.div`
    padding: 24px 0;
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    > * {
      flex: 1;
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  `,
};

const PopupAddReader = ({ onClose, involve, setInvolve }) => {
  const companyId = sessionStorage.getItem('companyId');

  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [condition, setCondition] = useState('');
  const [tableData, setTableData] = useState([]);

  const readerList = query.member.useGetMembers({ type: 'contract', companyId: companyId, pageNum: 1, condition: condition, size: null, inspectorCompanyId: null });

  useEffect(() => {
    if (readerList.data) {
      const newArr: any = [];
      //목록에 이미 등록된 검수자 빼고 팝업에 리스트업
      readerList.data.content.map((i) => {
        if (!involve.filter(({ memberId }) => memberId === i.memberId).length) {
          newArr.push({ ...i, checked: false });
        }
      });
      setTableData(newArr);
    }
  }, [readerList.data]);

  //체크박스 클릭
  const onCheck = (id) => {
    const newArr: any = [...tableData];
    if (newArr.filter(({ checked }) => checked).length + involve.length > 29) {
      if (newArr.filter(({ memberId }) => memberId === id)[0].checked) {
        newArr.find((i) => i.memberId === id)!.checked = !newArr.find((i) => i.memberId === id)?.checked;
        setTableData(newArr);
      } else {
        setConfirm({ ...confirm, show: true, message: '판독자는 최대 30명까지 추가 가능합니다.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
      }
    } else {
      newArr.find((i) => i.memberId === id)!.checked = !newArr.find((i) => i.memberId === id)?.checked;
      setTableData(newArr);
    }
  };

  //추가하기 버튼 클릭
  const onAdd = () => {
    setConfirm({
      ...confirmState,
      show: true,
      message: '판독자를 추가하시겠습니까?',
      leftText: '취소',
      rightText: '확인',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: () => {
        const newArr: any = [...involve];
        const newArr2: any = [];
        //체크된 검수자는 목록에 추가
        tableData
          .filter(({ checked }) => checked)
          .map((i: any) => {
            newArr.push({ ...i, checked: false });
          });

        //체크 안된 검수자만 팝업에 리스트업
        tableData
          .filter(({ checked }) => !checked)
          .map((i: any) => {
            newArr2.push({ ...i, checked: false });
          });
        setInvolve(newArr);
        setTableData(newArr2);
        setConfirm({ ...confirm, show: false });
        onClose();
      },
    });
  };

  return (
    <PopupTemplate
      title={'판독자 추가'}
      width={668}
      isDimmed={true}
      onClose={onClose}
      footer={<Button width={'full'} text={`추가하기(${tableData.filter(({ checked }) => checked).length})`} onClick={onAdd} disabled={!tableData.filter(({ checked }) => checked).length} />}
      content={
        <Content.Inner>
          <SearchInput
            onChange={(e) => setCondition(e.target.value)}
            onClick={() => {
              readerList.refetch();
            }}
            inputWidth={320}
            height={48}
            placeholder={'이름 또는 E-mail로 검색'}
          />
          <ScrollTable
            $maxHeight={480}
            caption={'판독자 목록'}
            colgroup={[60, 160, 'auto']}
            thead={
              <tr>
                <th scope={'col'}></th>
                <th scope={'col'}>이름</th>
                <th scope={'col'}>이메일</th>
              </tr>
            }
            tbody={
              <>
                {tableData.length
                  ? tableData.map((i: any) => {
                      return (
                        <tr key={i.memberId}>
                          <td>
                            <Checkbox id={'readerList_' + i.memberId} checked={i.checked} onChange={() => onCheck(i.memberId)} />
                          </td>
                          <td>{i.name}</td>
                          <td>{i.email}</td>
                        </tr>
                      );
                    })
                  : null}
              </>
            }
          />
        </Content.Inner>
      }
    />
  );
};

export default PopupAddReader;
