import React, { useEffect, useState } from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import Select from '../components/atoms/Select';
import NoData from '../components/atoms/NoData';
import IcoLot from '../assets/images/noData/ico-lot.svg';
import PagingTable from '../components/atoms/PagingTable';
import Checkbox from '../components/atoms/Checkbox';
import SearchInput from '../components/molcures/SearchInput';
import { useRecoilState } from 'recoil';
import { confirmState, formState } from '../store/Atoms';
import LabelInput from '../components/atoms/LabelInput';
import { Section } from '../components/atoms/TabContent';
import LabelSelect from '../components/atoms/LabelSelect';
import query from '../hooks/RQuery/utils';
import { checkEmail, checkPassword } from '../util/regCheck';

const Content = {
  Inner: styled.div`
    padding: 24px 0;
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    > * {
      flex: 1;
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  `,
};

const initForm = {
  email: '',
  emailCheck: false,
  name: '',
  inspectorCompanyId: null,
  password: '',
  passwordCheck: false,
  passwordConfirm: '',
};

const PopupSignUpReader = ({ onClose }) => {
  const companyId = sessionStorage.getItem('companyId');
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [form, setForm] = useRecoilState(formState);
  const [checkId, setCheckId] = useState({
    isError: false,
    message: '',
  });

  const checkDuplicateEmail = query.member.useDuplicateEmail(form.email);
  //검수자 등록
  const addReader = query.member.useAddMember({ ...form, companyId: companyId });

  // 등록하기 버튼 disabled 값
  const isDisabled = () => {
    return !(form.email && form.emailCheck && form.name && form.password && form.password === form.passwordConfirm && form.passwordCheck);
  };

  useEffect(() => {
    setForm(initForm);
  }, []);

  //등록하기 버튼 클릭
  const onAdd = () => {
    setConfirm({
      ...confirmState,
      show: true,
      message: '등록하시겠습니까?',
      leftText: '취소',
      rightText: '확인',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: () => {
        addReader.mutate(
          { ...form, companyId: companyId },
          {
            onSuccess: (res) => {
              const message = (res as any)?.message;
              setConfirm({
                ...confirm,
                message: message,
                show: true,
                leftText: undefined,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                  setForm(initForm);
                  onClose();
                },
              });
            },
            onError: (err) => {
              const message = (err?.response as any)?.data?.message;
              setConfirm({
                ...confirm,
                message: message,
                show: true,
                leftText: undefined,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                },
              });
            },
          },
        );
      },
    });
  };

  //이메일 중복체크
  const onCheckEmail = () => {
    if (checkEmail(form.email)) {
      checkDuplicateEmail.refetch();
    } else {
      setConfirm({ ...confirm, show: true, message: '이메일 형식이 올바르지 않습니다.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
    }
  };

  useEffect(() => {
    if (form.email) {
      if (checkDuplicateEmail.isSuccess) {
        setForm({ ...form, emailCheck: true });
        setCheckId({ isError: false, message: checkDuplicateEmail.data.message });
      } else if (checkDuplicateEmail.isError) {
        setForm({ ...form, emailCheck: false });
        setCheckId({ isError: true, message: (checkDuplicateEmail.error as any)?.response?.data?.message });
      }
    }
  }, [checkDuplicateEmail.data, checkDuplicateEmail.error]);

  return (
    <PopupTemplate
      title={'판독자 등록'}
      width={502}
      isDimmed={true}
      onClose={() => {
        setForm(initForm);
        onClose();
      }}
      footer={<Button width={'full'} text={'등록하기'} onClick={onAdd} disabled={isDisabled()} />}
      content={
        <Content.Inner>
          <Section>
            <LabelInput
              label={'이메일(ID)'}
              $labelWidth={126}
              inputWidth={217}
              inputHeight={48}
              required={true}
              placeholder={'이메일을 입력하세요'}
              value={form?.email || ''}
              $error={checkId.isError}
              message={checkId.message}
              onChange={(e) => {
                setForm({ ...form, email: e.target.value, emailCheck: false });
                setCheckId({ isError: true, message: '' });
              }}
              rightButton={<Button $buttonType={'medium'} text={'중복 확인'} width={92} height={48} style={{ marginLeft: 6 }} onClick={onCheckEmail} disabled={form.emailCheck} />}
            />
          </Section>
          <Section>
            <LabelInput
              label={'이름'}
              $labelWidth={126}
              inputWidth={315}
              inputHeight={48}
              required={true}
              placeholder={'이름을 입력하세요'}
              value={form?.name || ''}
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
              }}
            />
          </Section>
          <Section>
            <LabelInput
              type={'password'}
              label={'비밀번호'}
              $labelWidth={126}
              inputWidth={315}
              inputHeight={48}
              required={true}
              placeholder={'비밀번호를 입력하세요'}
              value={form?.password || ''}
              onChange={(e) => {
                setForm({ ...form, password: e.target.value, passwordCheck: true });
              }}
              // $error={form.password && !form.passwordCheck}
              // message={form.password ? (form.passwordCheck ? '사용 가능한 비밀번호 입니다.' : '영문/숫자( / 특수문자) 조합 8자리 이상 조합') : ''}
            />
          </Section>
          <Section>
            <LabelInput
              type={'password'}
              label={'비밀번호 확인'}
              $labelWidth={126}
              inputWidth={315}
              inputHeight={48}
              required={true}
              placeholder={'비밀번호 확인'}
              value={form?.passwordConfirm || ''}
              onChange={(e) => {
                setForm({ ...form, passwordConfirm: e.target.value });
              }}
            />
          </Section>
        </Content.Inner>
      }
    />
  );
};

export default PopupSignUpReader;
