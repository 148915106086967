export const map = {
  modify: (layerName, featureId) => `/layers/${layerName}/${featureId}/geometry`,
};

export const address = '/data/LSCT_LAWDCD.csv';

export const image = {
  upload: '/upload',
  farmmapUpload: (surveyId) => `/survey/${surveyId}/farm-map/image`, // 사업회차 필지 이미지 파일 업로드
};

export const project = {
  roundList: (projectId) => `/project/${projectId}`, // 사업의 회차리스트 조회
  get: `/project`, // 사업 목록 조회
  add: `/project`, // 사업 등록(주소)
  addWithShp: `/project/shp-file`, // 사업등록(shp)
  addShpFile: (projectId) => `/project/${projectId}/farm-map/file`, //shp 파일 저장
  patch: (projectId) => `/project/${projectId}`, // 사업 수정
  getLots: (projectId) => `/project/${projectId}/farm-map`, // 사업장의 필지목록 조회
  getUsers: '/project/member', // 사업 판독자/검수자 조회
  addUsers: '/project/member', // 사업 판독자/검수자 추가
  removeUsers: '/project/member', // 사업 판독자/검수자 삭제
  getShp: (projectId) => `/project/${projectId}/image/shp`, // shp;
};

export const client = {
  get: `/inspector`,
  add: '/inspector',
  detail: (inspectorCompanyId) => `/inspector/${inspectorCompanyId}`,
  edit: (inspectorCompanyId) => `/inspector/${inspectorCompanyId}`,
  crops: (inspectorCompanyId) => `/inspector/${inspectorCompanyId}/crop`,
};

export const member = {
  projects: (memberId) => `/member/${memberId}/project`,
  changePassword: (memberId) => `/member/${memberId}/password`,
  duplicate: '/member/email-exists',
  addUser: '/member',
  get: '/member',
  modifyUser: (memberId) => `/member/${memberId}`,
  deleteUser: '/member',
};

export const farmMap = {
  map: `/frammap/data`,
};

export const survey = {
  deleteDuplicateImage: (surveyId) => `/survey/${surveyId}/duplicated-image`, // 회차별 중복 이미지 선택 등록(선택 외 나머지 삭제)
  getSurveyData: (surveyFarmMapId) => `/survey/farm-map/${surveyFarmMapId}`, //회차별 필지 판독 정보 조회
  updateRemain: (surveyFarmMapId) => `/survey/farm-map/${surveyFarmMapId}/remain-rate`, //회차별 필지 판독 잔여량 수정
  addFillRate: (surveyFarmMapId) => `/survey/farm-map/${surveyFarmMapId}/fill-rate`, // 회차별 필지 판독 점유율 저장
  inspect: (surveyFarmMapId) => `/survey/farm-map/${surveyFarmMapId}/inspect`, // 회차별 필지 검수 완료
  addFillRateMulti: `/survey/farm-map/fill-rate`, // 회차별 필지 판독 점유율 일괄 저장
  updateSingleSurveyStatus: (surveyId, surveyFarmMapId) => `/survey/${surveyId}/${surveyFarmMapId}`, // 회차별 필지 조회 (단일)
  getLots: (surveyId) => `/survey/${surveyId}`, // 회차별 필지 조회 - 목록(paging)
  updateSurveyStatus: (surveyId) => `/survey/${surveyId}`, //관리자 - 사업회차 상태 변경
  deleteCrops: `/survey/crop`, // 관리자 - 사업회차 작물코드 삭제
  addCrops: (surveyId) => `/survey/${surveyId}/crop`, // 관리자 - 사업회차 작물코드 추가
  getCrops: (surveyId) => `/survey/${surveyId}/crop`, // 관리자 - 사업회차 작물코드 조회
  updateCrops: `/survey/crop/display-name`, // 관리자 - 사업회차 작물코드 명칭 수정
  updateCropLevel: `/survey/crop/level`, // 관리자 - 사업회차 작물코드 레벨 수정
  getCondition: (surveyId) => `/survey/${surveyId}/condition`, // 관리자 - 사업회차 필지 추가조건 조회
  addCondition: (surveyId) => `/survey/${surveyId}/condition`, // 관리자 - 사업회차 필지 추가조건 추가
  removeCondition: (surveyId) => `/survey/${surveyId}/condition`, // 관리자 - 사업회차 필지 추가조건 삭제
  getMap: (surveyId) => `/survey/${surveyId}/map`, // 지도
  duplicatedImage: (surveyId) => `/survey/${surveyId}/duplicated-image`, // 중복 이미지 조회
  uploadImageList: (surveyId) => `/survey/${surveyId}/image`,
  getChart: (surveyId) => `/survey/${surveyId}/chart`, // 차트
};
