import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import IcoPrev from 'assets/images/ico-page-prev.svg';

export const PaginationSt = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `,
  ArrowBtn: styled.button`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .ico {
      width: 4.41px;
      height: 7.31px;
      background: url(${IcoPrev}) no-repeat center;
    }
    &.next {
      .ico {
        transform: rotate(180deg);
      }
    }
  `,
  NumGroup: styled.ul`
    /* width: 148px; */
    display: flex;
    justify-content: center;
    gap: 15px;
    li {
      height: 20px;
      line-height: 20px;
      font-weight: 700;
      font-size: 14px;
      color: #767676;
      cursor: pointer;
      &.active {
        color: #00b074;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 2px;
          background-color: #00b074;
        }
      }
    }
  `,
};

function Pagination({ currentPage, setCurrentPage, totalPages }) {
  const groupSize = 5; // 한번에 보여지는 페이저 넘버 개수(최대)
  //   const totalPages = 102;
  //   const [currentPage, setCurrentPage] = useState(1);

  const [startRow, setStartRow] = useState(1); // 그룹 시작 페이지 넘버
  const [groupNo, setGroupNo] = useState(1); // 몇번째 그룹인지
  const [pageList, setPageList] = useState<number[]>([]); // 현재 보여지는 페이지 넘버 목록

  // 페이지가 바뀌면 데이터 재요청 등
  useEffect(() => {
    //  console.log('cur', currentPage);
  }, [currentPage]);

  // 1) 현재 페이지, 총 페이지수, 한번에 보여지는 컨텐츠 수(pageSize)가 바뀔 때 페이지그룹 재계산
  useEffect(() => {
    if (currentPage) {
      setGroupNo(Math.ceil(currentPage / groupSize));
    }
  }, [currentPage]);

  // 2) 페이지그룹이 바뀔때 그룹 시작 페이지넘버 갱신
  useEffect(() => {
    setStartRow((groupNo - 1) * groupSize + 1); // 1, 6,
  }, [groupNo, groupSize]);

  // 3) 페이지그룹의 시작 페이지넘버, 총 페이지수, 페이지넘버가 바뀔 때 페이지그룹의 마지막 페이지넘버를 확인하여 잔여 컨텐츠를 집어넣음
  useEffect(() => {
    let endRow = groupNo * groupSize > totalPages ? totalPages : groupNo * groupSize;
    let newPageList: any[] = [];
    for (let i = startRow; i < endRow + 1; i++) {
      newPageList.push(i);
    }
    setPageList(newPageList);
  }, [startRow, totalPages, groupNo, groupSize]);

  // 숫자 클릭
  const onClickNum = (num) => {
    setCurrentPage(num);
  };

  // Prev 이전 버튼
  const onClickPrev = () => {
    //  setCurrentPage(currentPage - 1);
    const num = startRow - groupSize; // 1,6,11

    if (num < 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(num);
    }
  };

  // Next 다음 버튼
  const onClickNext = () => {
    //  setCurrentPage(currentPage + 1);
    const num = startRow + groupSize; // 1,6,11

    if (num > totalPages) {
      setCurrentPage(startRow); // 현재 그룹 첫번째 항목으로 고정
    } else {
      setCurrentPage(num);
    }

    // 전체 페이지 수가 5보다 적을때
    if (pageList?.length < groupSize) {
      setCurrentPage(currentPage + 1);
    }

    if (currentPage === totalPages) {
      setCurrentPage(totalPages);
    }
  };

  return (
    <PaginationSt.Wrap>
      <PaginationSt.ArrowBtn className="prev" onClick={onClickPrev}>
        <span className="ico"></span>
        <span style={{ display: 'none' }}>{'<'}</span>
      </PaginationSt.ArrowBtn>

      <PaginationSt.NumGroup>
        {pageList.map((ele) => (
          <li key={`number:${ele}`} className={`${currentPage === ele && 'active'}`} onClick={() => onClickNum(ele)}>
            {ele}
          </li>
        ))}
      </PaginationSt.NumGroup>

      <PaginationSt.ArrowBtn className="next" onClick={onClickNext}>
        <span className="ico"></span>
        <span style={{ display: 'none' }}>{'>'}</span>
      </PaginationSt.ArrowBtn>
    </PaginationSt.Wrap>
  );
}

export default Pagination;
