import { useQuery } from '@tanstack/react-query';
import queryKeys from '../keys/queryKeys';
import api from '../service';
import useMutateHook from 'hooks/useMutateHook';
import { useQueryState } from 'hooks/useQueryState';

// 관리자 - 발주처 목록 조회
export function useGetClients({ companyId, pageNum, size }) {
  return useQueryState({
    queryKey: [...queryKeys.clientKeys.list({ companyId, pageNum, size })],
    queryFn: () => api.client.getClients({ companyId, pageNum, size }),
    enabled: !!companyId,
  });
}

//관리자 - 발주처 추가
export function useAddClient({ companyId, name, role, addr, zipcode, stdg, tel, email, deptName }) {
  return useMutateHook({
    fetchApi: () => api.client.addClient({ companyId, name, role, addr, zipcode, stdg, tel, email, deptName }),
  });
}

// 관리자 - 발주처 상세정보
export function useGetClientDetail({ inspectorCompanyId }) {
  return useQueryState({
    queryKey: [queryKeys.clientKeys.detail({ inspectorCompanyId })],
    queryFn: () => api.client.getClientDetail({ inspectorCompanyId }),
  });
}

//관리자 - 발주처 수정
export function useEditClient({ inspectorCompanyId, name, role, addr, zipcode, stdg, tel, email, deptName }) {
  return useMutateHook({
    fetchApi: () => api.client.editClient({ inspectorCompanyId, name, role, addr, zipcode, stdg, tel, email, deptName }),
  });
}
// 관리자 - 발주처 작물코드 조회
export function useGetCrops({ inspectorCompanyId }) {
  return useQueryState({
    queryKey: [queryKeys.clientKeys.crops(inspectorCompanyId)],
    queryFn: () => api.client.getCrops({ inspectorCompanyId }),
  });
}

// 관리자 - 발주처 작물코드 추가
export function useAddCrops({ inspectorCompanyId, inspectorCrops }) {
  return useMutateHook({
    fetchApi: () => api.client.addCrops({ inspectorCompanyId, inspectorCrops }),
    invalidQuery: [[queryKeys.clientKeys.crops(inspectorCompanyId)]],
  });
}

//관리자 - 발주처 작물코드 삭제
export function useRemoveCrops({ inspectorCompanyId, inspectorCrops }) {
  return useMutateHook({
    fetchApi: () => api.client.removeCrops({ inspectorCompanyId, inspectorCrops }),
    invalidQuery: [[queryKeys.clientKeys.crops(inspectorCompanyId)]],
  });
}

//관리자 - 발주처 작물코드 삭제
export function useEditCrops({ inspectorCompanyId, inspectorCrops }) {
  return useMutateHook({
    fetchApi: () => api.client.editCrops({ inspectorCompanyId, inspectorCrops }),
    invalidQuery: [[queryKeys.clientKeys.crops(inspectorCompanyId)]],
  });
}
