import useMutateHook from '../../useMutateHook';
import api from '../service';
import queryKeys from '../keys/queryKeys';

export function useModifyLayer({ layerName, featureId, type, coordinates, srid }) {
  return useMutateHook({
    fetchApi: () => api.map.modifyLayer({ layerName, featureId, type, coordinates, srid }),
    invalidQuery: [queryKeys.mapKeys.all],
  });
}
