import React from 'react';
import styled from 'styled-components';
import BgNotice from '../../assets/images/notice/bg-notice.png';
import IcoNotice from '../../assets/images/notice/ico-notice.svg';

const NoticeStyle = {
  Dimmed: styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
  `,
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 496px;
    border-radius: 12px;
    background: url(${BgNotice}) no-repeat center bottom;
  `,
  Close: styled.button`
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;
    top: 20px;
    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
      background: #fff;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:before {
      width: 3px;
      height: 18px;
    }
    &:after {
      width: 18px;
      height: 3px;
    }
  `,
  Title: styled.strong`
    margin: 50px 0 35px;
    color: #fff;
    font-size: 44px;
    font-weight: 700;
  `,
  Desc: styled.p`
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin: 17px 0 19px;
    text-align: center;
  `,
  ListBox: styled.ul`
    margin-bottom: 16px;
    background: #fff;
    padding: 15px 20px;
    border-radius: 8px;
  `,
  Item: styled.li`
    display: flex;
    align-items: center;
    strong {
      margin-right: 16px;
      text-align: center;
      line-height: 30px;
      min-width: 114px;
      height: 30px;
      border: 1px solid #00b074;
      border-radius: 50px;
      color: #00b074;
      font-size: 14px;
      font-weight: 600;
      box-sizing: border-box;
    }
    p {
      color: #3f3f3f;
      font-size: 16px;
      font-weight: 500;
    }
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  `,
  Support: styled.p`
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    span {
      margin-left: 8px;
      color: #ffe83d;
    }
    margin-bottom: 32px;
  `,
};

const Notice = ({ $hasClose }) => {
  return (
    <>
      <NoticeStyle.Dimmed></NoticeStyle.Dimmed>
      <NoticeStyle.Wrap>
        {$hasClose ? (
          <NoticeStyle.Close type={'button'}>
            <span className={'hidden'}>닫기</span>
          </NoticeStyle.Close>
        ) : null}
        <NoticeStyle.Title>공지사항</NoticeStyle.Title>
        <img src={IcoNotice} alt={''} />
        <NoticeStyle.Desc>
          내용
          <br />
          내용
          <br />
          sss
        </NoticeStyle.Desc>
        <NoticeStyle.ListBox>
          <NoticeStyle.Item>
            <strong>점검시간</strong>
            <p>2024.10.10(월) 13:00 ~ 15:00</p>
          </NoticeStyle.Item>
          <NoticeStyle.Item>
            <strong>작업내용</strong>
            <p>홈페이지 서버 교체 작업</p>
          </NoticeStyle.Item>
          <NoticeStyle.Item>
            <strong>예상 소요 시간</strong>
            <p>1시간</p>
          </NoticeStyle.Item>
        </NoticeStyle.ListBox>
        <NoticeStyle.Support>
          문의하기
          <span>support@movements.kr</span>
        </NoticeStyle.Support>
      </NoticeStyle.Wrap>
    </>
  );
};

export default Notice;
