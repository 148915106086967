import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Point } from 'ol/geom';
import { Collection, Feature } from 'ol';
import Style from 'ol/style/Style';
import LayerGroup from 'ol/layer/Group';
import { FitOptions } from 'ol/View';
import OlCore from '../OlCore2';
import { baseProjection, targetProjection } from '../projection';

//VectorLayer에 관련 모듈
class VectorModule {
  private layerGroup = new LayerGroup();
  public core: OlCore;

  //관리용 Layers, Features
  private layers: VectorLayer<VectorSource>[] = [];
  private features: Feature[] = [];

  //초기 Layers 셋팅용 Collection;
  private layerCollection: Collection<VectorLayer<VectorSource>>;

  public selectedId?: string = '';
  public selectedFeature?: Feature;

  //Module 생성과 동시에 LayerGroup 및 LayerCollection 초기화
  constructor(core: OlCore) {
    this.core = core;

    this.layerCollection = new Collection<VectorLayer<VectorSource>>();
    this.layerGroup.setLayers(this.layerCollection);

    //LayerGroup을 MapInstance에 등록
    this.core.getMapInstance().addLayer(this.layerGroup);
  }

  //초기 환경 셋팅용 레이어
  //layer를 MapInstance에 바로 올라간다.
  public setLayers(layers: VectorLayer<VectorSource>[]) {
    layers.forEach((layer) => {
      this.layerCollection.push(layer);
      this.layers.push(layer);
    });
  }

  //layer의 properties에 저장된 layerId로 레이어 검색
  public getLayerById(layerId: string) {
    const layers = this.layers.filter((layer) => layer.get('id') === layerId);
    if (layers.length) {
      return layers[0];
    }

    console.error('해당 아이디를 가진 레이어가 존재하지 않습니다.');
  }

  //레이어 Visible Toggle
  public onToggleLayer(layerId: string) {
    const layers = this.layers.filter((layer) => layer.get('id') === layerId);

    layers.forEach((layer) => {
      layer.setVisible(!layer.getVisible());
    });
  }

  //해당 Id의 레이어로 화면 이동
  public moveLayer(layerId: string, option?: FitOptions) {
    const targetLayer = this.getLayerById(layerId);
    this.core.moveToLayer(targetLayer, option);
  }

  //벡터 소스 생성
  public createSource(features: Feature[]) {
    const source = new VectorSource({ features });
    return source;
  }

  //피쳐 배열 생성
  public createFeatures(datas: any[], layerId: string) {
    const features = datas?.map((i) => this.createFeature(i, layerId));
    return features;
  }

  //단일 피쳐 생성
  public createFeature(data: any, layerId: string) {
    const feature = new Feature({
      geometry: new Point(data.geometry.coordinates),
      properties: { ...data.properties, ufid: data.id, layerId: layerId },
    });
    feature.getGeometry()?.transform(targetProjection, baseProjection);
    this.features.push(feature);
    return feature;
  }
}

export default VectorModule;
