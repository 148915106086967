import React, { useState } from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import Input from '../components/atoms/Input';
import query from 'hooks/RQuery/utils';
import { useRecoilState } from 'recoil';
import { confirmState } from '../store/Atoms';

const Content = {
  Inner: styled.div`
    padding: 32px 0;
  `,
  Tip: styled.p`
    color: #00b074;
    font-size: 14px;
    font-weight: 400;
  `,
  ErrMsg: styled.p`
    color: #f4323d;
  `,
};

const PopupPasswordChange = ({ onClose }) => {
  const memberId = sessionStorage.getItem('memberId');
  const [errMsg, setErrMsg] = useState('');
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [form, setForm] = useState({ newPassword: '', passwordCheck: '' });
  const { mutate } = query.member.useMutateChangePassword({ memberId, newPassword: form?.newPassword });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(name, value);
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onClickConfirm = async () => {
    console.log(form?.newPassword);
    // 두개 비교
    if (form?.newPassword !== form?.passwordCheck) {
      setErrMsg('* 비밀번호가 일치하지 않습니다');
      return;
    } else {
      setErrMsg('');
    }

    setConfirm({
      ...confirm,
      show: true,
      message: '비밀번호를 변경하시겠습니까?',
      leftText: '취소',
      rightText: '확인',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: () => {
        mutate(
          { memberId, newPassword: form?.newPassword },
          {
            onSuccess(data: any, variables, context) {
              console.log(data);
              setConfirm({
                ...confirm,
                show: true,
                message: data?.message,
                leftText: undefined,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                  onClose(); // 팝업창 종료
                },
              });
            },
            onError(error: any, variables, context) {
              const message = error?.response?.data?.message;
              setConfirm({
                ...confirm,
                show: true,
                message: message,
                leftText: undefined,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                  onClose(); // 팝업창 종료
                },
              });
              console.log(error);
              //에러 메세지 저장 등
              setErrMsg('*' + message);
            },
          },
        );
        setConfirm({ ...confirm, show: false });
      },
    });
  };

  return (
    <PopupTemplate
      title={'비밀번호 변경'}
      width={352}
      isDimmed={true}
      onClose={onClose}
      footer={<Button width={'full'} text={'비밀번호 변경'} onClick={onClickConfirm} />}
      content={
        <Content.Inner>
          <Input type={'password'} placeholder={'새 비밀번호'} width={'100%'} height={48} style={{ marginBottom: 8 }} name="newPassword" onChange={onChange} />
          <Input type={'password'} placeholder={'새 비밀번호 확인'} width={'100%'} height={48} style={{ marginBottom: 8 }} name="passwordCheck" onChange={onChange} />
          {errMsg ? <Content.ErrMsg>{errMsg}</Content.ErrMsg> : sessionStorage.getItem('auth') === 'ROLE_ADMIN' ? <Content.Tip>* 영문자 및 숫자를 포함하여 8자 이상 입력하세요.</Content.Tip> : null}
        </Content.Inner>
      }
    />
  );
};

export default PopupPasswordChange;
