import React, { useEffect, useState } from 'react';
import AdminSubContainer, { AdminSubContainerStyle } from 'components/templates/Container/AdminSubContainer';
import TabContent, { NextStep, Section } from 'components/atoms/TabContent';
import PopupAddLot from 'popup/PopupAddLot';
import { useRecoilState } from 'recoil';
import { confirmState, formState, loadingState } from 'store/Atoms';
import { useNavigate } from 'react-router-dom';
import Step1 from './AddStep/Step1';
import { DateObject } from 'react-multi-date-picker';
import query from 'hooks/RQuery/utils';
import { useMutation } from '@tanstack/react-query';
import api from 'hooks/RQuery/service';
import { getEmptyKeyArr, getEmptyKeyString } from 'util/global';

const initForm = {
  projectName: '',
  prjId: '',
  inspectorCompanyId: '',
  surveyCount: 5,
  surveyBeginDate: new DateObject().format('YYYY-MM-DD'),
  surveyEndDate: new DateObject().add(1, 'days').format('YYYY-MM-DD'),
  bjdCdList: [],
  submitType: null, // null | 'address' | 'shp'
  address: [],
  shpFile: [],
  date: {
    from: new DateObject(),
    to: new DateObject().add(1, 'days'),
  },
};

// 사업장 추가 화면
const WorkPlaceAdd = () => {
  const navigate = useNavigate();
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [form, setForm] = useRecoilState<any>(formState); // 신규 사업장 정보
  const { date, submitType, address, shpFile, ...restForm } = form; // 제외항목: date 등

  const [tabList, setTabList] = useState([
    { name: '기본 정보', pass: true, active: true },
    { name: '회차별 정보', pass: false, active: false, disabled: true },
  ]);
  //팝업 on/off 관리
  const [popupState, setPopupState] = useState({
    lotAdd: false,
  });

  // 사업장 추가 mutate
  const { mutate: postProject } = query.project.usePostProject(restForm);
  //
  const { mutate: postProjectWithShp } = query.project.usePostProjectWithShp();

  //shp 저장
  const { mutateAsync: postShpFileAsync } = useMutation({
    mutationFn: api.project.addShpFile,
  });

  //form 객체 세팅(recoil 저장)
  useEffect(() => {
    setForm({ ...initForm });
  }, []);

  //팝업 토글 (key: 팝업종류)
  const onTogglePopup = (key) => {
    setPopupState({ ...popupState, [key]: !popupState[key] });
  };

  console.log(form);

  // 신규 사업장 저장하기 - 서버
  const onClickSave = () => {
    setConfirm({
      ...confirm,
      show: true,
      message: '사업장을 추가하시겠습니까?',
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: async () => {
        setConfirm({ ...confirm, show: false });
        console.log(restForm);
        const emptyKeys = getEmptyKeyString(restForm);
        //   const emptyArrKey = getEmptyKeyArr(restForm);

        // 기본정보 유무 체크:  projectName inspectorCompanyId prjId 등
        if (emptyKeys?.length > 0) {
          setConfirm({
            ...confirm,
            show: true,
            leftText: undefined,
            rightText: '확인',
            rightFunc: () => setConfirm({ ...confirm, show: false }),
            message: '빈 항목을 확인해주세요.',
            isError: true,
          });
          return;
        }

        // 필지 정보 유무 체크
        if (restForm?.bjdCdList?.length === 0 && shpFile.filter(({ isValid }) => isValid)?.length === 0) {
          setConfirm({
            ...confirm,
            show: true,
            leftText: undefined,
            rightText: '확인',
            rightFunc: () => setConfirm({ ...confirm, show: false }),
            message: '필지 정보를 확인해주세요.',
            isError: true,
          });
          return;
        }

        setLoading({ show: true, text: '정보 양이 많을 경우 지연될수 있습니다.' }); // 로더 시작

        // 주소로 필지 등록시
        if (form?.submitType === 'address') {
          await postProject(restForm, {
            async onSuccess(data: any, variables, context) {
              setLoading({ show: false });

              setConfirm({
                ...confirm,
                show: true,
                leftText: undefined,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                  setForm({});
                  navigate('/workPlace');
                },
                message: data?.message || '사업장이 추가되었습니다.',
              });
            },
            onError(error, variables, context) {
              setLoading({ show: false });

              if (error.status === 504) {
                setConfirm({
                  ...confirm,
                  show: true,
                  leftText: undefined,
                  rightText: '확인',
                  rightFunc: () => setConfirm({ ...confirm, show: false }),
                  message: '관리자에게 문의하세요',
                  isError: true,
                });
              } else {
                setConfirm({
                  ...confirm,
                  show: true,
                  leftText: undefined,
                  rightText: '확인',
                  rightFunc: () => setConfirm({ ...confirm, show: false }),
                  message: (error as any)?.response?.data?.message,
                  isError: true,
                });
              }
            },
          });
        }

        // shp로 필지 등록시
        if (form?.submitType === 'shp') {
          // shp 파일 객체 만들기
          const validArr = shpFile.filter(({ isValid }) => isValid); // 필수파일 체크
          const files: File[] = [];
          validArr?.map((ele) => {
            ele?.files.map((file) => files.push(file));
          });

          await postProjectWithShp(
            { projectSaveRequest: restForm, files },
            {
              onSuccess(data: any, variables, context) {
                console.log(data);
                setLoading({ show: false });
                setConfirm({
                  ...confirm,
                  show: true,
                  leftText: undefined,
                  rightText: '확인',
                  rightFunc: () => {
                    setConfirm({ ...confirm, show: false });
                    setForm({});
                    navigate('/workPlace');
                  },
                  message: data?.message || '사업장이 추가되었습니다.',
                });
              },
              onError(error: any, variables, context) {
                setLoading({ show: false });
                console.error('shp파일 업로드 실패:', error);
                if (error.status === 504 || error.status === 413) {
                  setConfirm({
                    ...confirm,
                    show: true,
                    leftText: undefined,
                    rightText: '확인',
                    rightFunc: () => setConfirm({ ...confirm, show: false }),
                    message: `관리자에게 문의하세요`,
                    isError: true,
                  });
                } else {
                  setConfirm({
                    ...confirm,
                    show: true,
                    leftText: undefined,
                    rightText: '확인',
                    rightFunc: () => setConfirm({ ...confirm, show: false }),
                    message: (error as any)?.response?.data?.message,
                    isError: true,
                  });
                }
              },
            },
          );
        }
      },
    });
  };

  return (
    <AdminSubContainer
      title={'사업장 추가'}
      onNavigate={() => navigate('/workPlace')}
      content={
        <>
          <AdminSubContainerStyle.Content>
            <TabContent
              type={2}
              tabList={tabList}
              setTabList={setTabList}
              content={
                <>
                  {/* 탭별 on/off*/}
                  {tabList[0].active ? (
                    <>
                      {/* 기본정보 추가화면 */}
                      <Step1 onTogglePopup={() => onTogglePopup('lotAdd')} onClickSave={onClickSave} />
                      {/* 필지 업로드 팝업 */}
                      {popupState.lotAdd ? <PopupAddLot onClose={() => onTogglePopup('lotAdd')} /> : null}
                    </>
                  ) : null}
                  {/* {tabList[1].active ? <Step3 onNextStep={onNextStep} /> : null}
                {tabList[2].active ? <Step3 onNextStep={onNextStep} /> : null}
                {tabList[3].active ? <Step4 /> : null} */}
                </>
              }
            />
          </AdminSubContainerStyle.Content>
        </>
      }
    />
  );
};

export default WorkPlaceAdd;
