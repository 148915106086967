import React, { useEffect, useState } from 'react';
import { NextStep, Section } from '../../../../components/atoms/TabContent';
import HelpMessage from '../../../../components/atoms/HelpMessage';
import IcoHelp from '../../../../assets/images/etc/ico-help-message.svg';
import FileButton from '../../../../components/atoms/FileButton';
import Checkbox from '../../../../components/atoms/Checkbox';
import ScrollTable from '../../../../components/atoms/ScrollTable';
import { useRecoilState } from 'recoil';
import { confirmState } from '../../../../store/Atoms';
import Button from '../../../../components/atoms/Button';
import IcoStepNext from '../../../../assets/images/button/ico-step-next.svg';
import IcoStepDone from '../../../../assets/images/button/ico-step-done.svg';
import styled from 'styled-components';
import query from '../../../../hooks/RQuery/utils';
import { useLocation } from 'react-router-dom';
import CsvTemplate from '../../../../assets/작물코드_csv_템플릿.csv';
import IcoStepDoneDisabled from '../../../../assets/images/button/ico-step-done-disabled.svg';

const TableSt = {
  Input: styled.input`
    border: 1px solid transparent;
    &:disabled {
      background-color: #f1f1f5;
    }
    &.shadow {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0.5%, #f1f1f5 5.5%, #f1f1f5);
    }
  `,
};
const Step2 = ({ isEditMode, setIsEditMode, onToggleEditMode }) => {
  const location = useLocation();
  const inspectorCompanyId = location.pathname.split('/')[3];
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [colList, setColList] = useState<any>(['code1', 'code2', 'code3', 'name1', 'name2', 'name3']);
  const [isAllCheck, setIsAllCheck] = useState(false);

  //원본
  const [originCodeData, setOriginCodeData] = useState<any>([]);

  //수정
  const [codeData, setCodeData] = useState<any>([]);

  //추가된 리스트
  const [addCodeData, setAddCodeData] = useState<any>([]);

  //삭제된 리스트
  const [removeCodeData, setRemoveCodeData] = useState<any>([]);

  //수정된 리스트
  const [editCodeData, setEditCodeData] = useState<any>([]);

  const getCrops = query.client.useGetCrops({ inspectorCompanyId });
  const addCrops = query.client.useAddCrops({ inspectorCompanyId, inspectorCrops: addCodeData });
  const removeCrops = query.client.useRemoveCrops({ inspectorCompanyId, inspectorCrops: removeCodeData });
  const editCrops = query.client.useEditCrops({ inspectorCompanyId, inspectorCrops: editCodeData });

  //초기 세팅
  useEffect(() => {
    if (getCrops.data) {
      const newArr: any = [];
      getCrops.data.map((i) => {
        newArr.push({ ...i, checked: false, isDuplicated: false });
      });
      setCodeData(newArr);
      setOriginCodeData(getCrops.data);
    }
  }, [getCrops.data]);
  //전체 선택/해제
  const onCheckAll = () => {
    const newArr = [...codeData];
    if (newArr.filter(({ code3 }) => code3?.substring(0, 4) !== '0000').length === newArr.filter(({ checked, code3 }) => checked && code3?.substring(0, 4) !== '0000').length) {
      newArr
        .filter(({ code3 }) => code3?.substring(0, 4) !== '0000')
        .map((i) => {
          i.checked = false;
        });
    } else {
      newArr
        .filter(({ code3 }) => code3?.substring(0, 4) !== '0000')
        .map((i) => {
          i.checked = true;
        });
    }
    setCodeData(newArr);
  };

  //체크박스 클릭
  const onCheck = (id) => {
    const newArr = [...codeData];
    newArr.find((i, index) => index === id)!.checked = !newArr.find((i, index) => index === id)?.checked;
    setCodeData(newArr);
  };

  //체크된수 === 전체 수 같은지 확인 후 전체체크박스 체크선택/해제
  useEffect(() => {
    if (codeData.length > 0 && codeData.filter(({ checked }) => checked).length === codeData.length) {
      setIsAllCheck(true);
    } else {
      setIsAllCheck(false);
    }
  }, [codeData.filter(({ checked }) => checked).length]);

  //csv 읽어오기
  const onOpenCsv = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const arr = (e.target as any).result.replaceAll('\r', '').split('\n');
      const step2Arr: any = [];
      const step3Arr: any = [];

      arr.map((str) => {
        step2Arr.push(str.split(','));
      });
      let obj = {};

      step2Arr.map((i, index) => {
        if (index !== 0) {
          colList.map((th, idx) => {
            obj = { ...obj, [th]: i[idx], checked: false, isDuplicated: false, inspectorCropId: null };
          });
          step3Arr.push(obj);
        }
      });
      colList.map((th, idx) => {
        obj = { ...obj, [th]: null, checked: false, isDuplicated: false, inspectorCropId: null };
      });
      step3Arr.push(obj);
      console.log(step3Arr);
      setCodeData([...codeData, ...step3Arr]);
    };
    reader.readAsText(file);
  };

  //행 추가
  const onRowAdd = () => {
    const newArr = [...codeData];
    let obj = {};
    colList.map((i) => {
      obj = { ...obj, [i]: null, checked: false, isDuplicated: false, inspectorCropId: null };
    });
    newArr.push(obj);
    setCodeData(newArr);
  };
  //테이블 내 데이터 변경값 state 변경
  const onChangeValue = (e, key, num) => {
    const val = e.target.value ? e.target.value : null;
    const newArr = [...codeData];
    newArr.filter((i, index) => index === num)[0][key] = val;
    setCodeData(newArr);
  };

  //선택 행 삭제
  const onRemoveCheck = () => {
    setConfirm({
      ...confirm,
      show: true,
      leftText: '취소',
      rightText: '확인',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: () => {
        const newArr = [...codeData];
        setCodeData(newArr.filter(({ checked }) => !checked));
        setConfirm({ ...confirm, show: false });
      },
      message: '선택한 항목을 삭제하시겠습니까?',
    });
  };
  //저장버튼
  const onSave = () => {
    const newArr = [...codeData];
    newArr.map((i) => {
      i.isDuplicated = codeData.filter(({ code3 }) => i.code3 === code3).length > 1;
    });
    setCodeData(newArr);
    if (newArr.filter(({ isDuplicated }) => isDuplicated).length) {
      //중복된 행이 있을 경우 저장 불가
      setConfirm({ ...confirm, show: true, message: '중복된 작물코드를 확인해주세요.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
    } else {
      setConfirm({
        ...confirm,
        show: true,
        message: '변경된 사항을 저장하시겠습니까?',
        leftText: '취소',
        leftFunc: () => setConfirm({ ...confirm, show: false }),
        rightText: '확인',
        rightFunc: () => {
          const newAddArr: any = [];
          const newRemoveArr: any = [];
          const newEditArr: any = [];
          codeData.map((i) => {
            if (!originCodeData.some(({ code3, name1, name2, name3 }) => code3 === i.code3 && name1 === i.name1 && name2 === i.name2 && name3 === i.name3)) {
              if (!!i.code3) {
                if (!!i.inspectorCropId) {
                  newEditArr.push(i);
                } else {
                  newAddArr.push(i);
                }
              }
            }
          });
          setAddCodeData(newAddArr);
          setEditCodeData(newEditArr);

          originCodeData.map((i) => {
            if (!codeData.some(({ code3, inspectorCropId }) => code3 === i.code3)) {
              newRemoveArr.push(i);
            }
          });
          setRemoveCodeData(newRemoveArr);

          setConfirm({ ...confirm, show: false });
          const promises: any = [];
          if (!!newAddArr.length) {
            promises.push(
              new Promise((resolve, reject) => {
                addCrops.mutate(
                  { inspectorCompanyId, inspectorCrops: addCodeData },
                  {
                    onSuccess: (res) => {
                      resolve(res);
                    },
                    onError: (error) => {
                      reject(error);
                    },
                  },
                );
              }),
            );
          }
          if (!!newRemoveArr.length) {
            promises.push(
              new Promise((resolve, reject) => {
                removeCrops.mutate(
                  { inspectorCompanyId, inspectorCrops: removeCodeData },
                  {
                    onSuccess: (res) => {
                      resolve(res);
                    },
                    onError: (error) => {
                      reject(error);
                    },
                  },
                );
              }),
            );
          }
          if (!!newEditArr.length) {
            promises.push(
              new Promise((resolve, reject) => {
                editCrops.mutate(
                  { inspectorCompanyId, inspectorCrops: editCodeData },
                  {
                    onSuccess: (res) => {
                      resolve(res);
                    },
                    onError: (error) => {
                      reject(error);
                    },
                  },
                );
              }),
            );
          }
          Promise.all(promises)
            .then((res) => {
              setConfirm({
                ...confirm,
                show: true,
                message: res[0]?.message || '변경사항이 저장되었습니다.',
                leftText: undefined,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                  setIsEditMode(false);
                },
              });
            })
            .catch((error) => {
              // 에러 처리
              setConfirm({ ...confirm, show: true, message: error?.response.data?.message || '변경 실패. 관리자에게 문의하세요.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
            })
            .finally(() => {
              setAddCodeData([]);
              setRemoveCodeData([]);
              setEditCodeData([]);
            });
        },
      });
    }
  };

  return (
    <>
      <Section style={{ width: 1272, height: '100%' }}>
        {isEditMode ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 16 }}>
              <HelpMessage message={'작물 분류를 정해진 양식의 CSV로 작성해 일괄 추가할 수 있습니다  &nbsp;&nbsp;&nbsp;'} icon={IcoHelp} />
              <a
                href={CsvTemplate}
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: '#00b074',
                  textDecoration: 'underline',
                  marginRight: 'auto',
                }}
              >
                작물 분류 기준 csv 양식 다운로드
              </a>
              <FileButton id={'csvOpen'} text={'CSV 불러오기'} onChange={onOpenCsv} $isShowFileName={true} />
            </div>
            <ScrollTable
              $hasBorder={true}
              $minHeight={'calc(100% - 194px)'}
              $maxHeight={'calc(100% - 194px)'}
              caption={'필지 정보'}
              colgroup={['auto', 235, 160, 235, 160, 235, 160]}
              thead={
                <tr>
                  <th scope={'col'}>
                    <Checkbox onChange={onCheckAll} id={'checkAll'} checked={isAllCheck} />
                  </th>
                  <th scope={'col'}>대분류</th>
                  <th scope={'col'}>대분류 코드</th>
                  <th scope={'col'} className={'shadow'}>
                    중분류
                  </th>
                  <th scope={'col'}>중분류 코드</th>
                  <th scope={'col'} className={'shadow'}>
                    작물명
                  </th>
                  <th scope={'col'}>작물 코드</th>
                </tr>
              }
              tbody={
                <>
                  {codeData.map((i: any, index: number) => {
                    return (
                      <tr key={index} className={i.checked ? 'checked' : i.code3?.substring(0, 4) === '0000' ? 'noHover' : undefined}>
                        <td>{i.code3?.substring(0, 4) === '0000' ? null : <Checkbox id={'code_' + index} checked={i.checked} onChange={() => onCheck(index)} />}</td>
                        <td>
                          <TableSt.Input value={i.name1} onChange={(e) => onChangeValue(e, 'name1', index)} placeholder={'입력'} disabled={i.code3?.substring(0, 4) === '0000'} />
                        </td>
                        <td>
                          <TableSt.Input type={'text'} value={i.code3?.substring(0, 2)} disabled={true} />
                        </td>
                        <td className={'shadow'}>
                          <TableSt.Input value={i.name2} onChange={(e) => onChangeValue(e, 'name2', index)} placeholder={'입력'} disabled={i.code3?.substring(0, 4) === '0000'} />
                        </td>
                        <td>
                          <TableSt.Input type={'text'} value={i.code3?.substring(0, 4)} disabled={true} />
                        </td>
                        <td className={'shadow'}>
                          <TableSt.Input value={i.name3} onChange={(e) => onChangeValue(e, 'name3', index)} placeholder={'입력'} disabled={i.code3?.substring(0, 4) === '0000'} />
                        </td>
                        <td>
                          <TableSt.Input value={i.code3} style={{ border: i.isDuplicated ? '2px solid #DC1600' : undefined }} onChange={(e) => onChangeValue(e, 'code3', index)} placeholder={'입력'} disabled={i.code3?.substring(0, 4) === '0000'} />
                        </td>
                      </tr>
                    );
                  })}
                  <tr className={'noHover'}>
                    <td colSpan={7}>
                      <button type={'button'} className={'rowAdd'} onClick={onRowAdd}>
                        + 추가하기
                      </button>
                    </td>
                  </tr>
                </>
              }
              $hasTotal={true}
              $totalBtnName={'삭제'}
              checkLength={codeData.filter(({ checked }) => checked).length}
              onRemove={onRemoveCheck}
            />

            <NextStep>
              <Button $buttonType={'large'} text={'저장하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={onSave} $iconDisabled={IcoStepDoneDisabled} />
            </NextStep>
          </>
        ) : (
          <>
            <ScrollTable
              $hasBorder={true}
              $minHeight={'calc(100% - 194px)'}
              $maxHeight={'calc(100% - 194px)'}
              caption={'필지 정보'}
              colgroup={['auto', 160, 'auto', 160, 'auto', 160]}
              thead={
                <tr>
                  <th scope={'col'}>대분류</th>
                  <th scope={'col'}>대분류 코드</th>
                  <th scope={'col'} className={'shadow'}>
                    중분류
                  </th>
                  <th scope={'col'}>중분류 코드</th>
                  <th scope={'col'} className={'shadow'}>
                    작물명
                  </th>
                  <th scope={'col'}>작물 코드</th>
                </tr>
              }
              tbody={
                <>
                  {codeData.map((i: any, index: number) => {
                    return (
                      <tr key={index} className={'noHover'}>
                        <td style={{ borderBottom: codeData.length - 1 === index ? '1px solid #e7eaee' : 'inherit' }}>
                          <input type={'text'} value={i.name1} readOnly />
                        </td>
                        <td style={{ borderBottom: codeData.length - 1 === index ? '1px solid #e7eaee' : 'inherit' }}>
                          <input type={'text'} value={i.code3?.substring(0, 2)} readOnly />
                        </td>
                        <td className={'shadow'} style={{ borderBottom: codeData.length - 1 === index ? '1px solid #e7eaee' : 'inherit' }}>
                          <input type={'text'} value={i.name2} readOnly />
                        </td>
                        <td style={{ borderBottom: codeData.length - 1 === index ? '1px solid #e7eaee' : 'inherit' }}>
                          <input type={'text'} value={i.code3?.substring(0, 2)} readOnly />
                        </td>
                        <td className={'shadow'} style={{ borderBottom: codeData.length - 1 === index ? '1px solid #e7eaee' : 'inherit' }}>
                          <input type={'text'} value={i.name3} readOnly />
                        </td>
                        <td style={{ borderBottom: codeData.length - 1 === index ? '1px solid #e7eaee' : 'inherit' }}>
                          <input type={'text'} value={i.code3} readOnly />
                        </td>
                      </tr>
                    );
                  })}
                </>
              }
              $totalBtnName={'삭제'}
              checkLength={codeData.filter(({ checked }) => checked).length}
              onRemove={onRemoveCheck}
            />
            <Button $buttonType={'large'} text={'수정하기'} style={{ marginTop: 20 }} onClick={onToggleEditMode} />
          </>
        )}
      </Section>
    </>
  );
};

export default Step2;
