import React from 'react';
import styled from 'styled-components';

const RoundBadgeStyle = {
  Wrap: styled.div<{ num: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 24px;
    border-radius: 4px;
    background: ${(p) => {
      switch (p.num) {
        case 1:
          return '#EDF7DF';
        case 2:
          return '#FDF2FA';
        case 3:
          return '#EFF8FF';
        case 4:
          return '#FFF0EA';
        case 5:
          return '#F9F5FF';
      }
    }};
  `,
  Total: styled.span`
    color: #6b8d75;
    font-size: 13px;
    font-weight: 400;
  `,
  Progress: styled.span<{ num: number }>`
    font-size: 13px;
    font-weight: 400;
    color: ${(p) => {
      switch (p.num) {
        case 1:
          return '#047857';
        case 2:
          return '#E946BD';
        case 3:
          return '#3789EE';
        case 4:
          return '#E36328';
        case 5:
          return '#6941C6';
      }
    }};
  `,
};

const RoundBadge = ({ num }: { num: number }) => {
  return (
    <RoundBadgeStyle.Wrap num={num}>
      <RoundBadgeStyle.Progress num={num}>{num}회차</RoundBadgeStyle.Progress>
      {/*<RoundBadgeStyle.Total>/ 5</RoundBadgeStyle.Total>*/}
    </RoundBadgeStyle.Wrap>
  );
};

export default RoundBadge;
