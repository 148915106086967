import { useState, useLayoutEffect, useEffect } from 'react';
//react-query
import { QueryKey, QueryObserverOptions, UseQueryResult, UndefinedInitialDataOptions, useQuery, useQueryClient } from '@tanstack/react-query';

// useQuery - Base
// 쿼리로 받아온 데이터를 바로 사용하지 않고 state 저장후 사용시 깜빡임 개선
export function useQueryState({ queryKey, queryFn, ...options }: QueryObserverOptions) {
  const { data, isLoading, isSuccess, error, refetch, isFetching } = useQuery({ queryKey, queryFn, ...options });
  const [propData, setPropData] = useState<any>(null);
  //   console.log('data', data);
  //   console.log('propData', propData);

  useLayoutEffect(() => {
    if (data) {
      setPropData(data);
    } else {
      setPropData(null);
    }
  }, [data]);

  return { data: propData || null, isLoading, isSuccess, error, refetch, isFetching };
}

// 예시) query-key 이용하여 데이터 가져오기 (정확한 key 입력)
export function useGetQueryData(keys: any[]) {
  const queryClient = useQueryClient();
  return queryClient.getQueryData(keys); // 없으면 undefined
}
