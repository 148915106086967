import React from 'react';
import Input, { InputStyle } from '../atoms/Input';
import Button from '../atoms/Button';
import styled from 'styled-components';
import IcoSearch from '../../assets/images/input/ico-search.svg';

const SearchInputStyle = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    ${InputStyle.Wrap} {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  `,
};

const SearchInput = ({ onChange, onClick, inputWidth, height, placeholder }) => {
  return (
    <SearchInputStyle.Wrap>
      <Input onChange={onChange} height={height} width={inputWidth} placeholder={placeholder} $leftIco={IcoSearch} />
      <Button $buttonType={'large'} text={'검색'} height={height} onClick={onClick} fontSize={14} />
    </SearchInputStyle.Wrap>
  );
};

export default SearchInput;
