import React, { useEffect, useState } from 'react';
import { NextStep, Section } from '../../../../components/atoms/TabContent';
import LabelInput, { LabelInputStyle } from '../../../../components/atoms/LabelInput';
import Button from '../../../../components/atoms/Button';
import IcoStepNext from '../../../../assets/images/button/ico-step-next.svg';
import { useRecoilState } from 'recoil';
import { confirmState, formState } from '../../../../store/Atoms';
import Input from '../../../../components/atoms/Input';
import LabelValue, { LabelValueStyle } from '../../../../components/atoms/LabelValue';
import query from '../../../../hooks/RQuery/utils';
import { useLocation } from 'react-router-dom';
import IcoStepDone from '../../../../assets/images/button/ico-step-done.svg';
import IcoStepDoneDisabled from '../../../../assets/images/button/ico-step-done-disabled.svg';

const Step1 = ({ isEditMode, setIsEditMode, onToggleEditMode, onTogglePopup }) => {
  const location = useLocation();
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [form, setForm] = useRecoilState(formState);
  const clientDetail = query.client.useGetClientDetail({ inspectorCompanyId: location.pathname?.split('/')[3] });
  const clientEdit = query.client.useEditClient({ inspectorCompanyId: location.pathname?.split('/')[3], ...form });

  // 다음 단계 버튼 disabled 값
  const isDisabled = () => {
    return !(form.name && form.role && form.zipcode && form.addr && form.stdg && form.tel && form.email && form.deptName);
  };

  useEffect(() => {
    if (clientDetail.data) {
      setForm(clientDetail.data);
    }
  }, [clientDetail.data]);

  const onSave = () => {
    setConfirm({
      ...confirm,
      show: true,
      message: '변경사항을 저장하시겠습니까?',
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: () => {
        clientEdit.mutate(
          { inspectorCompanyId: location.pathname?.split('/')[3], ...form },
          {
            onSuccess: (res: any) => {
              //   setForm({});
              setConfirm({
                ...confirm,
                show: true,
                message: res?.message,
                leftText: undefined,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                  setIsEditMode(false);
                },
              });
            },
            onError: (err: any) => {
              setConfirm({ ...confirm, show: true, message: err?.response?.data?.message, leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
            },
          },
        );
        setConfirm({ ...confirm, show: false });
      },
    });
  };

  return (
    <div style={{ height: '100%', width: 1130 }}>
      {isEditMode ? (
        <>
          <div className={'cScroll'} style={{ height: 'calc(100% - 102px)' }}>
            <Section>
              <LabelInput
                label={'발주처(기관명)'}
                inputWidth={532}
                inputHeight={48}
                required={true}
                placeholder={'발주처명을 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, name: e.target.value });
                }}
                value={form?.name || ''}
              />
            </Section>
            <Section>
              <LabelInput
                label={'기관 유형'}
                inputWidth={532}
                inputHeight={48}
                required={true}
                placeholder={'기관 유형을 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, role: e.target.value });
                }}
                value={form?.role || ''}
              />
            </Section>
            <Section>
              <LabelInputStyle.Wrap $alignItem={'flex-start'}>
                <LabelInputStyle.Key required={true} $alignItem={'flex-start'}>
                  주소
                </LabelInputStyle.Key>
                <div>
                  <LabelInputStyle.Wrap style={{ marginBottom: 6 }}>
                    <Input readOnly={true} value={form?.zipcode || ''} width={160} height={48} placeholder={'우편번호'} />
                    <Button $buttonType={'small'} text={'주소찾기'} onClick={onTogglePopup} height={48} fontSize={14} padding={20} style={{ marginLeft: 6 }} />
                  </LabelInputStyle.Wrap>
                  <LabelInputStyle.Wrap style={{ marginBottom: 6 }}>
                    <Input readOnly={true} value={form?.addr?.split('&')[0] || ''} width={532} height={48} placeholder={'도로명 또는 지번'} />
                  </LabelInputStyle.Wrap>
                  <LabelInputStyle.Wrap>
                    <Input
                      width={532}
                      height={48}
                      placeholder={'상세 주소'}
                      onChange={(e) => {
                        setForm({ ...form, addr: form.addr?.split('&')[0] + '&' + e.target.value });
                        console.log(form);
                      }}
                      value={form?.addr?.split('&')[1] || ''}
                    />
                  </LabelInputStyle.Wrap>
                </div>
              </LabelInputStyle.Wrap>
            </Section>
            <Section>
              <LabelInput
                label={'행정구역'}
                inputWidth={532}
                inputHeight={48}
                required={true}
                placeholder={'행정구역을 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, stdg: e.target.value });
                }}
                value={form?.stdg || ''}
              />
            </Section>
            <Section>
              <LabelInput
                label={'전화번호'}
                inputWidth={532}
                inputHeight={48}
                required={true}
                placeholder={'전화번호를 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, tel: e.target.value });
                }}
                value={form?.tel || ''}
              />
            </Section>
            <Section>
              <LabelInput
                label={'이메일'}
                inputWidth={532}
                inputHeight={48}
                required={true}
                placeholder={'이메일을 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, email: e.target.value });
                }}
                value={form?.email || ''}
              />
            </Section>
            <Section>
              <LabelInput
                label={'담당부서'}
                inputWidth={532}
                inputHeight={48}
                required={true}
                placeholder={'담당부서를 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, deptName: e.target.value });
                }}
                value={form?.deptName || ''}
              />
            </Section>
          </div>
          <Section>
            <NextStep>
              <Button $buttonType={'large'} text={'저장하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={onSave} disabled={isDisabled()} $iconDisabled={IcoStepDoneDisabled} />
            </NextStep>
          </Section>
        </>
      ) : (
        <div style={{ marginTop: '24px' }}>
          <Section>
            <LabelValue label={'발주처(기관명)'} value={form?.name || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Section>
            <LabelValue label={'기관 유형'} value={form?.role || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Section>
            <LabelValueStyle.Wrap $alignItem={'flex-start'}>
              <LabelValueStyle.Key $alignItem={'flex-start'} $labelWidth={155} fontSize={16} $labelColor={'#001A21'}>
                주소
              </LabelValueStyle.Key>
              <LabelValueStyle.Cont>
                <LabelValueStyle.Row>
                  <LabelValueStyle.Badge>우편번호</LabelValueStyle.Badge>
                  <LabelValueStyle.Value fontSize={16} $valueColor={'#001A21'}>
                    ({form?.zipcode || ''})
                  </LabelValueStyle.Value>
                </LabelValueStyle.Row>
                <LabelValueStyle.Row>
                  <LabelValueStyle.Badge>주소</LabelValueStyle.Badge>
                  <LabelValueStyle.Value fontSize={16} $valueColor={'#001A21'}>
                    {form?.addr?.split('&')[0] || ''}
                  </LabelValueStyle.Value>
                </LabelValueStyle.Row>
                <LabelValueStyle.Row>
                  <LabelValueStyle.Badge>상세주소</LabelValueStyle.Badge>
                  <LabelValueStyle.Value fontSize={16} $valueColor={'#001A21'}>
                    {form?.addr?.split('&')[1] || ''}
                  </LabelValueStyle.Value>
                </LabelValueStyle.Row>
              </LabelValueStyle.Cont>
            </LabelValueStyle.Wrap>
          </Section>
          <Section>
            <LabelValue label={'행정구역'} value={form?.stdg || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Section>
            <LabelValue label={'전화번호'} value={form?.tel || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Section>
            <LabelValue label={'이메일'} value={form?.email || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Section>
            <LabelValue label={'담당부서'} value={form?.deptName || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Button $buttonType={'large'} text={'수정하기'} style={{ marginTop: 20 }} onClick={onToggleEditMode} />
        </div>
      )}
    </div>
  );
};

export default Step1;
