import VectorModule from './VectorModule2';
import { Feature } from 'ol';
import { MultiPolygon, Polygon } from 'ol/geom';
import { baseProjection, targetProjection } from '../projection';

//폴리곤 관련 모듈 (벡터의 함수 상속받음)
class FarmMapModule extends VectorModule {
  public createFarmMapFeature(data: any) {
    const polygonFeature = new Feature({
      geometry: new MultiPolygon(data.geometry),
      property: { ...data },
    });
    // polygonFeature.getGeometry();
    return polygonFeature;
  }

  public createFarmMapFeatures(datas: any[]) {
    return datas?.map((data) => {
      return this.createFarmMapFeature(data);
    });
  }
}

export default FarmMapModule;
