import React, { useEffect, useState } from 'react';
import { NextStep, Section } from '../../../../components/atoms/TabContent';
import LabelInput, { LabelInputStyle } from '../../../../components/atoms/LabelInput';
import LabelSelect from '../../../../components/atoms/LabelSelect';
import HelpMessage from '../../../../components/atoms/HelpMessage';
import IcoHelp from '../../../../assets/images/etc/ico-help-message.svg';
import Button from '../../../../components/atoms/Button';
import IcoFileUpload from '../../../../assets/images/button/ico-file-upload.svg';
import ScrollTable from '../../../../components/atoms/ScrollTable';
import Checkbox from '../../../../components/atoms/Checkbox';
import NoData from '../../../../components/atoms/NoData';
import IcoNoLot from '../../../../assets/images/noData/ico-no-lot.svg';
import IcoStepNext from '../../../../assets/images/button/ico-step-next.svg';
import { useRecoilState } from 'recoil';
import { formState } from '../../../../store/Atoms';
import { PagingTableStyle } from '../../../../components/atoms/PagingTable';
import TdEllipsis from '../../../../components/atoms/TdEllipsis';
import RangePicker from '../../../../components/atoms/RangePicker';
import { DateObject } from 'react-multi-date-picker';
import IcoStepDone from '../../../../assets/images/button/ico-step-done.svg';
import query from 'hooks/RQuery/utils';
import IcoO from 'assets/images/popup/ico-o.svg';
import IcoX from 'assets/images/popup/ico-x.svg';
import IcoStepDoneDisabled from '../../../../assets/images/button/ico-step-done-disabled.svg';

// 사업장 추가 화면
const Step1 = ({ onTogglePopup, onClickSave }) => {
  const companyId = sessionStorage.getItem('companyId');
  const [form, setForm] = useRecoilState(formState);

  // 발주처 옵션
  const [option, setOption] = useState<any>([]);
  // 회차 옵션
  const [roundOption, setRoundOption] = useState<any>([
    {
      value: 1,
      text: '1',
      active: false,
    },
    {
      value: 2,
      text: '2',
      active: false,
    },
    {
      value: 3,
      text: '3',
      active: false,
    },
    {
      value: 4,
      text: '4',
      active: false,
    },
    {
      value: 5,
      text: '5',
      active: true,
    },
  ]);

  const [isAllCheck, setIsAllCheck] = useState(false); // 필지 목록 전체 선택 유무
  const [addrList, setAddrList] = useState<any[]>([]); // 보여줄 필지 목록 (checked 포함)
  const [fileList, setFileList] = useState<any[]>([]); //보여줄 shp 파일 목록 (checked 포함)

  // 발주처 목록 조회 (전체목록 size: 99999)
  const { data } = query.client.useGetClients({ companyId, pageNum: 1, size: 99999 });
  const { content: clientList } = data || {};

  // 발주처 option용 형태 변경
  useEffect(() => {
    if (clientList?.length > 0) {
      setOption(clientList?.map((ele) => ({ text: ele.name, value: ele.inspectorCompanyId, active: false })));
    }
  }, [clientList]);

  // 필지 정보 목록용 형태 변경
  //FIXME: id 부분변경
  useEffect(() => {
    if (form?.address?.length > 0) {
      let bjdCdList = form?.bjdCdList; // id로 법정코드 사용
      setAddrList(form?.address?.map((ele, idx) => ({ id: bjdCdList[idx], text: ele, checked: false })));
    } else {
      setAddrList([]);
    }
  }, [form?.address, form?.bjdCdList]);

  // 파일 정보 목록용 형태 변경
  useEffect(() => {
    if (form?.shpFile?.length > 0) {
      setFileList(form?.shpFile?.map((ele, idx) => ({ id: 'shp_' + idx, text: ele.name, types: ele.types, isValid: ele.isValid, checked: false })));
    } else {
      setFileList([]);
    }
  }, [form?.shpFile]);

  // 목록 아무것도 없을때, submitType 초기화
  useEffect(() => {
    if (fileList?.length === 0 && addrList?.length === 0) {
      setForm((prev) => ({ ...prev, submitType: null }));
    }
  }, [addrList, fileList]);

  //전체 선택/해제
  const onCheckAll = (e) => {
    const { checked } = e.target;
    setAddrList((prev) => [...prev]?.map((ele) => ({ ...ele, checked })));
    setFileList((prev) => [...prev]?.map((ele) => ({ ...ele, checked })));
  };

  //체크박스 클릭
  const onCheck = (e) => {
    const { id, checked } = e.target;
    setAddrList((prev) => [...prev]?.map((ele) => (ele.id === id ? { ...ele, checked } : ele)));
    setFileList((prev) => [...prev]?.map((ele) => (ele.id === id ? { ...ele, checked } : ele)));
  };

  // 체크된수 === 전체 수 같은지 확인 후 전체체크박스 체크선택/해제
  useEffect(() => {
    let isAllCheck = addrList?.length > 0 && addrList.filter(({ checked }) => checked).length === addrList?.length;
    // FIXME:
    if (isAllCheck) {
      setIsAllCheck(true);
    } else {
      setIsAllCheck(false);
    }
  }, [addrList]);

  // 필지 목록 한줄 삭제
  const removeRow = (idx) => {
    // 주소, 법정동코드 업데이트
    setForm((form) => ({ ...form, address: form?.address?.filter((ele, index) => index !== idx), bjdCdList: form?.bjdCdList?.filter((ele, index) => index !== idx) }));

    setForm((form) => ({ ...form, shpFile: form?.shpFile?.filter((ele, index) => index !== idx) }));
  };

  // 선택항목 일괄 삭제
  const removeMultiRow = () => {
    let idxArr: number[] = [];
    addrList?.filter((ele, idx) => (ele.checked ? idxArr.push(idx) : null)); // 체크된 항목 인덱스 배열 생성
    // 주소, 법정동코드 업데이트 (인덱스 배열에 포함되지 않은 항목만 남기기)
    setForm((form) => ({ ...form, address: form?.address?.filter((ele, index) => !idxArr.includes(index)), bjdCdList: form?.bjdCdList?.filter((ele, index) => !idxArr.includes(index)) }));
  };

  // [사업기간]
  const onChangeDate = (e, type) => {
    const YYYYMMDD = new DateObject(e).format('YYYY-MM-DD');
    if (type === 'from') {
      setForm({ ...form, date: { ...form.date, from: e }, surveyBeginDate: YYYYMMDD });
    } else {
      setForm({ ...form, date: { ...form.date, to: e }, surveyEndDate: YYYYMMDD });
    }
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeSelect = (e, name) => {
    const value = e.target?.ariaValueText;
    setOption((prev) => {
      return [...prev]?.map((ele) => ({ ...ele, active: String(ele.value) === value }));
    }); // active
    setForm({ ...form, [name]: value }); // inspectorCompanyId 저장
  };

  return (
    <>
      <div style={{ width: 1272, height: '100%' }}>
        <Section>
          <LabelInput label={'사업장명'} fontSize={16} inputWidth={1116} inputHeight={48} required={true} placeholder={'사업장명을 입력하세요'} name={'projectName'} onChange={onChangeInput} value={form?.projectName || ''} />
        </Section>
        <Section style={{ display: 'flex' }}>
          <div style={{ width: 635, borderRight: '1px solid #E5E5EC' }}>
            <LabelSelect label={'발주처'} inputWidth={320} inputHeight={48} required={true} placeholder={'발주처를 선택하세요'} option={option} $borderColor={'#E5E5EC'} name={'inspectorCompanyId'} onChange={onChangeSelect} />
            <HelpMessage message={'발주처가 없는 경우 <a href="/client/add">[발주처 관리]</a>에서 추가하세요.'} position={{ left: 155, top: 10 }} icon={IcoHelp} />
          </div>
          <div style={{ marginLeft: 40 }}>
            <LabelInput label={'프로젝트 ID'} fontSize={16} inputWidth={320} inputHeight={48} required={true} placeholder={'프로젝트 ID를 입력하세요'} name={'prjId'} onChange={onChangeInput} value={form?.prjId || ''} />
          </div>
        </Section>

        <Section style={{ display: 'flex' }}>
          <div style={{ width: 635, borderRight: '1px solid #E5E5EC' }}>
            <LabelInputStyle.Wrap style={{ height: '100%' }} $alignItem={'flex-start'}>
              <LabelInputStyle.Key required={true}>사업 기간</LabelInputStyle.Key>
              <RangePicker date={form.date} onChangeDate={onChangeDate} />
            </LabelInputStyle.Wrap>
          </div>
          <div style={{ marginLeft: 40 }}>
            <LabelSelect
              label={'판독 회차'}
              inputWidth={112}
              inputHeight={48}
              required={true}
              option={roundOption}
              $borderColor={'#E5E5EC'}
              onChange={(e: any) => {
                const newArr = [...roundOption];
                newArr.map((i) => {
                  i.active = String(i.value) === e.target.ariaValueText;
                });
                setRoundOption(newArr);
                setForm({ ...form, surveyCount: e.target.ariaValueText });
              }}
              subText={'회차까지 실행'}
            />
          </div>
        </Section>

        <Section style={{ height: 'calc(100% - 386px)' }}>
          <LabelInputStyle.Wrap style={{ height: '100%' }} $alignItem={'flex-start'}>
            <LabelInputStyle.Key required={true}>필지 정보</LabelInputStyle.Key>
            <div style={{ width: '100%', height: '100%' }}>
              <div style={{ display: 'flex', marginBottom: 14 }}>
                <HelpMessage message={'<b>주소 검색</b> 또는 <b>shp 파일</b>을 업로드해서 필지를 추가하세요.'} />
                <div style={{ display: 'flex', marginLeft: 'auto' }}>
                  <Button $buttonType={'medium'} text={'필지 업로드'} icon={IcoFileUpload} $iconSize={20} $iconSpacing={6} onClick={onTogglePopup} />
                </div>
              </div>
              <ScrollTable
                $minHeight={'calc(100% - 142px)'}
                $maxHeight={'calc(100% - 142px)'}
                caption={'필지 정보'}
                colgroup={form?.submitType === 'shp' ? [60, 300, 100, 100, 100, 'auto', 100] : [60, 'auto', 100]}
                thead={
                  <tr>
                    <th scope={'col'}>
                      <Checkbox onChange={onCheckAll} id={'checkAll'} checked={isAllCheck} />
                    </th>
                    <th scope={'col'}>{form?.submitType === 'shp' ? '파일명' : '주소'}</th>
                    {form?.submitType === 'shp' && (
                      <>
                        <th scope={'col'}>shp 파일</th>
                        <th scope={'col'}>dbf 파일</th>
                        <th scope={'col'}>shx 파일</th>
                        <th scope={'col'}>prj 파일</th>
                      </>
                    )}
                    <th scope={'col'}></th>
                  </tr>
                }
                tbody={
                  <>
                    {form?.submitType === 'address' &&
                      addrList?.length > 0 &&
                      addrList.map((ele, idx) => (
                        <tr key={'address_' + ele.id} className={ele.checked ? 'checked' : undefined}>
                          <td>
                            <Checkbox id={ele.id} checked={ele.checked} onChange={onCheck} />
                          </td>
                          <td>
                            <TdEllipsis str={ele.text || '-'} />
                          </td>
                          <td>
                            <PagingTableStyle.BtnRowRemove className={`btnRemove ${ele.checked ? 'checked' : undefined}`} type={'button'} onClick={() => removeRow(idx)}></PagingTableStyle.BtnRowRemove>
                          </td>
                        </tr>
                      ))}
                    {form?.submitType === 'shp' &&
                      fileList?.length > 0 &&
                      fileList.map((ele, idx) => (
                        <tr key={'shp_' + ele.id} className={ele.checked ? 'checked' : undefined}>
                          <td>
                            <Checkbox id={ele.id} checked={ele.checked} onChange={onCheck} />
                          </td>
                          <td>
                            <TdEllipsis str={ele.text || '-'} />
                          </td>
                          <td>
                            <div>{ele.types.includes('shp') ? <img src={IcoO} alt={'O'} /> : <img src={IcoX} alt={'X'} />}</div>
                          </td>
                          <td>
                            <div>{ele.types.includes('dbf') ? <img src={IcoO} alt={'O'} /> : <img src={IcoX} alt={'X'} />}</div>
                          </td>
                          <td>
                            <div>{ele.types.includes('shx') ? <img src={IcoO} alt={'O'} /> : <img src={IcoX} alt={'X'} />}</div>
                          </td>
                          <td>
                            <div>{ele.types.includes('prj') ? <img src={IcoO} alt={'O'} /> : <img src={IcoX} alt={'X'} />}</div>
                          </td>
                          <td>
                            <PagingTableStyle.BtnRowRemove className={`btnRemove ${ele.checked ? 'checked' : undefined}`} type={'button'} onClick={() => removeRow(idx)}></PagingTableStyle.BtnRowRemove>
                          </td>
                        </tr>
                      ))}
                    {addrList?.length < 1 && fileList?.length < 1 && (
                      <tr className="noHover">
                        <td colSpan={6} style={{ height: '100%', borderBottom: 0 }}>
                          <NoData text={'등록된 필지가 없습니다.<br/>필지 주소 또는 shp 파일을 업로드해주세요.'} color={'#3F3F3F'} icon={IcoNoLot} $iconSize={54} padding={[50, 50]} />
                        </td>
                      </tr>
                    )}
                  </>
                }
                $hasTotal={true}
                total={`${addrList?.length || fileList?.filter(({ isValid }) => isValid)?.length} 개`}
                checkLength={addrList.filter(({ checked }) => checked).length}
                $totalBtnName={'필지 삭제'}
                onRemove={removeMultiRow}
              />
              <NextStep>
                <Button $buttonType={'large'} text={'저장하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={onClickSave} $iconDisabled={IcoStepDoneDisabled} />
              </NextStep>
            </div>
          </LabelInputStyle.Wrap>
        </Section>
      </div>
    </>
  );
};

export default Step1;
