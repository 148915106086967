import React, { useEffect, useState } from 'react';
import IcoPage2 from '../../../assets/images/nav/ico-menu2-page.svg';
import IcoNoProject from '../../../assets/images/noData/ico-no-project.svg';
import PagingTable from '../../../components/atoms/PagingTable';
import AdminContainer from '../../../components/templates/Container/AdminContainer';
import Checkbox from '../../../components/atoms/Checkbox';
import RoundBadge from '../../../components/atoms/RoundBadge';
import TdEllipsis from '../../../components/atoms/TdEllipsis';
import NoData from '../../../components/atoms/NoData';
import { useNavigate } from 'react-router-dom';
import query from 'hooks/RQuery/utils';

const ClientList = () => {
  const companyId = sessionStorage.getItem('companyId');
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const clientList = query.client.useGetClients({ companyId: companyId, pageNum: currentPage, size: 10 });
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (clientList.data) {
      const newArr: any = [];
      clientList.data.content.map((i) => {
        newArr.push({ ...i, checked: false });
      });
      setTableData(newArr);
      setTotalPages(clientList.data.totalPages);
    }
  }, [clientList.data]);

  return (
    <AdminContainer
      title={'발주처 관리'}
      icon={IcoPage2}
      headRight={
        <button type={'button'} style={{ color: '#00B074', fontSize: 16, fontWeight: 500 }} onClick={() => navigate('/client/add')}>
          + 발주처 추가
        </button>
      }
      content={
        <PagingTable
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          caption={'발주처 목록'}
          colgroup={[300, 'auto', 180, 300, 280]}
          hasTotal={true}
          thead={
            <tr>
              <th style={{ paddingLeft: 20 }}>발주처(기관)</th>
              <th>주소</th>
              <th>전화번호</th>
              <th>이메일</th>
              <th>담당부서</th>
            </tr>
          }
          tbody={
            <>
              {tableData.length ? (
                tableData.map((item: any) => {
                  return (
                    <tr key={item.inspectorCompanyId}>
                      <td onClick={() => navigate('/client/detail/' + item.inspectorCompanyId)} style={{ cursor: 'pointer', paddingLeft: 20 }}>
                        <TdEllipsis str={item.name} />
                      </td>
                      <td onClick={() => navigate('/client/detail/' + item.inspectorCompanyId)} style={{ cursor: 'pointer' }}>
                        <TdEllipsis str={item.addr.replaceAll('&', ', ')} />
                      </td>
                      <td onClick={() => navigate('/client/detail/' + item.inspectorCompanyId)} style={{ cursor: 'pointer' }}>
                        {item.tel}
                      </td>
                      <td onClick={() => navigate('/client/detail/' + item.inspectorCompanyId)} style={{ cursor: 'pointer' }}>
                        <TdEllipsis str={item.email} />
                      </td>
                      <td onClick={() => navigate('/client/detail/' + item.inspectorCompanyId)} style={{ cursor: 'pointer' }}>
                        <TdEllipsis str={item.deptName} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="noHover">
                  <td colSpan={7} rowSpan={10} style={{ height: 600 }}>
                    <NoData text={'등록된 발주처가 없습니다.'} icon={IcoNoProject} color={'#3F3F3F'} />
                  </td>
                </tr>
              )}
            </>
          }
        />
      }
    />
  );
};

export default ClientList;
