import styled from 'styled-components';
import IcoCloseWhite from 'assets/images/ico-close-white.svg';
import IcoCheckActive from 'assets/images/cropInfoBox/ico-check-active.svg';

import IcoArrowLeft from 'assets/images/ico-arrow-btn-left.svg';
import IcoArrowRight from 'assets/images/ico-arrow-btn-right.svg';

import IcoTotal from 'assets/images/cropInfoBox/ico-total.svg';

export const CropInfoSt = {
  Wrap: styled.div<{ $isAdmin?: boolean }>`
    width: 100%;
    position: fixed;
    top: 80px;
    /* top: ${(p) => (p.$isAdmin ? '16px' : '80px')}; */
    z-index: 11;
    right: 17px;
    left: auto;
    transition: 0.4s;
    &.hide {
      width: calc(304px + 180px); // 필지체크, 작물체크, 카드영역
    }
    @media (min-width: 1800px) {
      width: calc(100% - 135px - 16px); // 전체화면 - 왼쪽 padding - 오른쪽 padding
      width: ${(p) => (p.$isAdmin ? 'calc(100% - 96px - 16px - 16px)' : 'calc(100% - 135px - 16px)')};
    }
  `,
  Header: styled.div`
    /* min-width: 484px; */
    height: 52px;
    box-sizing: border-box;
    background-color: #024751;
    padding: 0 16px 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px 4px 0 0;
    .title {
      color: #09d585;
      font-size: 16px;
      font-weight: 600;
    }
    .closeBtn {
      width: 20px;
      height: 20px;
      background: url(${IcoCloseWhite}) no-repeat center;
      cursor: pointer;
    }
  `,
  ContainerWrap: styled.div`
    display: flex;
    flex-direction: row;
    &.hide {
      width: 485px;
    }
  `,
  Container: styled.div`
    flex: 1;
    overflow: hidden;
    /* min-width: 484px; */
    height: calc(100vh - 52px - 83px - 35px);
    background-color: #f0f0f0;
    border-radius: 0 0 4px 4px;
    border-top: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 0px #00000059;
    display: flex;
    flex-direction: row;

    > * {
      flex-shrink: 0;
    }
  `,
  TypeCheckWrap: styled.div`
    width: 90px;
    height: 100%;
    background-color: #fff;
    padding: 12px 7px 0 20px;
    border-right: 1px solid #d9d9d9;
    box-sizing: border-box;
    display: flex;
    ul {
      display: flex;
      flex-direction: column;
      gap: 14px;
      li {
      }
    }
  `,
  CropCheckWrap: styled.div`
    min-width: 90px;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #d9d9d9;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    ul {
      padding: 12px 7px 12px 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 14px;
      &:nth-child(2) {
        border-top: 1px solid #d9d9d9;
      }
      li {
      }
    }
  `,
  ContentWrap: styled.div`
    width: 304px;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* overflow: auto; */
    .pageListWrap {
      width: 100%;
      height: 48px;
      border-top: 1px solid #d9d9d9;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
  Content: styled.div`
    height: calc(100% - 48px);
    padding: 6px 12px 12px 12px;
    box-sizing: border-box;
    .selectAll {
      width: 100%;
      height: 44px;
      margin-bottom: 11px;
      background-color: #fff;
      padding: 0 16px;
      border: 1px solid #e9e7e7;
      box-sizing: border-box;
      box-shadow: 0px 4px 6px 0px #00000014;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .selectWrap {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #00b074;
        font-weight: 500;
        .num {
          width: 22px;
          height: 20px;
          padding: 6px 8px;
          box-sizing: border-box;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #00b074;
          color: #fff;
          font-size: 12px;
        }
      }
    }
    .filterWrap {
      margin-top: 6px;
      /* margin-top: 11px; */
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btnWrap {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 4px;
        button {
          height: 100%;
          padding: 10px;
          box-sizing: border-box;
          background-color: #fff;
          border: 1px solid #e9e7e7;
          border-radius: 20px;
          color: #7e8888;
          display: flex;
          gap: 6px;
          align-items: center;
          font-weight: 500;
          /* font-size: 12px; */
          &.active {
            border: 1px solid #00b074;
            color: #00b074;
            /* &::before {
              content: '';
              display: inline-block;
              width: 9px;
              height: 6px;
              background: url(${IcoCheckActive}) no-repeat center;
              background-size: cover;
            } */
          }
        }
      }
      .totalWrap {
        font-size: 12px;
        color: #00b074;
        font-weight: 500;
        .total {
          color: #024751;
          font-weight: 600;
        }
      }
    }
  `,
  CardWrap: styled.div`
    width: 100%;
    height: calc(100% - 49px - 44px);
    margin-top: 8px;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 6px;
  `,
  SingleLotInfo: styled.div`
    margin-top: 12px;
    background: #fff;
    border: 2px solid #00b074;
    padding: 0 0 0 16px;
    height: calc(100% - 230px);
  `,
  Card: styled.div`
    position: relative;
    flex-shrink: 0;
    padding: 2px;
    width: 100%;
    height: 218px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e9e7e7;
    box-sizing: border-box;
    &.active {
      background: #00b074;
    }
  `,
  CardHead: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
    height: 44px;
    box-sizing: border-box;
    border-bottom: 1px solid #e9e7e7;
    background: #fff;
    label {
      cursor: pointer;
    }
  `,
  CardCont: styled.div`
    background: #fff;
    height: calc(100% - 108px);
    box-sizing: border-box;
    border-bottom: 1px solid #e9e7e7;
    display: flex;
    align-items: center;
    position: relative;
    .imgCount {
      position: absolute;
      bottom: 16px;
      right: 22px;
      background-color: #ff7600;
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      color: #fff;
      font-size: 12px;
    }
  `,
  CardFoot: styled.div`
    height: 64px;
    background: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
  CardImageArea: styled.div`
    flex: 1;
    padding: 0 14px;
    height: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .imageWrap {
      flex: 1;
      height: 100%;
      border-radius: 4px;
      background-color: #d6d6d6;
      position: relative;
      &:not(:last-child) {
        margin-right: 8px;
      }
      > .imgCount {
        position: absolute;
        bottom: 6px;
        right: 6px;
        background-color: #d6d6d6;
        display: flex;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        color: #fff;
        font-size: 12px;
        &.active {
          background-color: #ff7600;
        }
      }
      > img {
        flex: 1;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  `,
  CardLabelValueArea: styled.div`
    width: calc(100% - 28px);
    padding: 0 14px;
  `,
};

export const ActiveAreaSt = {
  Wrap: styled.div`
    flex: 1; // 자유롭게 축소
    width: calc(100% - 180px - 304px - 304px); // 고정영역을 제외
    /* width: fit-content; */
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e9e7e7;
    transition: 0.4s;
    &.hide {
      width: 0;
      overflow: hidden;
      visibility: hidden;
    }
  `,
  PicArea: styled.div<{ $isMultiPic?: boolean }>`
    width: 100%;
    ${(p) => p.$isMultiPic && 'height: 45%;'}; // 최소 지도 영역 고정으로 처리

    max-height: 50%;
    padding: 10px 12px;
    box-sizing: border-box;
    border-bottom: 1px solid #e9e7e7;
    display: flex;
    flex-direction: column;
    .picBoxWrap {
      width: 100%;
      height: 100%;
      padding: 6px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }
  `,
  PicWrap: styled.div<{ $isMultiPic?: boolean }>`
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    /* height: 176px; */
    //max-height: calc(340px + 6px + 6px); // 이미지 2개, gap, 스크롤바 영역
    display: flex;
    flex-direction: ${(p) => (p.$isMultiPic ? 'column' : 'row')};
    ${(p) => p.$isMultiPic && 'flex-wrap: wrap'};
    /* flex: 1; */
    /* gap: 6px; */
  `,
  PicType: styled.strong`
    background: #ffffff;
    font-size: 14px;
    font-weight: 500;
    color: #00b074;
    box-sizing: border-box;
    height: calc(100% - 5px);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    flex-shrink: 0;
  `,
  PicCard: styled.div<{ $width?: number; $isMultiPic?: boolean }>`
    width: ${(p) => (p.$isMultiPic ? '50%' : p.$width ? p.$width + 'px' : '228px')};
    height: ${(p) => (p.$isMultiPic ? '50%' : '168px')};
    flex-shrink: 0;
    border-radius: 12px;
    box-sizing: border-box;
    border: 1px solid #e9e7e7;
    &:not(:last-child) {
      /* margin-bottom: 12px; */
    }
    &.single {
      width: 100%;
      height: 100%;
      strong {
        width: auto;
        max-width: 100%;
        box-sizing: border-box;
      }
    }
    &.active {
      background: #00b074;
      > div {
        background: #d1f1e6;
      }
    }
    &.disabled {
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: calc(100% - 2px);
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 12px;
      }
    }
  `,
  PicCardInner: styled.div`
    background: #fff;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    width: 100%;
    height: 100%;
    border-radius: 9px;
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  `,
  PicBox: styled.div`
    position: relative;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #e9e7e7;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    &:not(:last-child) {
      margin-right: 2px;
    }
    button {
      position: absolute;
      right: 8px;
      bottom: 8px;
      z-index: 9;
    }

    > .TitleWrap {
      position: relative;
      width: 100%;
      .title {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        width: calc(100% - 24px);
        padding: 0 12px;
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.6);
        color: #00b074;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .tooltip {
        display: none;
        color: white;
        background-color: #3b3b3b;
      }
    }
  `,
  PicTitle: styled.strong`
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 12px;
    width: calc(100% - 24px);
    height: 32px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.6);
    color: #00b074;
    font-size: 12px;
    font-weight: 500;
    /* text-align: center; */
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  MapArea: styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 12px 16px;
    box-sizing: border-box;
    #mapContainer2 {
      position: relative;
      width: 100%;
      height: 100%;
      /* min-height: 360px; */
      background-color: #c3c3c3;
      border-radius: 8px;
      /* overflow: hidden; */
      > .ol-viewport {
        position: absolute !important;
        left: 0;
        top: 0;
        border-radius: 8px;
      }
      > .loadingPage {
        width: 100%;
        height: 100%;
        color: #9f9f9f;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  `,
};

export const DataEnterAreaSt = {
  Contain: styled.div`
    position: relative;
    z-index: 10;
  `,
  BeforeBtn: styled.button`
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 40px;
    background: #fff url(${IcoArrowLeft}) no-repeat center;
    border-radius: 4px 0 0 4px;
    border: 1px solid #e9e7e7;
    transition: 0.4s;

    &.active {
      left: -324px;
      background-image: url(${IcoArrowRight});
    }
  `,
  Wrap: styled.div`
    /* flex: 1; */
    width: 304px;
    height: 100%;
    border-left: 1px solid #e9e7e7;
    background-color: #ffffff;
    box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.08);
    transition: 0.4s;
    position: relative;

    &.hide {
      width: 0;
      overflow: hidden;
      visibility: hidden;
    }
  `,
  TabBtnWrap: styled.div`
    display: flex;

    .tabBtn {
      width: 152px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #e9e7e7;
      box-sizing: border-box;
      background: #f3f5f4;
      color: #7e8888cc;
      font-weight: 600;

      &.active {
        position: relative;
        background-color: #fff;
        color: #001a21;

        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          width: 100%;
          height: 3px;
          border-radius: 2px 2px 0px 0px;
          background: #00b074;
        }
      }
    }
  `,
  ReadGrdTypeWrap: styled.div`
    height: calc(100% - 54px); // tab 영역 제외
    display: flex;
    flex-direction: column;

    ul {
      li.radioItem {
        height: 48px;
        padding: 0 16px;
        /* padding: 10px 16px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        border-bottom: 1px solid #e9e7e7;

        .value {
          margin-top: 4px;
          margin-bottom: 8px;
          height: 17px;
          text-align: right;
          color: #6f979c;
          font-weight: 500;
        }

        .percent {
          color: #7e8888;

          &.active {
            color: #025143;
          }
        }
        .labelValue {
          width: 100%;
          min-height: 36px;
          height: inherit;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  `,
  FormWrap: styled.div<{ $height?: string }>`
    padding: 10px 16px 16px 42px;
    /* border-top: 1px solid #e9e7e7; */
    border-bottom: 1px solid #e9e7e7;
    background-color: #f0f5f3;
    height: 100%;
    /* height: calc(100% - (54px + 49px * 4) - 133px); */
    /* height: calc(100% - 133px - 49px); */
    height: ${(p) => (p.$height ? p.$height : `calc(100% - (54px + 49px * 4) - 133px)`)};
    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      li.formItem {
        > .labelValue {
          min-height: 36px;
          height: inherit;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        > .labelInput {
          min-height: 36px;
          height: inherit;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .label,
          label {
            color: #00b074;
            font-weight: 500;
          }
        }
        .percent {
          color: #7e8888;
          &.active {
            color: #025143;
          }
        }
        .value {
          margin-top: 4px;
          margin-bottom: 8px;
          height: 17px;
          text-align: right;
          color: #6f979c;
          font-weight: 500;
        }
      }
    }
  `,
  SaveWrap: styled.div`
    margin-top: auto;
    margin-bottom: 36px;
    width: 100%;
    padding: 14px 12px 0 12px;
    box-sizing: border-box;
    border-top: 2px solid #00b074;
    display: flex;
    flex-direction: column;
    .totalWrap {
      margin-bottom: 16.5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .titleWrap {
        display: flex;
        align-items: center;
        gap: 8px;
        .ico {
          display: block;
          width: 18px;
          height: 18px;
          background: url(${IcoTotal}) no-repeat center;
        }
        .title {
          color: #024751;
          font-weight: 500;
        }
      }
      .valueWrap {
        display: flex;
        align-items: center;
        gap: 8px;
        .value {
          color: #024751;
          font-size: 16px;
          font-weight: 600;
        }
        .unit {
          color: #7e8888;
        }
      }
    }
    .lastUpdate {
      line-height: 14px;
      color: #00b074;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 16px;
      text-align: right;
      text-decoration-line: underline;
    }
  `,
  BeforeRound: styled.div`
    position: absolute;
    background: #fff;
    z-index: 10;
    width: 0;
    transition: 0.4s;
    opacity: 0;
    visibility: hidden;
    height: 100%;
    right: 100%;
    top: 0;
    box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.08);
    border-right: 1px solid #e9e7e7;
    &.active {
      width: 304px;
      opacity: 1;
      visibility: visible;
    }
  `,
};
