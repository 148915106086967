import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const AdminContainerStyle = {
  Wrap: styled.div`
    position: relative;
    background: #fff;
    flex: 1;
    height: 100%;
  `,
  Inner: styled.div`
    height: 100%;
    padding: 0 36px;
  `,
  Head: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-bottom: 1px solid #d9d9d9;
    height: 72px;
    margin-bottom: 24px;
  `,
  Title: styled.h2<AdminContainerProps>`
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #3f3f3f;
    font-weight: 600;
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      margin-right: 10px;
      ${(p) => p.icon && `background:url(${p.icon})no-repeat center`};
      background-size: contain;
    }
  `,
  Cont: styled.div`
    height: calc(100% - 96px);
  `,
};

interface AdminContainerProps {
  title?: string;
  icon?: string;
  headRight?: ReactNode;
  content?: ReactNode;
}

const AdminContainer = ({ title, icon, headRight, content }: AdminContainerProps) => {
  return (
    <AdminContainerStyle.Wrap>
      <AdminContainerStyle.Inner>
        <AdminContainerStyle.Head>
          <AdminContainerStyle.Title icon={icon}>{title}</AdminContainerStyle.Title>
          {headRight ? headRight : null}
        </AdminContainerStyle.Head>
        <AdminContainerStyle.Cont>{content ? content : null}</AdminContainerStyle.Cont>
      </AdminContainerStyle.Inner>
    </AdminContainerStyle.Wrap>
  );
};

export default AdminContainer;
