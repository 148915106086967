import React, { Fragment, useEffect, useRef, useState } from 'react';
import { DataEnterAreaSt } from '../_style';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { confirmState, popupLargeImgState, toastPopState, userInfoState } from 'store/Atoms';
import query from 'hooks/RQuery/utils';
import { formatDate2 } from 'util/global';
import useOnChange from 'hooks/useOnChange';
import Input from 'components/atoms/Input';
import Checkbox from 'components/atoms/Checkbox';
import RoundBadge from 'components/atoms/RoundBadge';
import Button from 'components/atoms/Button';
import IcoNextLot from 'assets/images/button/ico-next-lot.svg';

// 선택된 항목 있을때 데이터 입력하는 영역
function DataEnterArea({ hide, cardData, round, prevSurveyCrops, surveyCrops, surveyDatas, refetchSurveyLots, refetchSurveyData, selectNextCard, onChangeFeatureStatus }) {
  const userInfo = useRecoilValue(userInfoState);
  const [confirm, setConfirm] = useRecoilState(confirmState);

  const [checkboxType, setCheckboxType] = useState('radio');
  const [tabNum, setTabNum] = useState(1); // 점유율 | 잔여량
  const [beforeShow, setBeforeShow] = useState(false);
  const [radioValue, setRadioValue] = useState(''); // 필지 타입 판독하기

  const [options, setOptions] = useState<any[]>([]); // 비경지,휴경지,시설,경작지 옵션
  const [cropOptions, setCropOptions] = useState<any[]>([]); // 작물 옵션

  const [prevOptions, setPrevOptions] = useState<any[]>([]); // 비경지,휴경지,시설,경작지 옵션(이전회차)
  const [prevCropOptions, setPrevCropOptions] = useState<any[]>([]); // 작물 옵션(이전회차)

  const [newCropOption, setNewCropOption] = useState<any[]>([]); // 작물 옵션(total)
  const [newPrevCropOptions, setNewPrevCropOptions] = useState<any[]>([]); // 작물 옵션(이전회차(total))

  const [totalSum, setTotalSum] = useState(0); // 점유율 총합
  const [totalSum2, setTotalSum2] = useState(0); // 잔여량 총합

  //   const [form, setForm, onChange] = useOnChange({}); // [점유율] - 작물input
  const [form2, setForm2, onChange2] = useOnChange({}); // [잔여량] - 작물input

  const [beforeTotalSum, setBeforeTotalSum] = useState(0); // 이전 회차 판독 데이터 TotalSum
  const [beforeSurveyCrops, setBeforeSurvey] = useState<any[]>([]); // 이전 회차 판독 데이터
  const [thisSurveyCrops, setThisSurvey] = useState<any[]>([]); // 당회차 판독 데이터 - 잔여량 옵션

  const [defaultOption, setDefaultOption] = useState<any[]>([]); // 당회차 작물코드 서버에서 받은 데이터 (시설물 등)
  const [defaultCropOption, setDefaultCropOption] = useState<any[]>([]); // 당회차 작물코드 서버에서 받은 데이터 (경작지항목)
  const [defaultNewOption, setDefaultNewOption] = useState<any[]>([]);

  const [fillRateDate, setFillRateDate] = useState(''); // 점유율 최종 변경시간
  const [remainDate, setRemainDate] = useState(''); // 잔여량 최종 변경시간
  const [inspectDate, setInspectDate] = useState(''); // 잔여량 최종 변경시간

  const [isValidSaveBtn, setIsValidSaveBtn] = useState(false); //점유율 판독 가능 여부

  // 점유율 저장
  const { mutate: addFillRate } = query.survey.useAddFillRate();
  // 점유율 일괄 저장
  const { mutate: addFillRateMulti } = query.survey.useAddFillRateMulti();
  // 검수 완료
  const { mutate: completeInspect } = query.survey.useCompleteInspect();
  // 잔여량 수정
  const { mutate: updateRemain } = query.survey.useUpdateRemain();

  // 판독 데이터(당회차 thisSurvey, 이전회차 beforeSurvey)
  useEffect(() => {
    // 판독 & active된 카드 개수만큼 있음.
    if (surveyDatas?.length > 0) {
      const firstCardSurveyData = surveyDatas?.[0];

      // 이전회차 판독 정보
      const beforeSurveyCrops = firstCardSurveyData?.beforeSurvey?.crops;
      if (beforeSurveyCrops?.length > 0) {
        let total = 0;
        beforeSurveyCrops?.map((ele) => (total += ele?.fillRate));
        setBeforeTotalSum(total);
        setBeforeSurvey(beforeSurveyCrops);
      } else {
        setBeforeSurvey([]);
      }

      // 당회차 판독 정보 - 잔여량 옵션
      const thisSurveyCrops = firstCardSurveyData?.thisSurvey?.crops;
      if (thisSurveyCrops?.length > 0) {
        setThisSurvey(thisSurveyCrops);
      } else {
        setThisSurvey([]);
      }

      // 최종 변경 시간
      setFillRateDate(formatDate2(firstCardSurveyData?.fillRateModifiedDate));
      setRemainDate(formatDate2(firstCardSurveyData?.remainRateModifiedDate));
      setInspectDate(formatDate2(firstCardSurveyData?.inspectDate));
    }
  }, [surveyDatas]);

  const newResetOption = (cropData) => {
    if (cropData?.length > 0) {
      const cropArr = cropData;
      if (cropArr?.length > 0) {
        let newArr = cropArr?.map((ele) => ({ ...ele, text: ele.displayName, value: ele.surveyCropId, fillRate: 0, remainRate: 0, percent: null, checked: false }));

        return newArr;
      }
    }
  };

  // 당회차의 작물정보 (이전 판독값은 thisSurvey값이 있는 경우만 매칭.'당회차':100)
  useEffect(() => {
    let newCropOption = newResetOption(surveyCrops);

    setDefaultNewOption(newCropOption || []); // 초기화 용
    setNewCropOption(newCropOption || []); // 점유율 옵션용
  }, [surveyCrops]);

  // 이전 회차의 작물정보 - 이전 회차 데이터 화면에서 사용 (2회차 이상부터 보임)
  useEffect(() => {
    let newCropOption = newResetOption(prevSurveyCrops);

    setNewPrevCropOptions(newCropOption || []);
  }, [prevSurveyCrops]);

  // 점유율 옵션 '초기 정보'로 초기화
  const resetOptions = () => {
    // 1번 방법
    //  let newCropOption = newResetOption(prevSurveyCrops);
    //  setNewCropOption(newCropOption || []);

    // 2번 방법
    if (defaultNewOption?.length > 0) {
      setNewCropOption(defaultNewOption);
    }
  };

  // 🔥 선택된 카드 데이터 업데이트
  // => 1개일때: 입력창 형태 / 2개이상: 라디오 형태 (only 100%)
  useEffect(() => {
    resetOptions(); // 점유율 옵션 초기화

    const activeCard = [...cardData].filter(({ active }) => active); // 선택된 카드들만 필터

    // 선택된 카드가 1개일때 -> TODO: 판독카드 아이디로 판독정보 조회하도록 요청(최종변경 일자 등 포함) 부모영역에서?
    if (activeCard?.length === 1) {
      setCheckboxType('checkbox');
    }
    // 선택된 카드가 1개 이상
    if (activeCard?.length > 1) {
      setCheckboxType('radio');
      setTabNum(1); // 점유율 탭 활성화 (잔여량 탭 x)
      setBeforeShow(false); //이전 회차 정보탭 닫기 (FIXME:)
    }

    // 이미지 1,1 개씩 있는 것만 선택되어야 점유율 저장버튼 활성화
    let isValidSaveBtn = false;
    activeCard?.map((ele) => {
      if (ele.imageList?.wideImageList?.length === 1 && ele.imageList?.zoomImageList?.length === 1) {
        isValidSaveBtn = true;
      } else {
        isValidSaveBtn = false;
      }
    });
    setIsValidSaveBtn(isValidSaveBtn);
  }, [cardData]);

  // [점유율] 전체 총합 구하기
  useEffect(() => {
    // let newArr = [...options.filter((ele) => ele.checked), ...cropOptions]; // 선택된 경작지 옵션 + 작물 옵션
    let newArr = [...newCropOption.filter((ele) => ele.checked)]; // 선택된 경작지 옵션 + 작물 옵션
    let sum = 0;
    newArr.map((ele) => (sum += Number(ele.percent)));
    setTotalSum(sum);
  }, [options, newCropOption]);

  // [잔여량] 전체 총합 구하기
  useEffect(() => {
    if (Object.keys(form2)) {
      let sum = 0;
      Object.entries(form2).map(([key, value]) => (sum += Number(value)));
      setTotalSum2(sum);
    }
  }, [form2]);

  // 점유율 탭 -> 경지 종류 선택 [1차분류]
  const onChangeCheck = (e) => {
    const { type, id, name, value, checked, text } = e.target;
    // 라디오 버튼(한개만 checked)
    if (type === 'radio') {
      // [기타 항목들, 경작지] 옵션
      if (name === 'cropList') {
        setRadioValue(value);

        setNewCropOption((prev) => {
          return [...prev].map((ele) => (ele.value === value ? { ...ele, checked, percent: ele.text === '경작지' ? null : 100 } : { ...ele, checked: false, percent: null }));
        });
      }
    }
    // 체크박스 버튼
    if (type === 'checkbox') {
      setNewCropOption((prev) => {
        return [...prev].map((ele) => (ele.value === value ? { ...ele, checked, percent: !checked ? null : ele.percent } : ele));
      });
    }
  };

  // 퍼센트 입력창
  const onChangePercent = (e, optionType) => {
    const { name, value } = e.target;
    if (optionType === 'cropList') {
      setNewCropOption((prev) => {
        let newCrop = [...prev].map((ele) => (ele.text === name ? { ...ele, percent: value } : { ...ele }));
        return newCrop;
      });
    }
  };

  // 저장 버튼 이벤트 [점유율, 잔여량]
  const onClickSave = async () => {
    const activeCard = [...cardData].filter(({ active }) => active); // 선택된 카드들만 필터
    const surveyFarmMapId = activeCard?.[0]?.surveyFarmMapId; // 선택된 카드 id
    // 점유율 저장
    if (tabNum === 1) {
      // const totalOptions = [...options, ...cropOptions];
      const totalOptions = [...newCropOption];
      const activeOption = totalOptions?.filter(({ percent }) => percent);
      // 1) 항목별 퍼센트 값
      const saveSurveyCropFillRates = activeOption?.map((ele) => ({ surveyCropId: ele.surveyCropId, fillRate: Number(ele.percent) }));

      /* 일괄 점유율 저장 */
      if (activeCard?.length > 1) {
        const surveyFarmMapIds = activeCard?.map((ele) => ele.surveyFarmMapId);
        const surveyCropFillRateRequest = {
          saveSurveyCropFillRates,
          workerName: userInfo?.name,
        };
        await addFillRateMulti(
          {
            surveyFarmMapIds,
            surveyCropFillRateRequest,
          },
          {
            onSuccess(data: any, variables, context) {
              console.log(data);
              setConfirm({
                ...confirm,
                show: true,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                  onChangeFeatureStatus(
                    cardData.filter(({ active }) => active),
                    1,
                    false,
                  );
                  refetchSurveyLots(); // 필지 목록들 재요청
                  refetchSurveyData(); // 판독 정보 재요청
                },
                message: data?.message || '[점유율] 일괄 저장 완료',
              });
            },
            onError(error: any, variables, context) {
              console.log(error);
              const errMsg = error?.response?.data?.message;
              setConfirm({
                ...confirm,
                show: true,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                },
                message: errMsg || '[점유율] 일괄 저장 실패',
                isError: true,
              });
            },
          },
        );
      }
      /* 개별 점유율 저장 */
      if (activeCard?.length === 1) {
        await addFillRate(
          { surveyFarmMapId, saveSurveyCropFillRates, workerName: userInfo?.name },
          {
            onSuccess(data, variables, context) {
              console.log(data);
              setConfirm({
                ...confirm,
                show: true,
                rightText: '확인',
                rightFunc: () => {
                  onChangeFeatureStatus(
                    cardData.filter(({ active }) => active),
                    1,
                    false,
                  );
                  setConfirm({ ...confirm, show: false });
                  refetchSurveyLots(); // 필지 목록들 재요청
                  refetchSurveyData(); //판독 정보 재요청
                },
                message: '[점유율] 저장 완료',
              });
            },
            onError(error: any, variables, context) {
              console.log(error);
              const errMsg = error?.response?.data?.message;
              setConfirm({
                ...confirm,
                show: true,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                },
                message: errMsg || '[점유율] 저장 실패',
                isError: true,
              });
            },
          },
        );
      }
    }

    // 잔여량 저장
    if (tabNum === 2) {
      const activeOptions = Object.keys(form2)
        ?.filter((key) => form2[key])
        ?.map((ele) => ({ name: ele, value: form2[ele] }));

      let updateArr = thisSurveyCrops
        ?.map((ele) => {
          let matched = activeOptions?.find((item) => item.name === ele.displayName);
          if (matched) {
            return { ...ele, updateRemainRate: matched.value };
          } else {
            return null;
          }
        })
        ?.filter((item) => item !== null);

      const updateSurveyCropRemainRates = updateArr?.map((ele) => ({ surveyCropHistoryId: ele.surveyCropHistoryId, remainRate: ele.updateRemainRate }));
      /* 잔여량 저장 */
      await updateRemain(
        { surveyFarmMapId, updateSurveyCropRemainRates, workerName: userInfo?.name },
        {
          onSuccess(data, variables, context) {
            console.log(data);
            setConfirm({
              ...confirm,
              show: true,
              rightText: '확인',
              rightFunc: () => {
                onChangeFeatureStatus(
                  cardData.filter(({ active }) => active),
                  2,
                  false,
                );
                setConfirm({ ...confirm, show: false });
                refetchSurveyLots(); // 필지 목록들 재요청
                refetchSurveyData(); //판독 정보 재요청
                setForm2({}); //입력창 초기화
              },
              message: '[잔여량] 저장 완료',
            });
          },
          onError(error: any, variables, context) {
            console.log(error);
            const errMsg = error?.response?.data?.message;
            setConfirm({
              ...confirm,
              show: true,
              rightText: '확인',
              rightFunc: () => {
                setConfirm({ ...confirm, show: false });
              },
              message: errMsg || '[잔여량] 저장 실패',
              isError: true,
            });
          },
        },
      );
    }
  };

  // 이전회차 점유율 열기
  const onToggleBeforeRound = () => {
    setBeforeShow(!beforeShow);
  };

  //검수완료 버튼
  const onCompleteInspect = () => {
    setConfirm({
      ...confirm,
      show: true,
      message: '검수를 완료하시겠습니까?',
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: () => {
        setConfirm({ ...confirm, show: false });
        completeInspect(cardData?.filter(({ active }) => active)[0].surveyFarmMapId, {
          onSuccess: (res: any) => {
            onChangeFeatureStatus(
              cardData.filter(({ active }) => active),
              3,
              false,
            );
            refetchSurveyLots();
            //검수 완료 성공 얼럿
            setConfirm({
              ...confirm,
              show: true,
              message: res?.message || '저장되었습니다.',
              leftText: undefined,
              rightText: '확인',
              rightFunc: () => {
                setConfirm({ ...confirm, show: false });
              },
            });
          },
          onError: (err: any) => {
            //검수 완료 실패 얼럿
            setConfirm({ ...confirm, show: true, message: err?.response?.data?.message || '실패. 관리자에게 문의하세요', leftText: undefined, rightText: '확인', isError: true, rightFunc: () => setConfirm({ ...confirm, show: false }) });
          },
        });
      },
    });
  };

  // 경작지 항목 체크 유무
  const isCropChecked = options.find((ele) => ele.text === '경작지')?.checked;

  // 잔여량 탭버튼 disabled : 선택된 카드의 status 확인 후
  const isDisable = cardData?.filter(({ active }) => active)?.[0]?.status === 'NONE' || cardData?.filter(({ active }) => active)?.length > 1 || cardData?.filter(({ active }) => active)?.length === 0; // 미판독상태 | 복수선택 | 카드 없음

  // 현재 1회차 인지 체크
  const isRound1 = round.filter(({ active }) => active)[0]?.surveyNo === 1;
  // 이전 회차 숫자
  const activeSurveyNo = round[round.findIndex(({ active }) => active) - 1]?.surveyNo;

  // 검수완료 버튼 활성화
  const isInvalidInspect = surveyDatas?.[0]?.status === 'INSPECT';

  // 이전회차 점유율 영역 보여주기
  const isShowBeforeData = !isRound1 && sessionStorage?.getItem('auth') === 'ROLE_WORKER' && cardData?.filter(({ active }) => active)?.length === 1;

  return (
    <DataEnterAreaSt.Contain>
      {isShowBeforeData ? <DataEnterAreaSt.BeforeBtn type={'button'} onClick={onToggleBeforeRound} className={beforeShow ? 'active' : undefined}></DataEnterAreaSt.BeforeBtn> : null}
      <DataEnterAreaSt.Wrap className={hide ? 'hide' : undefined}>
        <DataEnterAreaSt.TabBtnWrap>
          <button className={`tabBtn ${tabNum === 1 && 'active'}`} onClick={() => setTabNum(1)}>
            {sessionStorage.getItem('auth') === 'ROLE_WORKER' ? '점유율' : '판독량'}
          </button>
          <button className={`tabBtn ${tabNum === 2 && 'active'}`} onClick={() => setTabNum(2)} disabled={isDisable}>
            잔여량
          </button>
        </DataEnterAreaSt.TabBtnWrap>
        <DataEnterAreaSt.ReadGrdTypeWrap>
          {/* 점유율 탭 */}
          {tabNum === 1 && (
            <>
              {/*판독자*/}
              {sessionStorage.getItem('auth') === 'ROLE_WORKER' ? (
                <ul className={'cScroll'} style={{ height: 'calc(100% - 139px)' }}>
                  {newCropOption.map((item, idx) => (
                    <li key={`radio-${idx}`} className="radioItem">
                      <>
                        <Checkbox
                          type={checkboxType}
                          id={`cropList-${item.value}`}
                          name="cropList" // 라디오끼리 같은 name
                          text={item.text}
                          value={item.value}
                          style={checkboxType === 'radio' ? { width: '100%', height: '100%' } : undefined}
                          checked={item?.checked}
                          onChange={onChangeCheck}
                        />
                        {/* 라디오일때 */}
                        {checkboxType === 'radio' && <p className={`percent ${item.checked && 'active'}`}>{item.percent || 0}%</p>}

                        {/* 입력일때 */}
                        {checkboxType !== 'radio' && (
                          <>
                            {thisSurveyCrops?.find(({ surveyCropId }) => surveyCropId === item?.surveyCropId) && <p className="value">{`당회차: ${thisSurveyCrops?.find(({ surveyCropId }) => surveyCropId === item?.surveyCropId)?.fillRate}`}</p>}

                            <Input width={102} height={36} unit="%" placeholder="입력" name={item.text} value={item.percent || ''} onChange={(e) => onChangePercent(e, 'cropList')} disabled={!item.checked} />
                          </>
                        )}
                      </>
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  {/*   검수자, 관리자 */}
                  <DataEnterAreaSt.FormWrap className="cScroll" style={{ height: '100%' }}>
                    <ul>
                      {thisSurveyCrops?.map((ele, idx) => (
                        <li className="formItem" key={'경작지Item' + idx}>
                          <div className="labelInput">
                            <p className="label">{ele?.displayName}</p>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                              {ele?.fillRate && (
                                <span className="value" style={{ color: ' #025143' }}>
                                  {ele?.fillRate} %
                                </span>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </DataEnterAreaSt.FormWrap>
                </>
              )}

              {/* 하단 최종 버튼 영역 */}
              {/*판독자*/}
              {sessionStorage.getItem('auth') === 'ROLE_WORKER' ? (
                <DataEnterAreaSt.SaveWrap>
                  <div className="totalWrap">
                    <div className="titleWrap">
                      <span className="ico"></span>
                      <p className="title">전체 총합</p>
                    </div>
                    <div className="valueWrap">
                      <p className="value">{totalSum}</p>
                      <span className="unit">{'%'}</span>
                    </div>
                  </div>

                  {fillRateDate && <p className="lastUpdate">{`최종변경 : ${fillRateDate}`}</p>}
                  <Button width={280} text={!isValidSaveBtn ? '판독 대상 이미지 없음' : '저장'} disabled={!isValidSaveBtn} onClick={onClickSave} />
                </DataEnterAreaSt.SaveWrap>
              ) : (
                /* 검수자, 관리자 */
                <DataEnterAreaSt.SaveWrap>
                  {inspectDate ? <p className="lastUpdate">{`검수완료 : ${inspectDate}`}</p> : isInvalidInspect && <p className="lastUpdate">{'이미 검수가 완료된 필지입니다.'}</p>}

                  {/* 관리자일때 다음필지 버튼 고정 */}
                  {sessionStorage.getItem('auth') === 'ROLE_ADMIN' ? (
                    <Button width={280} text={'다음 필지'} icon={IcoNextLot} $icoPosition="right" $iconSize={20} $iconSpacing={8} onClick={selectNextCard} />
                  ) : !isInvalidInspect ? (
                    /* 필지가 검수완료 상태가 아닐때 */
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button width={136} text={'건너뛰기'} onClick={selectNextCard} $buttonType="large" />
                      <Button width={136} text={'검수완료'} onClick={onCompleteInspect} />
                    </div>
                  ) : (
                    /* 필지가 검수완료 상태 */
                    <Button width={280} text={'다음 필지'} icon={IcoNextLot} $icoPosition="right" $iconSize={20} $iconSpacing={8} onClick={selectNextCard} />
                  )}
                </DataEnterAreaSt.SaveWrap>
              )}
            </>
          )}
          {/* 잔여량 탭 */}
          {tabNum === 2 && (
            <>
              <ul>
                <li className="radioItem">{'경작지'}</li>
              </ul>
              {/* 판독자 */}
              {sessionStorage.getItem('auth') === 'ROLE_WORKER' ? (
                <DataEnterAreaSt.FormWrap className="cScroll" $height={'calc(100% - 133px - 49px)'}>
                  <ul>
                    {thisSurveyCrops?.map(
                      (ele, idx) =>
                        ele?.remainUsage ? (
                          <li className="formItem" key={'경작지Item' + idx}>
                            <div className="labelInput">
                              <p className="label">{ele?.displayName}</p>

                              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                {ele?.remainRate && <span className="value">{ele?.remainRate}</span>}
                                <Input width={102} height={36} unit="%" name={ele?.displayName} value={form2?.[ele?.displayName] || ''} onChange={onChange2} max={ele?.fillRate} placeholder={`최대${ele?.fillRate}`} />
                              </div>
                            </div>
                          </li>
                        ) : null,
                      //  <div>{'항목이 없습니다'}</div>
                    )}
                  </ul>
                </DataEnterAreaSt.FormWrap>
              ) : (
                /* 검수자, 관리자 */
                <DataEnterAreaSt.FormWrap className="cScroll" style={{ height: '100%' }}>
                  <ul>
                    {thisSurveyCrops?.map((ele, idx) =>
                      ele?.remainUsage ? (
                        <li className="formItem" key={'경작지Item' + idx}>
                          <div className="labelInput">
                            <p className="label">{ele?.displayName}</p>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                              {ele?.remainRate && (
                                <span className="value" style={{ color: ' #025143' }}>
                                  {ele?.remainRate} %
                                </span>
                              )}
                            </div>
                          </div>
                        </li>
                      ) : null,
                    )}
                  </ul>
                </DataEnterAreaSt.FormWrap>
              )}
              {/* 판독자 */}
              {sessionStorage.getItem('auth') === 'ROLE_WORKER' ? (
                <DataEnterAreaSt.SaveWrap>
                  <div className="totalWrap">
                    <div className="titleWrap">
                      <span className="ico"></span>
                      <p className="title">전체 총합</p>
                    </div>
                    <div className="valueWrap">
                      <p className="value">{totalSum2}</p>
                      <span className="unit">{'%'}</span>
                    </div>
                  </div>
                  {remainDate && <p className="lastUpdate">{`최종변경 : ${remainDate}`}</p>}
                  <Button width={280} text="저장" onClick={onClickSave} />
                </DataEnterAreaSt.SaveWrap>
              ) : (
                /* 검수자, 관리자 */
                <DataEnterAreaSt.SaveWrap>
                  {inspectDate ? <p className="lastUpdate">{`검수완료 : ${inspectDate}`}</p> : isInvalidInspect && <p className="lastUpdate">{'이미 검수가 완료된 필지입니다.'}</p>}

                  {/* 관리자일때 다음필지 버튼 고정 */}
                  {sessionStorage.getItem('auth') === 'ROLE_ADMIN' ? (
                    <Button width={280} text={'다음 필지'} icon={IcoNextLot} $icoPosition="right" $iconSize={20} $iconSpacing={8} onClick={selectNextCard} />
                  ) : !isInvalidInspect ? (
                    /* 필지가 검수완료 상태가 아닐때 */
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button width={136} text={'건너뛰기'} onClick={selectNextCard} $buttonType="large" />
                      <Button width={136} text={'검수완료'} onClick={onCompleteInspect} />
                    </div>
                  ) : (
                    /* 필지가 검수완료 상태 */
                    <Button width={280} text={'다음 필지'} icon={IcoNextLot} $icoPosition="right" $iconSize={20} $iconSpacing={8} onClick={selectNextCard} />
                  )}
                </DataEnterAreaSt.SaveWrap>
              )}
            </>
          )}
        </DataEnterAreaSt.ReadGrdTypeWrap>

        {/* 이전회차 점유율 영역 (1회차가 아닐때 & 판독자 웹) */}
        {isShowBeforeData ? (
          <DataEnterAreaSt.BeforeRound className={beforeShow ? 'active' : undefined}>
            <DataEnterAreaSt.TabBtnWrap>
              <p className={'tabBtn active'} style={{ width: '100%', display: 'flex', gap: '6px' }}>
                <RoundBadge num={activeSurveyNo} /> <span>점유율</span>
              </p>
            </DataEnterAreaSt.TabBtnWrap>
            <DataEnterAreaSt.ReadGrdTypeWrap>
              <ul className="cScroll">
                {beforeSurveyCrops?.map((item, idx) => (
                  <li key={`radio-${idx}`} className="radioItem">
                    <p className="labelValue">
                      {item.displayName}
                      <span className="percent"> {item?.fillRate || 0} %</span>
                    </p>
                  </li>
                ))}
              </ul>

              <DataEnterAreaSt.SaveWrap style={{ marginBottom: fillRateDate ? 130 : 100 }}>
                <div className="totalWrap" style={{ margin: 0 }}>
                  <div className="titleWrap">
                    <span className="ico"></span>
                    <p className="title">전체 총합</p>
                  </div>
                  <div className="valueWrap">
                    <p className="value">{beforeTotalSum}</p>
                    <span className="unit">{'%'}</span>
                  </div>
                </div>
              </DataEnterAreaSt.SaveWrap>
            </DataEnterAreaSt.ReadGrdTypeWrap>
          </DataEnterAreaSt.BeforeRound>
        ) : null}
      </DataEnterAreaSt.Wrap>
    </DataEnterAreaSt.Contain>
  );
}

export default DataEnterArea;
