import React, { useEffect, useState } from 'react';
import TabContent, { NextStep, Section } from '../../../../components/atoms/TabContent';
import styled from 'styled-components';
import Select from '../../../../components/atoms/Select';
import HelpMessage from '../../../../components/atoms/HelpMessage';
import IcoHelp from '../../../../assets/images/etc/ico-help-message.svg';
import { LabelInputStyle } from '../../../../components/atoms/LabelInput';
import Button from '../../../../components/atoms/Button';
import IcoFileUpload from '../../../../assets/images/button/ico-file-upload.svg';
import ScrollTable from '../../../../components/atoms/ScrollTable';
import Checkbox from '../../../../components/atoms/Checkbox';
import TdEllipsis from '../../../../components/atoms/TdEllipsis';
import { PagingTableStyle } from '../../../../components/atoms/PagingTable';
import NoData from '../../../../components/atoms/NoData';
import IcoAddUser from '../../../../assets/images/noData/ico-user-add.svg';
import IcoStepNext from '../../../../assets/images/button/ico-step-next.svg';

import RoundStep2 from './RoundStep/RoundStep2';
import { useOutletContext } from 'react-router-dom';
import RoundStep3 from './RoundStep/RoundStep3';
import RoundStep1 from './RoundStep/RoundStep1';
import query from 'hooks/RQuery/utils';

const Content = {
  Wrap: styled.div`
    height: 100%;
  `,
  RoundRow: styled.div`
    display: flex;
    align-items: center;
    height: 56px;
    border: 1px solid #31c16e99;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 17px;
    background: #eff6e4;
    margin-bottom: 20px;
    > span {
      margin: 0 18px 0 10px;
      font-size: 16px;
      color: #001a21;
      font-weight: 600;
    }
  `,
  SubContWrap: styled.div`
    height: calc(100% - 76px);
  `,
};

// 사업장 상세 - 회차별 탭
const Step3 = () => {
  const { projectId } = useOutletContext<any>();

  const [subTabList, setSubTabList] = useState<any>([]);

  const [roundOption, setRoundOption] = useState<any[]>([]); // 회차 옵션
  // 사업의 회차 리스트 조회
  const { data: projRounds } = query.project.useGetRounds(projectId);
  const { surveyList, inProgressSurveyNo } = projRounds || {};

  // 회차 리스트 Option
  useEffect(() => {
    if (surveyList?.length > 0) {
      // 첫번째 항목 active
      setRoundOption(surveyList.map((ele, idx) => ({ text: ele.surveyNo, value: ele.surveyId, active: idx === 0 })));
    } else {
      setRoundOption([]);
    }
  }, [surveyList]);

  useEffect(() => {
    if (roundOption?.findIndex(({ active }) => active) === 0) {
      setSubTabList([
        { name: '작물 정보', pass: true, active: true },
        { name: '혼작 정보', pass: true, active: false },
      ]);
    } else {
      setSubTabList([
        { name: '필지 정보', pass: true, active: true },
        { name: '작물 정보', pass: true, active: false },
        { name: '혼작 정보', pass: true, active: false },
      ]);
    }
  }, [roundOption]);
  // 회차 선택
  const onChangeRound = (e) => {
    const { ariaValueText } = e.target;

    setRoundOption((prev) => [...prev].map((ele) => ({ ...ele, active: ele.value === ariaValueText })));
  };

  // 선택된 회차 id
  const surveyId = roundOption?.find(({ active }) => active)?.value;

  const beforeSurveyIndex = roundOption?.findIndex(({ active }) => active);

  // 선택된 회차의 이전 회차 id(필지 추가용)
  const beforeSurveyId = roundOption[beforeSurveyIndex - 1]?.value;

  return (
    <Content.Wrap>
      <Content.RoundRow style={{ width: '1272px' }}>
        <Select option={roundOption} onChange={onChangeRound} $borderColor={'#E5E5EC'} width={112} height={40} />
        <span>회차</span>
        <HelpMessage message={'등록할 <b>[회차]</b>를 선택 후 정보를 등록해주세요.'} icon={IcoHelp} />
      </Content.RoundRow>
      <Content.SubContWrap style={{ width: '1272px' }}>
        {subTabList.length ? (
          <TabContent
            tabList={subTabList}
            setTabList={setSubTabList}
            type={3}
            height={'calc(100% - 65px)'}
            content={
              <>
                {roundOption?.findIndex(({ active }) => active) === 0 ? (
                  <>
                    {subTabList[0]?.active ? <RoundStep2 surveyId={surveyId} /> : null}
                    {subTabList[1]?.active ? <RoundStep3 surveyId={surveyId} /> : null}
                  </>
                ) : (
                  <>
                    {subTabList[0]?.active ? <RoundStep1 surveyId={surveyId} surveyList={surveyList} /> : null}
                    {subTabList[1]?.active ? <RoundStep2 surveyId={surveyId} /> : null}
                    {subTabList[2]?.active ? <RoundStep3 surveyId={surveyId} /> : null}
                  </>
                )}
              </>
            }
          />
        ) : null}
      </Content.SubContWrap>
    </Content.Wrap>
  );
};

export default Step3;
