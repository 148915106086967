import * as map from './map';
import * as address from './address';
import * as image from './image';
import * as project from './project';
import * as client from './client';
import * as member from './member';
import * as farmMap from './farmMap';
import * as survey from './survey';

const query = {
  map,
  address,
  image,
  project,
  client,
  member,
  farmMap,
  survey,
};
export default query;
