import Style from 'ol/style/Style';
import { FeatureLike } from 'ol/Feature';
import { Circle, Fill, Icon, Stroke } from 'ol/style.js';
import IcoPolygonImage from '../../assets/images/map/ico-polygon-image.svg';
import IcoPolygonImageA from '../../assets/images/map/ico-polygon-image-active.svg';
import { RegularShape, Text } from 'ol/style';
import { Point, MultiPoint } from 'ol/geom';

//선택 여부에 따른 사진 페어수 텍스트 위치 offset 조정
const interiorGeometry = (type: 'active' | 'default', feature, resolution) => {
  const interiorPoint = (feature.getGeometry() as any).getInteriorPoints();
  const point = new MultiPoint(interiorPoint.getCoordinates());
  point.translate(24 * resolution, (type === 'active' ? 26 : 16) * resolution);
  return point;
};
const interiorGeometry2 = (type: 'active' | 'default', feature, resolution) => {
  const interiorPoint = (feature.getGeometry() as any).getInteriorPoints();
  const point = new MultiPoint(interiorPoint.getCoordinates());
  point.translate(-24 * resolution, (type === 'active' ? 26 : 16) * resolution);
  return point;
};

//기본 상태 테두리
const defaultStroke = (step, isImageUpload) => {
  switch (step) {
    case 'NONE':
      if (isImageUpload) {
        return 'rgba(49,193,110,0.6)';
      } else {
        return 'rgba(253, 216, 53, 0.6)';
      }
    case 'FILL_RATE':
      return 'rgba(51, 170, 255, 0.6)';
    case 'REMAIN_RATE':
      return 'rgba(113, 81, 242, 0.60)';
    case 'INSPECT':
      return 'rgba(255, 103, 30, 0.6)';
  }
};

//기본 상태 배경
const defaultFill = (step, isImageUpload) => {
  switch (step) {
    case 'NONE':
      if (isImageUpload) {
        return 'rgba(204,247,216,0.4)';
      } else {
        return 'rgba(255, 245, 157, 0.4)';
      }
    case 'FILL_RATE':
      return 'rgba(51, 170, 255, 0.4)';
    case 'REMAIN_RATE':
      return 'rgba(113, 81, 242, 0.4)';
    case 'INSPECT':
      return 'rgba(255, 140, 84, 0.4)';
  }
};

//활성 상태 테두리
const activeStroke = (step, isImageUpload) => {
  switch (step) {
    case 'NONE':
      if (isImageUpload) {
        return 'rgba(49,193,110,0.6)';
      } else {
        return 'rgba(253, 216, 53, 0.6)';
      }
    case 'FILL_RATE':
      return 'rgba(51, 170, 255, 0.6)';
    case 'REMAIN_RATE':
      return '#7151F2';
    case 'INSPECT':
      return 'rgba(255, 140, 84, 0.6)';
  }
};

//활성 상태 배경
const activeFill = (step, isImageUpload) => {
  switch (step) {
    case 'NONE':
      if (isImageUpload) {
        return 'rgba(49, 193, 110, 0.6)';
      } else {
        return 'rgba(253, 216, 53, 0.6)';
      }
    case 'FILL_RATE':
      return 'rgba(51, 170, 255, 0.6)';
    case 'REMAIN_RATE':
      return 'rgba(113, 81, 242, 0.60)';
    case 'INSPECT':
      return 'rgba(255, 140, 84, 0.6)';
  }
};

//필지 스타일
export const FARM_STYLE = {
  DEFAULT: (feature: FeatureLike, resolution: number, zoom: number, isPopup?: boolean) => {
    //폴리곤의 중심(extent의 중심이 아닌 텍스트를 표시하기 위한 별도의 포인트)
    const interiorPoint = (feature.getGeometry() as any).getInteriorPoints();
    const step = feature.getProperties().property.status;
    const wCount = isPopup ? feature.getProperties().property.imageList.wideImageCount : feature.getProperties().property.wideImageCount;
    const zCount = isPopup ? feature.getProperties().property.imageList.zoomImageCount : feature.getProperties().property.zoomImageCount;
    //기본 스타일
    const styles = [
      //테두리, 폴리곤 배경
      new Style({
        stroke: new Stroke({
          width: 6,
          color: defaultStroke(step, !!wCount && !!zCount),
        }),
        fill: new Fill({
          color: defaultFill(step, !!wCount && !!zCount),
        }),
      }),
    ];

    //이미지 추가
    //아이콘
    const icon = new Style({
      image: new Icon({
        src: IcoPolygonImage,
        scale: 1,
        anchor: [14, 17],
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
      }),
      geometry: function () {
        return new MultiPoint(interiorPoint.getCoordinates());
      },
    });
    //w 이미지수
    const image = new Style({
      text: new Text({
        text: `W | ${String(wCount)}`,
        font: '600 12px Pretendard',
        fill: new Fill({ color: '#fff' }),
        backgroundFill: new Fill({ color: wCount > 1 ? '#FF7600' : '#024751' }),
        padding: [2, 4, 0, 4],
      }),
      geometry: interiorGeometry('default', feature, resolution),
    });
    //z 이미지수
    const image2 = new Style({
      text: new Text({
        text: `Z | ${String(zCount)}`,
        font: '600 12px Pretendard',
        fill: new Fill({ color: '#fff' }),
        backgroundFill: new Fill({ color: zCount > 1 ? '#FF7600' : '#024751' }),
        padding: [2, 4, 0, 4],
      }),
      geometry: interiorGeometry2('default', feature, resolution),
    });

    //이미지 있으면 아이콘과 갯수 표시
    if (wCount > 0 || zCount > 0) {
      if (wCount === 1 && zCount === 1) {
        return styles;
      } else {
        const newStyle = [...styles];
        newStyle.push(icon);
        newStyle.push(image);
        newStyle.push(image2);
        return newStyle;
      }
    } else {
      return styles;
    }
  },
  ACTIVE: (feature: FeatureLike, resolution: number, zoom: number, isPopup?: boolean) => {
    //폴리곤의 중심(extent의 중심이 아닌 텍스트를 표시하기 위한 별도의 포인트)
    const interiorPoint = (feature.getGeometry() as any).getInteriorPoints();

    const step = feature.getProperties().property.status;
    const wCount = isPopup ? feature.getProperties().property.imageList.wideImageCount : feature.getProperties().property.wideImageCount;
    const zCount = isPopup ? feature.getProperties().property.imageList.zoomImageCount : feature.getProperties().property.zoomImageCount;

    //기본 스타일
    const styles = [
      //테두리, 폴리곤 배경
      new Style({
        stroke: new Stroke({
          width: 6,
          color: activeStroke(step, !!wCount && !!zCount),
        }),
        fill: new Fill({
          color: activeFill(step, !!wCount && !!zCount),
        }),
      }),

      //테두리2
      new Style({
        stroke: new Stroke({
          width: 2,
          color: '#fff',
        }),
      }),
    ];

    //이미지 페어가 있을 때 추가
    //아이콘
    const icon = new Style({
      image: new Icon({
        src: IcoPolygonImageA,
        scale: 1,
        anchor: [14, 26],
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
      }),
      geometry: function () {
        return new Point(interiorPoint.getCoordinates()[0]);
      },
    });
    //w 이미지수
    const image = new Style({
      text: new Text({
        text: `W | ${String(wCount)}`,
        font: '600 12px Pretendard',
        fill: new Fill({ color: '#fff' }),
        backgroundFill: new Fill({ color: wCount > 1 ? '#FF7600' : '#024751' }),
        padding: [2, 4, 0, 4],
      }),
      geometry: interiorGeometry('active', feature, resolution),
    });
    //z 이미지수
    const image2 = new Style({
      text: new Text({
        text: `Z | ${String(zCount)}`,
        font: '600 12px Pretendard',
        fill: new Fill({ color: '#fff' }),
        backgroundFill: new Fill({ color: zCount > 1 ? '#FF7600' : '#024751' }),
        padding: [2, 4, 0, 4],
      }),
      geometry: interiorGeometry2('active', feature, resolution),
    });

    //이미지 있으면 아이콘과 갯수 표시
    if (wCount > 0 || zCount > 0) {
      if (wCount === 1 && zCount === 1) {
        return styles;
      } else {
        const newStyle = [...styles];
        newStyle.push(icon);
        newStyle.push(image);
        newStyle.push(image2);
        return newStyle;
      }
    } else {
      return styles;
    }
  },
};
