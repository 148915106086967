import React, { useEffect, useState } from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import Checkbox from '../components/atoms/Checkbox';
import HelpMessage from '../components/atoms/HelpMessage';
import IcoHelp from '../assets/images/etc/ico-help-message.svg';
import ScrollTable, { ScrollTableStyle } from '../components/atoms/ScrollTable';
import query from '../hooks/RQuery/utils';

const Content = {
  Inner: styled.div`
    padding: 24px 0;
  `,

  Box: styled.div`
    border: 1px solid #e7eaee;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 12px;
    margin-top: 20px;
    ${ScrollTableStyle.Contain} {
      border-radius: 4px !important;
    }
    ${ScrollTableStyle.Thead} {
      tr {
        th {
          height: 40px;
          color: #001a21;
          font-size: 16px;
          font-weight: 400;
          &:first-child {
            border-radius: 4px 0 0 0;
          }
          &:last-child {
            border-radius: 0 4px 0 0;
          }
        }
      }
    }
    ${ScrollTableStyle.Tbody} {
      tr {
        td {
          height: 40px;
        }
      }
    }
  `,
};

const PopupReportDownload = ({ onClose, projectId }) => {
  const [isAllCheck, setIsAllCheck] = useState(false);
  const [rounds, setRounds] = useState<any>([]);
  const projects = query.project.useGetRounds(projectId);
  useEffect(() => {
    if (projects.data) {
      const newArr: any = [];
      projects.data.surveyList.map((i) => {
        newArr.push({ ...i, checked: false });
      });
      setRounds(newArr);
    }
  }, [projects.data]);
  //전체 선택/해제
  const onCheckAll = () => {
    const newArr = [...rounds];
    if (newArr.length === newArr.filter(({ checked }) => checked).length) {
      newArr.map((i) => {
        i.checked = false;
      });
    } else {
      newArr.map((i) => {
        i.checked = true;
      });
    }
    setRounds(newArr);
  };

  //체크박스 클릭
  const onCheck = (surveyId) => {
    const newArr = [...rounds];
    newArr.find((i) => i.surveyId === surveyId)!.checked = !newArr.find((i) => i.surveyId === surveyId)?.checked;
    setRounds(newArr);
  };

  //체크된수 === 전체 수 같은지 확인 후 전체체크박스 체크선택/해제
  useEffect(() => {
    if (rounds.filter(({ checked }) => checked).length === rounds.length) {
      setIsAllCheck(true);
    } else {
      setIsAllCheck(false);
    }
  }, [rounds.filter(({ checked }) => checked).length]);

  return (
    <PopupTemplate
      title={'보고서 다운로드'}
      width={280}
      isDimmed={true}
      onClose={onClose}
      footer={<Button width={'full'} text={`다운로드`} onClick={() => {}} disabled={!rounds.filter(({ checked }) => checked).length} />}
      content={
        <Content.Inner>
          <HelpMessage message={'다운로드 받을 <b>[회차]</b>를 선택하세요.'} icon={IcoHelp} />
          <Content.Box>
            <ScrollTable
              caption={'회차별 다운로드'}
              colgroup={[50, 'auto']}
              thead={
                <tr>
                  <th scope={'col'}>
                    <Checkbox id={'reportAll'} onChange={onCheckAll} checked={isAllCheck} />
                  </th>
                  <th scope={'col'}>전체</th>
                </tr>
              }
              tbody={
                <>
                  {rounds?.map((i) => {
                    return (
                      <tr>
                        <td>
                          <Checkbox id={'check_' + i.surveyNo} onChange={() => onCheck(i.surveyId)} checked={i.checked} />
                        </td>
                        <td>{i.surveyNo + '회차'}</td>
                      </tr>
                    );
                  })}
                </>
              }
            />
          </Content.Box>
        </Content.Inner>
      }
    />
  );
};

export default PopupReportDownload;
