import React, { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

const HelpMessageStyle = styled.div<{ icon?: string; position?: { left?: number; top?: number } }>`
  margin-left: ${(p) => (p.position?.left ? p.position.left + 'px' : 0)};
  margin-top: ${(p) => (p.position?.top ? p.position.top + 'px' : 0)};
  display: flex;
  p {
    color: #7e8888;
    font-size: 14px;
    font-weight: 400;
    b {
      color: #00b074;
      font-weight: 500;
      margin-right: 3px;
    }
    a {
      color: #00b074;
      text-decoration: underline;
    }
  }
  ${(p) =>
    p.icon &&
    `
      &:before{content:'';width:18px;height:18px;background:url(${p.icon})no-repeat center;margin-right:4px;margin-top:1px;}
    `}
`;
const HelpMessage = ({ style, message, icon, position }: { style?: CSSProperties; message?: any; icon?: string; position?: { left?: number; top?: number } }) => {
  return (
    <HelpMessageStyle icon={icon} position={position}>
      <p dangerouslySetInnerHTML={{ __html: message }} style={style}></p>
    </HelpMessageStyle>
  );
};

export default HelpMessage;
