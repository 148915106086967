import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import IcoClose from 'assets/images/popupLarge/ico-x-btn.svg';
import IcoCloseA from 'assets/images/popupLarge/ico-x-btn-active.svg';
import SwiperArea from 'components/atoms/SwiperArea';
import { SwiperSlide } from 'swiper/react';
import ImageWLarge from 'assets/images/image-w-large.JPG';

import IcoNaviPrev from 'assets/images/popupLarge/ico-nav-prev.svg';
import IcoNaviPrevA from 'assets/images/popupLarge/ico-nav-prev-active.svg';
import { useOutsideClick } from 'util/useOutsideClick';
import { useRecoilState } from 'recoil';
import { popupLargeImgState } from 'store/Atoms';

const PopupLargeImageSt = {
  Dimmed: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background: rgba(0, 0, 0, 0.6);
  `,
  Close: styled.button`
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: 12;
    width: 40px;
    height: 40px;
    background: url(${IcoClose}) no-repeat center;
    &:hover {
      transition: all 0.4s;
      background-image: url(${IcoCloseA});
    }
  `,
  Wrap: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 12;
  `,
  Header: styled.div`
    margin-bottom: 12px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
    .titleWrap {
      display: flex;
      align-items: center;
      gap: 6px;
      .title {
        color: #fff;
      }
      .divider {
        display: inline-block;
        width: 2px;
        height: 15px;
        background-color: #ffffffb2;
      }
      .text {
        color: #fff;
      }
    }
    #swiper-pagination {
      width: fit-content;
      color: #ffffffb2;
      .swiper-pagination-current {
        color: #fff;
      }
    }
  `,
  Container: styled.div`
    overflow: hidden;
    width: 860px;
    height: 645px;
    background-color: #fff;
    border: 1px solid #000000;
    border-radius: 8px;
    cursor: grab;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      /* object-fit: contain; */
      transition: transform 0.2s ease;
    }
  `,
  Navi: styled.div`
    width: calc(860px + 48px + 140px);
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 12;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btnSwiperPrev,
    .btnSwiperNext {
      width: 70px;
      height: 70px;
      background: url(${IcoNaviPrev}) no-repeat center;
      &:hover {
        transition: all 0.4s;
        background-image: url(${IcoNaviPrevA});
      }
      &.swiper-button-disabled {
        background-image: url(${IcoNaviPrev});
        opacity: 0.4;
        cursor: default;
      }
    }
    .btnSwiperNext {
      transform: rotate(180deg);
    }
  `,
};

function PopupLargeImage({ close }) {
  const [popLarge, setPopLarge] = useRecoilState(popupLargeImgState);
  console.log('imgArr', popLarge?.imgArr);
  const imgContainer = useRef(null);
  const [activeIdx, setActiveIdx] = useState(0);

  useOutsideClick(close, imgContainer); // 크게보기 팝업 닫기

  useEffect(() => {
    let scale = 1;
    let isDragging = false;
    let startX,
      startY,
      currentX = 0,
      currentY = 0;

    // 컨테이너
    const container: any = document.getElementById('imageContainer');
    container.addEventListener('mouseleave', () => {
      isDragging = false;
    });

    // 이미지의 위치를 컨테이너 안에 제한하는 함수
    function limitImagePosition(img) {
      const containerRect = container.getBoundingClientRect();
      // 중요) 확대된 이미지의 실제 크기 계산
      //   const imgRect = img.getBoundingClientRect(); // 실제 이미지보다 크게 잡히는 문제 ! scale(2)면 1720인데 1934.Xx...
      //   const imgWidth = imgRect.width;
      //   const imgHeight = imgRect.height;
      const imgWidth = containerRect.width * scale; // img크기는 container랑 동일하게 설정했다는 가정(css)
      const imgHeight = containerRect.height * scale;

      // 이미지 스케일이 1일때 바로 0처리
      if (scale === 1) {
        currentX = 0;
        currentY = 0;
      } else {
        // x축 경계 처리 (왼쪽 및 오른쪽)
        const maxX = (imgWidth - containerRect.width) / 2 / scale - 1; // 1px만큼 갭이 생겨서 빼줌
        const minX = -maxX;

        if (imgWidth > containerRect.width) {
          currentX = Math.max(minX, Math.min(currentX, maxX));
        } else {
          currentX = 0;
        }

        // y축 경계 처리 (위쪽 및 아래쪽)
        const maxY = (imgHeight - containerRect.height) / 2 / scale - 1; // 1px만큼 갭이 생겨서 빼줌
        const minY = -maxY;

        if (imgHeight > containerRect.height) {
          currentY = Math.max(minY, Math.min(currentY, maxY));
        } else {
          currentY = 0;
        }
      }

      console.log('scale', scale);
      console.log('currentX', currentX);
      console.log('currentY', currentY);

      img.style.transformOrigin = `center center`; // 이미지 중심 기준 (left top 등)
      img.style.transform = `scale(${scale}) translate(${currentX}px, ${currentY}px)`;
    }

    function wheelEvent(event) {
      event.preventDefault();

      const zoomSpeed = 0.1;
      if (event.deltaY < 0) {
        scale += zoomSpeed;
      } else {
        scale = Math.max(1, scale - zoomSpeed); // 최소 축소 크기는 1
      }
      // event.target.style.transformOrigin = `center center`; // 이미지 중심 기준 (left top 등)
      // 확대 시 이미지 경계를 체크하고 조정
      limitImagePosition(event.target);
    }

    // 드래그 시작
    function startDrag(event) {
      event.preventDefault();
      container.style.cursor = 'grabbing';
      isDragging = true;
      startX = event.clientX - currentX;
      startY = event.clientY - currentY;
    }

    // 드래그 중
    function duringDrag(event) {
      event.preventDefault();

      if (isDragging) {
        currentX = event.clientX - startX;
        currentY = event.clientY - startY;

        limitImagePosition(event.target);
      }
    }

    // 드래그 종료
    function stopDrag() {
      container.style.cursor = 'grab';
      isDragging = false;
    }

    // 이미지 확대/축소 및 드래그 기능 추가
    function addZoomAndDrag(img) {
      img.addEventListener('wheel', wheelEvent);
      img.addEventListener('mousedown', startDrag);
      img.addEventListener('mousemove', duringDrag);
      img.addEventListener('mouseup', stopDrag);
    }

    // 이미지 확대/축소 및 드래그 기능 제거
    function removeZoomAndDrag(img) {
      img.removeEventListener('wheel', wheelEvent);
      img.removeEventListener('mousedown', startDrag);
      img.removeEventListener('mousemove', duringDrag);
      img.removeEventListener('mouseup', stopDrag);
      img.style.transform = `scale(1) translate(0px, 0px)`; // 이미지 원위치
    }

    // 슬라이드 변경시..
    const imgs: any = document.getElementsByClassName('zoomableImage');
    for (let i = 0; i < imgs.length; i++) {
      if (activeIdx === i) {
        addZoomAndDrag(imgs[i]);
      } else {
        removeZoomAndDrag(imgs[i]); // 안보이는 슬라이드 초기화
      }
    }
  }, [activeIdx]);

  //   useEffect(() => {
  //     let scale = 1;
  //     let isDragging = false;
  //     let startX,
  //       startY,
  //       currentX = 0,
  //       currentY = 0;

  //     const container: any = document.getElementById('imageContainer');
  //     const imgs: any = document.getElementsByClassName('zoomableImage');
  //     //  console.log(imgs);
  //     let img = imgs[activeIdx];

  //     const wheelEvent = (event) => {
  //       event.preventDefault();

  //       const zoomSpeed = 0.1;

  //       if (event.deltaY < 0) {
  //         scale += zoomSpeed;
  //       } else {
  //         scale = Math.max(1, scale - zoomSpeed); // 최소 축소 크기는 1
  //       }

  //       img.style.transformOrigin = `center center`; // 이미지 중심 기준 (left top 등)
  //       // img.style.transform = `scale(${scale}) translate(${currentX}px, ${currentY}px)`;
  //       // 확대 시 이미지 경계를 체크하고 조정
  //       limitImagePosition(img);
  //     };

  //     /* 초기화 구문 */
  //     for (let i = 0; i < imgs.length; i++) {
  //       let img = imgs[i];
  //       console.log('remove', img);
  //       img.removeEventListener('wheel', wheelEvent);
  //       img.style.transformOrigin = `center center`; // 이미지 중심 기준 (left top 등)
  //       img.style.transform = `scale(1) translate(0px, 0px)`; // 이전 activeIdx
  //     }

  //     console.log('add', img);
  //     img.addEventListener('wheel', wheelEvent);

  //     // 이미지 드래그 기능
  //     container.addEventListener('mousedown', function (event) {
  //       event.preventDefault();
  //       isDragging = true;
  //       container.style.cursor = 'grabbing';
  //       startX = event.clientX - currentX;
  //       startY = event.clientY - currentY;
  //     });

  //     container.addEventListener('mouseup', function (event) {
  //       isDragging = false;
  //       container.style.cursor = 'grab';
  //     });

  //     container.addEventListener('mousemove', (event) => {
  //       if (isDragging) {
  //         currentX = event.clientX - startX;
  //         currentY = event.clientY - startY;
  //         //   console.log(isDragging, currentX, currentY);

  //         limitImagePosition(img);
  //       }
  //     });

  //     // 이미지의 위치를 컨테이너 안에 제한하는 함수
  //     function limitImagePosition(img) {
  //       const containerRect = container.getBoundingClientRect();
  //       const imgRect = img.getBoundingClientRect();

  //       // 확대된 이미지의 실제 크기 계산
  //       const imgWidth = imgRect.width;
  //       const imgHeight = imgRect.height;

  //       // x축 경계 처리 (왼쪽 및 오른쪽)
  //       const maxX = (imgWidth - containerRect.width) / 2 / scale;
  //       const minX = -maxX;

  //       currentX = Math.max(minX, Math.min(currentX, maxX));

  //       // y축 경계 처리 (위쪽 및 아래쪽)
  //       const maxY = (imgHeight - containerRect.height) / 2 / scale;
  //       const minY = -maxY;

  //       currentY = Math.max(minY, Math.min(currentY, maxY));

  //       // console.log(imgRect);
  //       // console.log(scale, currentX, currentY);

  //       img.style.transform = `scale(${scale}) translate(${currentX}px, ${currentY}px)`;
  //     }
  //   }, [activeIdx]);

  // for문으로 다 돌기
  //   useEffect(() => {
  //     const imgs: any = document.getElementsByClassName('zoomableImage');
  //     console.log(imgs);

  //     //  const img: any = document.getElementById('zoomableImage');
  //     const container: any = document.getElementById('imageContainer');

  //     for (let i = 0; i < imgs.length; i++) {
  //       let scale = 1;
  //       let isDragging = false;
  //       let startX,
  //         startY,
  //         currentX = 0,
  //         currentY = 0;
  //       let img = imgs[i];

  //       img.addEventListener('wheel', function (event) {
  //         event.preventDefault();

  //         const zoomSpeed = 0.1;
  //         // const imgRect = img.getBoundingClientRect();
  //         // const offsetX = (event.clientX - imgRect.left) / imgRect.width;
  //         // const offsetY = (event.clientY - imgRect.top) / imgRect.height;

  //         if (event.deltaY < 0) {
  //           scale += zoomSpeed;
  //         } else {
  //           scale = Math.max(1, scale - zoomSpeed); // 최소 축소 크기는 1
  //         }

  //         img.style.transformOrigin = `center center`; // 이미지 중심 기준 (left top 등)

  //         // 확대 시 이미지 경계를 체크하고 조정
  //         limitImagePosition(img);
  //       });

  //       // 이미지 드래그 기능
  //       container.addEventListener('mousedown', function (event) {
  //         event.preventDefault();

  //         isDragging = true;
  //         container.style.cursor = 'grabbing';
  //         startX = event.clientX - currentX;
  //         startY = event.clientY - currentY;
  //       });

  //       document.addEventListener('mouseup', function () {
  //         isDragging = false;
  //         container.style.cursor = 'grab';
  //       });

  //       document.addEventListener('mousemove', function (event) {
  //         if (isDragging) {
  //           currentX = event.clientX - startX;
  //           currentY = event.clientY - startY;

  //           limitImagePosition(img);
  //         }
  //       });
  //       // 이미지의 위치를 컨테이너 안에 제한하는 함수
  //       function limitImagePosition(img) {
  //         const containerRect = container.getBoundingClientRect();
  //         const imgRect = img.getBoundingClientRect();

  //         // 확대된 이미지의 실제 크기 계산
  //         const imgWidth = imgRect.width;
  //         const imgHeight = imgRect.height;

  //         // x축 경계 처리 (왼쪽 및 오른쪽)
  //         const maxX = (imgWidth - containerRect.width) / 2 / scale;
  //         const minX = -maxX;

  //         currentX = Math.max(minX, Math.min(currentX, maxX));

  //         // y축 경계 처리 (위쪽 및 아래쪽)
  //         const maxY = (imgHeight - containerRect.height) / 2 / scale;
  //         const minY = -maxY;

  //         currentY = Math.max(minY, Math.min(currentY, maxY));

  //         img.style.transform = `scale(${scale}) translate(${currentX}px, ${currentY}px)`;
  //       }
  //     }
  //   }, []);

  //   // 기존 기본
  //   useEffect(() => {
  //    let scale = 1;
  //    let isDragging = false;
  //    let startX,
  //      startY,
  //      currentX = 0,
  //      currentY = 0;

  //        const img: any = document.getElementById('zoomableImage');
  //     const container: any = document.getElementById('imageContainer');

  //    img.addEventListener('wheel', function (event) {
  //       event.preventDefault();

  //       const zoomSpeed = 0.1;
  //       // const imgRect = img.getBoundingClientRect();
  //       // const offsetX = (event.clientX - imgRect.left) / imgRect.width;
  //       // const offsetY = (event.clientY - imgRect.top) / imgRect.height;

  //       if (event.deltaY < 0) {
  //         scale += zoomSpeed;
  //       } else {
  //         scale = Math.max(1, scale - zoomSpeed); // 최소 축소 크기는 1
  //       }

  //       img.style.transformOrigin = `center center`; // 이미지 중심 기준 (left top 등)

  //       // 확대 시 이미지 경계를 체크하고 조정
  //       limitImagePosition();
  //     });

  //     // 이미지 드래그 기능
  //     container.addEventListener('mousedown', function (event) {
  //       event.preventDefault();

  //       isDragging = true;
  //       container.style.cursor = 'grabbing';
  //       startX = event.clientX - currentX;
  //       startY = event.clientY - currentY;
  //     });

  //     document.addEventListener('mouseup', function () {
  //       isDragging = false;
  //       container.style.cursor = 'grab';
  //     });

  //     document.addEventListener('mousemove', function (event) {
  //       if (isDragging) {
  //         currentX = event.clientX - startX;
  //         currentY = event.clientY - startY;

  //         limitImagePosition();
  //       }
  //     });

  //      img.addEventListener('wheel', function (event) {
  //        event.preventDefault();

  //        const zoomSpeed = 0.1;
  //        // const imgRect = img.getBoundingClientRect();
  //        // const offsetX = (event.clientX - imgRect.left) / imgRect.width;
  //        // const offsetY = (event.clientY - imgRect.top) / imgRect.height;

  //        if (event.deltaY < 0) {
  //          scale += zoomSpeed;
  //        } else {
  //          scale = Math.max(1, scale - zoomSpeed); // 최소 축소 크기는 1
  //        }

  //        img.style.transformOrigin = `center center`; // 이미지 중심 기준 (left top 등)

  //        // 확대 시 이미지 경계를 체크하고 조정
  //        limitImagePosition();
  //      });

  //    // 이미지의 위치를 컨테이너 안에 제한하는 함수
  //      function limitImagePosition() {
  //        const containerRect = container.getBoundingClientRect();
  //        const imgRect = img.getBoundingClientRect();

  //        // 확대된 이미지의 실제 크기 계산
  //        const imgWidth = imgRect.width;
  //        const imgHeight = imgRect.height;

  //        // x축 경계 처리 (왼쪽 및 오른쪽)
  //        const maxX = (imgWidth - containerRect.width) / 2 / scale;
  //        const minX = -maxX;

  //        currentX = Math.max(minX, Math.min(currentX, maxX));

  //        // y축 경계 처리 (위쪽 및 아래쪽)
  //        const maxY = (imgHeight - containerRect.height) / 2 / scale;
  //        const minY = -maxY;

  //        currentY = Math.max(minY, Math.min(currentY, maxY));

  //        img.style.transform = `scale(${scale}) translate(${currentX}px, ${currentY}px)`;
  //      }
  //   }, []);

  const onChangeSlide = (swiper) => {
    console.log(swiper);

    setActiveIdx(swiper?.activeIndex);
  };

  const imgArrLength = popLarge?.imgArr?.length || 0;

  return (
    <>
      <PopupLargeImageSt.Dimmed></PopupLargeImageSt.Dimmed>
      <PopupLargeImageSt.Close onClick={close}></PopupLargeImageSt.Close>
      <div ref={imgContainer}>
        {imgArrLength > 1 ? (
          <PopupLargeImageSt.Navi>
            <button type={'button'} id={'btnSwiperPrev'} className={'btnSwiperPrev'}></button>
            <button type={'button'} id={'btnSwiperNext'} className={'btnSwiperNext'}></button>
          </PopupLargeImageSt.Navi>
        ) : null}

        <PopupLargeImageSt.Wrap>
          <PopupLargeImageSt.Header>
            <div className="titleWrap">
              <p className="title">팜맵 ID</p>
              <span className="divider"></span>
              <p className="text">{popLarge?.mapId}</p>
            </div>

            <div id={'swiper-pagination'} className="sliderNum"></div>
          </PopupLargeImageSt.Header>

          {/* <PopupLargeImageSt.Container id="imageContainer">
          <img id="zoomableImage" src="https://via.placeholder.com/860x645" alt="ZoomableImage"></img>
        </PopupLargeImageSt.Container> */}

          <PopupLargeImageSt.Container id="imageContainer">
            <SwiperArea initialSlide={popLarge?.startIndex || 0} perView={1} onChangeSlide={onChangeSlide}>
              {popLarge?.imgArr?.map((item) => (
                <SwiperSlide>
                  <img className="zoomableImage" src={item.origin} alt="ZoomableImage"></img>
                </SwiperSlide>
              ))}
            </SwiperArea>
          </PopupLargeImageSt.Container>
        </PopupLargeImageSt.Wrap>
      </div>
    </>
  );
}

export default PopupLargeImage;
