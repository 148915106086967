import React from 'react';
import { useRecoilValue } from 'recoil';
import { toastPopState } from 'store/Atoms';
import IcoCheckYellow from 'assets/images/popup/ico-check-yellow.svg';
import styled from 'styled-components';

export const ToastPopSt = {
  Wrap: styled.div`
    width: 346px;
    background: #00b074;
    border: 2px solid #ffffff;
    border-radius: 4px;
    padding: 16px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    z-index: 9999;
    right: 16px;
    top: -147px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    &.show {
      top: 16px;
      opacity: 1;
    }
  `,
  Title: styled.div`
    display: flex;
    gap: 6px;
    .ico {
      width: 20px;
      height: 20px;
      display: inline-block;
      background: url(${IcoCheckYellow}) no-repeat center;
    }
    .title {
      color: #ffea00;
      font-weight: 500;
      font-size: 16px;
    }
  `,
  Text: styled.p`
    color: #fff;
    font-weight: 500;
    font-size: 14px;
  `,
  BtnWrap: styled.div`
    margin-left: auto;
    display: flex;
    gap: 6px;
    button {
      width: 49px;
      height: 32px;
      border-radius: 4px;
      background-color: #024751;
      color: #09d585;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        transition: all 0.4s;
        background-color: #025c58;
      }
    }
  `,
};

export interface ToastPopProps {
  title?: string;
  // width?: number;
  message?: string;
  leftText?: string;
  rightText?: string;
  leftFunc?: () => void;
  rightFunc?: () => void;
  show?: boolean;
  isError?: boolean;
}
function ToastPop() {
  const toastInfo = useRecoilValue(toastPopState);
  const { title, message, leftText, rightText, leftFunc, rightFunc } = toastInfo || {};
  return (
    <ToastPopSt.Wrap className={toastInfo?.show ? 'show' : ''}>
      <ToastPopSt.Title>
        <span className="ico"></span>
        <p className="title">{title}</p>
      </ToastPopSt.Title>
      <ToastPopSt.Text> {message}</ToastPopSt.Text>
      <ToastPopSt.BtnWrap>
        {leftText && <button onClick={leftFunc}>{leftText}</button>}
        <button onClick={rightFunc}>{rightText}</button>
      </ToastPopSt.BtnWrap>
    </ToastPopSt.Wrap>
  );
}

export default ToastPop;
