import React from 'react';
import styled from 'styled-components';
import { PopupStyle } from '../../popup/PopupTemplate';
import LabelValue from '../atoms/LabelValue';

const LotOverlayStyle = {
  ...PopupStyle,
  Wrap: styled.div`
    border: 2px solid #024751;
    border-radius: 4px;
    position: absolute;
    z-index: 1000;
    width: 275px;
    transform: translate(-50%, calc(-50% - 180px));
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  `,
  Head: styled(PopupStyle.Head)`
    background: #fff;
    padding: 0 20px;
    height: 48px;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #d9d9d9;
    box-sizing: border-box;
  `,
  Title: styled(PopupStyle.Title)`
    font-size: 16px;
    color: #001a21;
    font-weight: 600;
  `,
  ReaderBtn: styled.button`
    margin-right: auto;
    margin-left: 10px;
    border: 1px solid #b6e2bf;
    border-radius: 2px;
    box-sizing: border-box;
    height: 21px;
    color: #00b074;
    font-size: 11px;
    font-weight: 600;
    padding: 0 6px;
    &:hover {
      background: #e5f8e8;
    }
  `,
  Inner: styled.div`
    padding: 10px 10px 14px 20px;
  `,
  CloseBtn: styled(PopupStyle.CloseBtn)`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #f3f4f5;
    position: relative;
    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
      width: 12px;
      height: 2px;
      border-radius: 50px;
      background: #3f3f3f;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `,
};

const LotOverlay = ({ objectFarmMap, overlayInfo, setIsSingleLot, setSingleFarmMapId, setShowCropInfo }) => {
  const info = overlayInfo?.info;
  return (
    <LotOverlayStyle.Wrap id={'lotOverlay'}>
      <LotOverlayStyle.Head>
        <LotOverlayStyle.Title>필지 정보</LotOverlayStyle.Title>
        <LotOverlayStyle.ReaderBtn
          type={'button'}
          onClick={() => {
            setIsSingleLot(true);
            setSingleFarmMapId(overlayInfo?.surveyFarmMapId);
            setShowCropInfo(true);
          }}
        >
          판독하기 &gt;
        </LotOverlayStyle.ReaderBtn>
        <LotOverlayStyle.CloseBtn type={'button'} onClick={() => objectFarmMap?.clearClickFeature()}>
          <span className={'hidden'}></span>
        </LotOverlayStyle.CloseBtn>
      </LotOverlayStyle.Head>
      <LotOverlayStyle.Cont>
        <LotOverlayStyle.Inner>
          <div className={'cScroll'} style={{ height: 198 }}>
            {info?.id ? <LabelValue label={'아이디'} value={info?.id} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.uid ? <LabelValue label={'유아이디'} value={info?.uid} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.clsf_nm ? <LabelValue label={'분류명'} value={info?.clsf_nm} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.stdg_cd ? <LabelValue label={'법정동코드'} value={info?.stdg_cd} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.stdg_addr ? <LabelValue label={'법정동주소'} value={info?.stdg_addr} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.pnu ? <LabelValue label={'대표PNU'} value={info?.pnu} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.ldcg_dc ? <LabelValue label={'대표 지목'} value={info?.ldcg_cd} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.area ? <LabelValue label={'면적'} value={info?.area} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.cad_con_ra ? <LabelValue label={'지적일치율'} value={info?.cad_con_ra} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.source_nm ? <LabelValue label={'판독영상'} value={info?.source_nm} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.flight_ymd ? <LabelValue label={'영상촬영일자'} value={info?.flight_ymd} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.updt_ymd ? <LabelValue label={'갱신일자'} value={info?.updt_ymd} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.updt_tp_nm ? <LabelValue label={'갱신유형'} value={info?.updt_tp_nm} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.chg_rsn_nm ? <LabelValue label={'변경사유'} value={info?.chg_rsn_nm} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.fl_armt_yn ? <LabelValue label={'경지정리여부'} value={info?.fl_armt_yn} padding={6} marginbottom={0} fontSize={13} /> : null}
            {info?.o_uid ? <LabelValue label={'전차_유아이디'} value={info?.o_uid} padding={8} marginbottom={0} fontSize={13} /> : null}
            {info?.o_clsf_nm ? <LabelValue label={'전차_분류명'} value={info?.o_clsf_nm} padding={6} marginbottom={0} fontSize={13} /> : null}
          </div>
        </LotOverlayStyle.Inner>
      </LotOverlayStyle.Cont>
    </LotOverlayStyle.Wrap>
  );
};

export default LotOverlay;
