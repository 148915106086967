import React, { useEffect, useState } from 'react';
import LabelValue, { LabelValueStyle } from '../../../../components/atoms/LabelValue';
import { NextStep, Section } from '../../../../components/atoms/TabContent';
import { DateObject } from 'react-multi-date-picker';
import IcoCal from '../../../../assets/images/input/ico-cal.svg';
import styled from 'styled-components';
import Checkbox from '../../../../components/atoms/Checkbox';
import TdEllipsis from '../../../../components/atoms/TdEllipsis';
import { PagingTableStyle } from '../../../../components/atoms/PagingTable';
import NoData from '../../../../components/atoms/NoData';
import IcoNoLot from '../../../../assets/images/noData/ico-no-lot.svg';
import ScrollTable from '../../../../components/atoms/ScrollTable';
import Button from '../../../../components/atoms/Button';
import LabelInput, { LabelInputStyle } from '../../../../components/atoms/LabelInput';
import LabelSelect from '../../../../components/atoms/LabelSelect';
import HelpMessage from '../../../../components/atoms/HelpMessage';
import IcoHelp from '../../../../assets/images/etc/ico-help-message.svg';
import RangePicker from '../../../../components/atoms/RangePicker';
import IcoFileUpload from '../../../../assets/images/button/ico-file-upload.svg';
import IcoStepDone from '../../../../assets/images/button/ico-step-done.svg';
import query from 'hooks/RQuery/utils';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { projectDetailState, ProjectDetailProps, confirmState, loadingState } from 'store/Atoms';
import { formatDate, getEmptyKeyString } from 'util/global';
import IcoStepDoneDisabled from '../../../../assets/images/button/ico-step-done-disabled.svg';

const initForm = {
  projectName: '제주서부사업장',
  projectId: '',
  client: '제주도청',
  lotList: [],
  round: null,
  template: null,
  reader: [],
  inspector: [],
  date: {
    from: new DateObject(),
    to: new DateObject(),
  },
};

export const CalRow = ({ from, to }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={IcoCal} alt={'기간'} style={{ marginRight: 10 }} />
      {from} ~ {to}
    </div>
  );
};

// 사업장 상세 - 기본 정보 (뷰어 & 수정 모드)
const Step1 = () => {
  const { projectId } = useOutletContext<any>();
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [projectDetail, setProjectDetail] = useRecoilState<ProjectDetailProps>(projectDetailState);
  // 회차 옵션
  const [roundOption, setRoundOption] = useState<any>([
    {
      value: 1,
      text: '1',
      active: false,
    },
    {
      value: 2,
      text: '2',
      active: false,
    },
    {
      value: 3,
      text: '3',
      active: false,
    },
    {
      value: 4,
      text: '4',
      active: false,
    },
    {
      value: 5,
      text: '5',
      active: false,
    },
  ]);

  const [isEditMode, setIsEditMode] = useState(false);
  const [form, setForm] = useState<ProjectDetailProps>({});
  console.log('project', projectDetail);

  interface LotItemProps {
    stdgCd?: string;
    stdgAddr?: string;
    totalCount?: number;
    areaUnder200Count?: number;
  }
  // 선택된 사업장의 필지목록 조회
  const { data: lots } = query.project.useGetLots(projectId);

  // 사업 수정 mutate
  const { mutate } = query.project.usePatchProject(form);

  // projectDetail 정보를 form에 자동완성
  useEffect(() => {
    setForm(projectDetail);
  }, [projectDetail]);

  // 판독 회차 active (projectDetail 정보 기반)
  useEffect(() => {
    if (projectDetail?.surveyCount) {
      setRoundOption((prev) => prev?.map((ele) => ({ ...ele, active: ele.value === projectDetail?.surveyCount })));
    }
  }, [projectDetail]);

  // [사업기간]
  const onChangeDate = (e, type) => {
    const YYYYMMDD = formatDate(new Date(e));
    if (type === 'from') {
      setForm({ ...form, surveyBeginDate: YYYYMMDD });
    } else {
      setForm({ ...form, surveyEndDate: YYYYMMDD });
    }
  };

  //  수정 완료 (저장하기)
  const onClickSaveEdit = async () => {
    console.log('myForm', form);
    const emptyKeys = getEmptyKeyString(form);

    // 기본정보 유무 체크 :projectName, prjId
    if (emptyKeys?.length > 0) {
      setConfirm({
        ...confirm,
        show: true,
        leftText: undefined,
        rightText: '확인',
        rightFunc: () => setConfirm({ ...confirm, show: false }),
        message: '빈 항목을 확인해주세요.',
        isError: true,
      });
      return;
    }
    // 서버 요청 전 확인 팝업
    setConfirm({
      ...confirm,
      show: true,
      message: '변경된 사항을 저장하시겠습니까?',
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: async () => {
        setConfirm({ ...confirm, show: false }); // 확인팝업 닫기
        setLoading({ show: true }); // 로더 시작

        await mutate(form, {
          onSuccess(data: any) {
            setLoading({ show: false }); // 로더 종료
            setProjectDetail(form); // 수정요청한 데이터로 recoil 데이터 업데이트
            setIsEditMode(!isEditMode); // 모드 변경

            setConfirm({
              ...confirm,
              show: true,
              leftText: undefined,
              rightText: '확인',
              rightFunc: () => setConfirm({ ...confirm, show: false }),
              message: data?.message,
            });
          },
          onError(error: any) {
            console.log(error);
            setLoading({ show: false }); // 로더 종료

            setConfirm({
              ...confirm,
              show: true,
              leftText: undefined,
              rightText: '확인',
              rightFunc: () => setConfirm({ ...confirm, show: false }),
              message: error?.response?.data?.message,
              isError: true,
            });
          },
        });
      },
    });
  };

  return (
    <div style={{ paddingTop: 8, height: 'calc(100% - 8px)', width: '1272px' }}>
      {/* 수정 모드 화면 */}
      {isEditMode ? (
        <>
          <Section>
            <LabelInput
              label={'사업장명'}
              fontSize={16}
              inputWidth={1116}
              inputHeight={48}
              required={true}
              placeholder={'사업장명을 입력하세요'}
              onChange={(e) => {
                setForm({ ...form, projectName: e.target.value });
              }}
              value={form?.projectName || ''}
            />
          </Section>
          <Section style={{ display: 'flex' }}>
            <div style={{ width: 635, borderRight: '1px solid #E5E5EC' }}>
              <LabelSelect label={'발주처'} inputWidth={320} inputHeight={48} required={true} option={[{ text: form?.inspectorCompanyName || '', value: '', active: true }]} $borderColor={'#E5E5EC'} onChange={() => {}} subText={''} disabled />
            </div>
            <div style={{ marginLeft: 40 }}>
              <LabelInput
                label={'프로젝트 ID'}
                fontSize={16}
                inputWidth={320}
                inputHeight={48}
                required={true}
                placeholder={'프로젝트 ID를 입력하세요'}
                onChange={(e) => {
                  setForm({ ...form, prjId: e.target.value });
                }}
                value={form?.prjId || ''}
              />
            </div>
          </Section>

          <Section style={{ display: 'flex' }}>
            <div style={{ width: 635, borderRight: '1px solid #E5E5EC' }}>
              <LabelInputStyle.Wrap style={{ height: '100%' }} $alignItem={'flex-start'}>
                <LabelInputStyle.Key required={true}>사업 기간</LabelInputStyle.Key>
                <RangePicker date={{ from: new DateObject(form?.surveyBeginDate || ''), to: new DateObject(form?.surveyEndDate || '') }} onChangeDate={onChangeDate} />
              </LabelInputStyle.Wrap>
            </div>
            <div style={{ marginLeft: 40 }}>
              <LabelSelect
                label={'판독 회차'}
                inputWidth={112}
                inputHeight={48}
                required={true}
                option={roundOption}
                $borderColor={'#E5E5EC'}
                onChange={(e: any) => {
                  const newArr = [...roundOption];
                  newArr.map((i) => {
                    i.active = String(i.value) === e.target.ariaValueText;
                  });
                  setRoundOption(newArr);
                  setForm({ ...form, surveyCount: e.target.ariaValueText });
                }}
                subText={'회차까지 실행'}
                disabled
                //  disabled={!(projectDetail?.status === 'READY' && projectDetail?.inProgressSurveyNo === 1)}
              />
            </div>
          </Section>

          <Section style={{ height: 'calc(100% - 286px)' }}>
            <LabelInputStyle.Wrap style={{ height: '100%' }} $alignItem={'flex-start'}>
              <LabelInputStyle.Key required={false}>필지 정보</LabelInputStyle.Key>
              <div style={{ width: '100%', height: '100%' }}>
                <ScrollTable
                  height={lots?.length > 0 ? '' : `calc(100% - 192px)`}
                  $minHeight={'calc(100% - 192px)'}
                  $maxHeight={'calc(100% - 192px)'}
                  caption={'필지 정보'}
                  colgroup={[200, 'auto', 180, 250]}
                  thead={
                    <tr>
                      <th scope={'col'} style={{ paddingLeft: 24 }}>
                        법정동코드
                      </th>
                      <th scope={'col'}>주소</th>
                      <th scope={'col'}>총 필지 개수</th>
                      <th scope={'col'}>200m²미만 필지 개수</th>
                    </tr>
                  }
                  tbody={
                    <>
                      {lots?.length > 0 ? (
                        lots.map((ele: LotItemProps, idx) => (
                          <tr key={ele.stdgCd}>
                            <td style={{ paddingLeft: 24 }}>{ele.stdgCd}</td>
                            <td>
                              <TdEllipsis str={ele.stdgAddr} />
                            </td>
                            <td>{ele.totalCount}</td>
                            <td>{ele.areaUnder200Count}</td>
                          </tr>
                        ))
                      ) : (
                        <tr className="noHover">
                          <td colSpan={6} style={{ height: '100%', borderBottom: 0 }}>
                            <NoData text={'등록된 필지가 없습니다.<br/>필지 주소 또는 shp 파일을 업로드해주세요.'} color={'#3F3F3F'} icon={IcoNoLot} $iconSize={54} padding={[50, 50]} />
                          </td>
                        </tr>
                      )}
                    </>
                  }
                  $hasTotal={true}
                  // total={lots?.length + '필지'}
                  // checkLength={lots?.filter(({ checked }) => checked).length}
                  $totalBtnName={'필지 삭제'}
                  onRemove={() => {}}
                />
                <NextStep>
                  <Button $buttonType={'large'} text={'저장하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={onClickSaveEdit} $iconDisabled={IcoStepDoneDisabled} />
                </NextStep>
              </div>
            </LabelInputStyle.Wrap>
          </Section>
        </>
      ) : (
        <>
          {/* 뷰어 모드 화면 */}
          <Section>
            <LabelValue label={'사업장'} value={projectDetail?.projectName || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
          </Section>
          <Section style={{ display: 'flex' }}>
            <div style={{ width: 635, borderRight: '1px solid #E5E5EC' }}>
              <LabelValue label={'발주처'} value={projectDetail?.inspectorCompanyName || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
            </div>
            <div style={{ marginLeft: 40 }}>
              <LabelValue label={'프로젝트 ID'} value={projectDetail?.prjId || '-'} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
            </div>
          </Section>
          <Section style={{ display: 'flex' }}>
            <div style={{ width: 635, borderRight: '1px solid #E5E5EC' }}>
              <LabelValue label={'기간'} value={<CalRow from={String(projectDetail?.surveyBeginDate)} to={String(projectDetail?.surveyEndDate)} />} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
            </div>
            <div style={{ marginLeft: 40 }}>
              <LabelValue label={'판독 회차'} value={projectDetail?.surveyCount || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} subText={'회차까지 실행'} />
            </div>
          </Section>
          <Section style={{ height: 'calc(100% - 300px)' }}>
            <LabelValueStyle.Wrap $alignItem={'flex-start'} style={{ height: '100%' }}>
              <LabelValueStyle.Key $labelWidth={155} fontSize={16} $labelColor={'#001A21'}>
                필지 목록
              </LabelValueStyle.Key>
              <LabelValueStyle.Value style={{ height: '100%' }}>
                <div style={{ width: '100%', height: '100%' }}>
                  <ScrollTable
                    height={lots?.length > 0 ? '' : `calc(100% - 192px)`}
                    $minHeight={'calc(100% - 60px)'}
                    $maxHeight={'calc(100% - 60px)'}
                    caption={'필지 정보'}
                    colgroup={[200, 'auto', 180, 250]}
                    thead={
                      <tr>
                        <th scope={'col'} style={{ paddingLeft: 24 }}>
                          법정동코드
                        </th>
                        <th scope={'col'}>주소</th>
                        <th scope={'col'}>총 필지 개수</th>
                        <th scope={'col'}>200m²미만 필지 개수</th>
                      </tr>
                    }
                    tbody={
                      <>
                        {lots?.length > 0 ? (
                          lots.map((ele: LotItemProps, idx) => (
                            <tr key={ele.stdgCd}>
                              <td style={{ paddingLeft: 24 }}>{ele.stdgCd}</td>
                              <td>
                                <TdEllipsis str={ele.stdgAddr} />
                              </td>
                              <td>{ele.totalCount}</td>
                              <td>{ele.areaUnder200Count}</td>
                            </tr>
                          ))
                        ) : (
                          <tr className="noHover">
                            <td colSpan={6} style={{ height: '100%', borderBottom: 0 }}>
                              <NoData text={'등록된 필지가 없습니다.<br/>필지 주소 또는 shp 파일을 업로드해주세요.'} color={'#3F3F3F'} icon={IcoNoLot} $iconSize={54} padding={[50, 50]} />
                            </td>
                          </tr>
                        )}
                      </>
                    }
                    $hasTotal={true}
                    //   total={lots?.length + '필지'}
                    //   checkLength={lots?.filter(({ checked }) => checked).length}
                    $totalBtnName={'필지 삭제'}
                    onRemove={() => {}}
                  />
                </div>
              </LabelValueStyle.Value>
            </LabelValueStyle.Wrap>
            <div style={{ display: 'flex', marginTop: 20, marginLeft: 155 }}>
              <Button $buttonType={'large'} text={'수정하기'} onClick={() => setIsEditMode(!isEditMode)} />
            </div>
          </Section>
        </>
      )}
    </div>
  );
};

export default Step1;
