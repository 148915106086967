import React, { useState } from 'react';
import PopupTemplate from './PopupTemplate';
import Button from 'components/atoms/Button';
import styled from 'styled-components';
import HelpMessage from 'components/atoms/HelpMessage';
import IcoHelp from 'assets/images/etc/ico-help-message.svg';
import LabelSelect from 'components/atoms/LabelSelect';

const Content = {
  Inner: styled.div`
    padding: 24px 0;
  `,
  Tip: styled.p`
    color: #00b074;
    font-size: 14px;
    font-weight: 400;
  `,
  BorderWrap: styled.div`
    border-radius: 4px;
    border: 1px solid #e7eaee;
    padding: 12px;

    ul {
      border-radius: 4px;
      border: 1px solid #e7eaee;
    }
  `,
  Row: styled.li`
    display: flex;
    align-items: center;
    padding: 8.5px 20px;
    box-sizing: border-box;
    &:not(:last-child) {
      border-bottom: 1px solid #e7eaee;
    }
    strong {
      font-weight: 400;
    }
    &:hover {
      transition: all 0.4s;
      background: #f6f6f6;
    }
  `,
};

const options = [
  {
    value: '1',
    text: '1',
    active: false,
  },
  {
    value: '2',
    text: '2',
    active: false,
  },
  {
    value: '3',
    text: '3',
    active: false,
  },
  {
    value: '4',
    text: '4',
    active: false,
  },
];

/*  순서 변경 팝업 */
function PopupChangeOrder({ onClose, codeData, setCodeData }) {
  // 팝업 내에서 임시로 순서를 변경.
  const [newList, setNewList] = useState(codeData?.find((ele) => ele?.group?.length > 0)?.group || []); // ['기타','시설','비경지','휴경지']

  // 순서 onChange
  const onChangeSelect = (e, idx) => {
    const { ariaValueText } = e.target;

    // 배열 - 특정 요소를 새 인덱스로 이동
    function moveArrayElement(arr, fromIndex, toIndex) {
      const element = arr.splice(Number(fromIndex), 1)[0]; // fromIndex에서 요소 제거
      arr.splice(Number(toIndex), 0, element); // toIndex에 요소 삽입
      return arr;
    }

    setNewList((prev) => {
      let newArr = moveArrayElement([...prev], idx, Number(ariaValueText) - 1); // 예: 1번 인덱스를 3번 인덱스로 이동
      return newArr;
    });
  };

  console.log('newList', newList);

  // 확인 버튼 클릭
  const onClickConfirm = () => {
    let finalGroup = newList.map((ele, idx) => ({ ...ele, level: Math.floor(ele.level) + (idx + 1) / 10 }));
    // 최종 순서 변경 결과를 부모에 전달.
    setCodeData((prev) => {
      let newArr = [...prev].map((ele) => (ele?.group?.length > 0 ? { ...ele, group: finalGroup } : ele));
      return newArr;
    });
    onClose(); // 팝업창 닫기
  };

  return (
    <>
      <PopupTemplate
        wrapStyle={{ overflow: 'visible' }}
        title={'순서 변경'}
        width={320}
        isDimmed={true}
        onClose={onClose}
        footer={<Button width={'full'} text={'확인'} onClick={onClickConfirm} />}
        content={
          <Content.Inner>
            <HelpMessage icon={IcoHelp} message={'변경할 <b>항목의 순서</b>를 변경해주세요.'} style={{ marginBottom: 20 }} />
            <Content.BorderWrap>
              <ul>
                {newList?.map((ele, idx) => (
                  <Content.Row key={'orderList' + idx}>
                    <LabelSelect
                      $labelWidth={120}
                      label={ele.displayName}
                      inputWidth={80}
                      inputHeight={40}
                      required={false}
                      option={options?.map((ele, i) => ({ ...ele, active: i === idx }))}
                      $borderColor={'#E5E5EC'}
                      onChange={(e) => {
                        onChangeSelect(e, idx);
                      }}
                    />
                  </Content.Row>
                ))}
              </ul>
            </Content.BorderWrap>
          </Content.Inner>
        }
      />
      <div style={{ overflow: 'visible' }}></div>
    </>
  );
}

export default PopupChangeOrder;
