import React, { ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import IcoRemove from '../../assets/images/button/ico-remove.svg';
import Button from './Button';
import IcoFileRemove from '../../assets/images/button/ico-file-remove.svg';
// import IcoPagiLeft from '../../assets/images/'

export const PagingTableStyle = {
  Wrap: styled.table<{ width?: number }>`
    width: ${(p) => (p.width ? p.width + 'px' : '100%')};
    //border-bottom: 1px solid #000;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
  `,
  Thead: styled.thead<{ type?: number }>`
    position: sticky;
    top: 0;
    z-index: 5;
    tr {
      th {
        padding: ${(p) => (p.type === 2 ? '0 20px' : '0 5px')};

        background: ${(p) => (p.type === 2 ? '#DEEDC9' : '#f6f6f6')};
        color: ${(p) => (p.type === 2 ? '#024751' : '#64748b')};
        font-size: 14px;
        font-weight: 500;
        height: ${(p) => (p.type === 2 ? '40px' : '54px')};
        border-bottom: 1px solid #e7eaee;
        border-top: 1px solid #e7eaee;
        box-sizing: border-box;
        position: relative;
        text-align: left;
        label {
          justify-content: center;
        }
        > button {
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          color: #000;
          &:after {
            content: '';
            width: 5px;
            height: 5px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(-45deg);
            margin-top: -2px;
            margin-left: 10px;
            transition: 0.4s;
          }
          &.asc {
            &:after {
              transform: rotate(135deg);
              margin-bottom: -4px;
              margin-top: 0;
            }
          }
        }
        &:first-child {
          border-left: 1px solid #e7eaee;
          border-radius: 8px 0 0 0;
        }
        &:last-child {
          border-right: 1px solid #e7eaee;
          border-radius: 0 8px 0 0;
        }
      }
    }
  `,
  Tbody: styled.tbody<{ type?: number }>`
    tr {
      td {
        background: #fff;
        color: ${(p) => (p.type === 2 ? '#3f3f3f' : '#001a21')};
        font-size: ${(p) => (p.type === 2 ? '14px' : '16px')};
        font-weight: ${(p) => (p.type === 2 ? 500 : 400)};
        text-align: left;
        height: ${(p) => (p.type === 2 ? '40px' : '60px')};
        border-bottom: 1px solid #e7eaee;
        padding: ${(p) => (p.type === 2 ? '0 20px' : '0 5px')};
        label {
          justify-content: center;
        }
        &:not(:last-child) {
        }
        &:first-child {
          border-left: 1px solid #e7eaee;
        }
        &:last-child {
          border-right: 1px solid #e7eaee;
        }
      }
      &:hover,
      &.checked {
        &:not(.noHover) {
          td {
            background: #f6f6f6;

            &.shadow {
              background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0.5%, #f6f6f6 5.5%, #f6f6f6);
            }

            .btnRemove {
              display: flex;
            }
          }
        }
      }
      &:last-child {
        td {
          &:first-child {
            ${(p) => p.type === 2 && 'border-radius: 0 0 0 4px'}
          }
          &:last-child {
            ${(p) => p.type === 2 && 'border-radius: 0 0 4px 0'}
          }
        }
      }
    }
  `,
  SiteName: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0076ff;
    &.txtLeft {
      justify-content: flex-start;
    }
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      margin-right: 9px;
    }
  `,
  PagiArea: styled.div<{ type?: number }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(p) => (p.type === 2 ? '30px' : '60px')};
    border: ${(p) => (p.type === 2 ? 'none' : '1px solid #e7eaee')};
    border-top: 0;
    border-radius: 0 0 8px 8px;
    margin: ${(p) => (p.type === 2 ? '14px 0 0' : 0)};
  `,
  LeftBtnGroup: styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    > button {
      &:not(:last-child) {
        margin-right: 7px;
      }
    }
  `,
  PagiBtn: styled.button<{ type?: number }>`
    min-width: ${(p) => (p.type === 2 ? 'inherit' : '32px')};
    height: ${(p) => (p.type === 2 ? '30px' : '32px')};
    padding: 0 4px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid transparent;
    color: ${(p) => (p.type === 2 ? '#767676' : '#64748b')};
    font-size: 14px;
    font-weight: ${(p) => (p.type === 2 ? 700 : 400)};
    &.active {
      position: relative;
      color: #00b074;
      background: ${(p) => (p.type === 2 ? 'none' : '#e4efd5')};
      font-weight: 600;
      &:after {
        display: ${(p) => (p.type === 2 ? 'block' : 'none')};
        content: '';
        position: absolute;
        left: 50%;
        bottom: 3px;
        transform: translateX(-50%);
        width: 7px;
        height: 2px;
        background: #00b074;
      }
    }
    &:disabled {
      visibility: hidden;
    }
    &.prev,
    &.next,
    &.first,
    &.last {
      position: relative;
      background: #fff;
      border: 1px solid #d9d9d9;
      border-radius: ${(p) => (p.type === 2 ? '50%' : '4px')};
      height: ${(p) => (p.type === 2 ? '24px' : '32px')};
      width: ${(p) => (p.type === 2 ? '24px' : '32px')};
      &:hover {
        background: #f6f6f6;
        &:before,
        &:after {
          border-color: #3f3f3f;
        }
      }
      &:before,
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        left: 50%;
        top: 50%;
        border-bottom: 1px solid #767676;
        border-left: 1px solid #767676;
      }
    }
    &.prev {
      &:after {
        transform: translate(calc(-50% + 2px), -50%) rotate(45deg) ${(p) => (p.type === 2 ? 'scale(0.7)' : '')};
      }
      &:before {
        display: none;
      }
    }
    &.next {
      &:after {
        transform: translate(calc(-50% - 2px), -50%) rotate(-135deg) ${(p) => (p.type === 2 ? 'scale(0.7)' : '')};
      }
      &:before {
        display: none;
      }
    }
    &.first {
      margin-right: 10px;
      &:before {
        transform: translate(calc(-50% + 4px), -50%) rotate(45deg) ${(p) => (p.type === 2 ? 'scale(0.7)' : '')};
      }
      &:after {
        transform: translate(calc(-50% - ${(p) => (p.type === 2 ? '0px' : '2px')}), -50%) rotate(45deg) ${(p) => (p.type === 2 ? 'scale(0.7)' : '')};
      }
    }
    &.last {
      margin-left: 10px;
      &:before {
        transform: translate(calc(-50% - 4px), -50%) rotate(-135deg) ${(p) => (p.type === 2 ? 'scale(0.7)' : '')};
      }
      &:after {
        transform: translate(calc(-50% + ${(p) => (p.type === 2 ? '0px' : '2px')}), -50%) rotate(-135deg) ${(p) => (p.type === 2 ? 'scale(0.7)' : '')};
      }
    }
  `,
  PagiGroup: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px;
    > button,
    > div {
      &:not(:last-child) {
        margin-right: 2px;
      }
    }
  `,
  PagiInfo: styled.p`
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    color: #001a21;
    font-size: 14px;
    font-weight: 400;
    > b {
      font-weight: 600;
    }
  `,
  BtnRowRemove: styled.button`
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    background: rgba(220, 22, 0, 0.6) url(${IcoRemove}) no-repeat center;
    &.checked {
      display: flex;
    }
  `,
};

interface PagingTableProps {
  currentPage: number;
  setCurrentPage: React.Dispatch<SetStateAction<number>>;
  caption: string;
  colgroup?: (number | string)[];
  totalPages: number;
  thead: ReactNode;
  tbody: ReactNode;
  width?: number;
  height?: string;
  hasTotal?: boolean;
  checkLength?: number;
  $totalBtnName?: string;
  onRemove?: () => void;
  type?: number;
}
const PagingTable = ({ currentPage, setCurrentPage, caption, colgroup, totalPages, thead, tbody, width, height, hasTotal, checkLength, $totalBtnName, onRemove, type = 1 }: PagingTableProps) => {
  const [pageArray, setPageArray] = useState<number[]>([]);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    const newArr: number[] = [];
    for (let i = 1; i <= 5; i++) {
      newArr.push(i + pageIndex * 5);
    }
    setPageArray(newArr);
  }, [pageIndex]);
  useEffect(() => {
    if (currentPage === 1) {
      setPageIndex(0);
    }
  }, [currentPage]);
  return (
    <>
      {/* 테이블 */}
      <div style={{ flex: 1, width: '100%', height: height }}>
        <PagingTableStyle.Wrap width={width}>
          <caption>{caption}</caption>
          {colgroup ? (
            <colgroup>
              {colgroup.map((i, index) => {
                return <col key={index} style={{ width: i }} />;
              })}
            </colgroup>
          ) : null}
          <PagingTableStyle.Thead type={type}>{thead}</PagingTableStyle.Thead>
          <PagingTableStyle.Tbody type={type}>{tbody}</PagingTableStyle.Tbody>
        </PagingTableStyle.Wrap>
      </div>
      <PagingTableStyle.PagiArea type={type}>
        {hasTotal ? (
          <PagingTableStyle.PagiInfo>
            Page <b>{currentPage}</b> of {totalPages}
          </PagingTableStyle.PagiInfo>
        ) : null}
        {!!checkLength ? (
          <Button
            $colorType={'redLine'}
            text={`${$totalBtnName}(${checkLength})`}
            icon={IcoFileRemove}
            $iconSize={20}
            $iconSpacing={6}
            style={{ marginLeft: 'auto', position: 'absolute', right: 24, top: '50%', transform: 'translateY(-50%)' }}
            padding={12}
            height={36}
            fontSize={14}
            onClick={onRemove}
          />
        ) : null}
        <PagingTableStyle.PagiBtn
          type={type}
          disabled={!(pageIndex > 0)}
          className={'first'}
          onClick={() => {
            setPageIndex(0);
            setCurrentPage(1);
          }}
        ></PagingTableStyle.PagiBtn>
        <PagingTableStyle.PagiBtn
          type={type}
          disabled={!(pageIndex > 0)}
          className={'prev'}
          onClick={() => {
            if (pageIndex > 0) {
              setCurrentPage(1 + (pageIndex - 1) * 5);
              setPageIndex(pageIndex - 1);
            }
          }}
        ></PagingTableStyle.PagiBtn>
        <PagingTableStyle.PagiGroup>
          {pageArray.map((i) => {
            if (i <= totalPages)
              return (
                <PagingTableStyle.PagiBtn
                  key={i}
                  type={type}
                  className={i === currentPage ? 'active' : undefined}
                  onClick={() => {
                    setCurrentPage(i);
                  }}
                >
                  {i}
                </PagingTableStyle.PagiBtn>
              );
          })}
        </PagingTableStyle.PagiGroup>

        <PagingTableStyle.PagiBtn
          type={type}
          disabled={!(pageIndex < totalPages / 5 - 1)}
          className={'next'}
          onClick={() => {
            if (pageIndex < totalPages / 5 - 1) {
              setCurrentPage(1 + (pageIndex + 1) * 5);
              setPageIndex(pageIndex + 1);
            }
          }}
        ></PagingTableStyle.PagiBtn>
        <PagingTableStyle.PagiBtn
          type={type}
          disabled={!(pageIndex < totalPages / 5 - 1)}
          className={'last'}
          onClick={() => {
            setPageIndex(Math.ceil(totalPages / 5) - 1);
            setCurrentPage(totalPages);
          }}
        ></PagingTableStyle.PagiBtn>
      </PagingTableStyle.PagiArea>
    </>
  );
};

export default PagingTable;
