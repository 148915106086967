import React, { SetStateAction, useState } from 'react';
import DatePicker, { Calendar, DateObject } from 'react-multi-date-picker';
import IcoCal from '../../assets/images/input/ico-cal.svg';
import styled from 'styled-components';
import IcoLeft from '../../assets/images/ico-cal-left.svg';
import IcoLeftH from '../../assets/images/ico-cal-left-hover.svg';
import IcoRight from '../../assets/images/ico-cal-right.svg';
import IcoRightH from '../../assets/images/ico-cal-right-hover.svg';

const RangePickerStyle = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    .rmdp-container {
      .rmdp-wrapper {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
        border: 1px solid #dfdfdf;
      }
      .rmdp-input {
        width: 172px;
        height: 48px;
        border: 1px solid #e5e5ec;
        margin: 0;
        padding: 0 0 0 20px;
        color: #001a21;
        font-size: 16px;
        font-weight: 400;
        background: #fff url(${IcoCal}) no-repeat right 20px center;
        &:focus {
          border: 1px solid #00b074;
        }
      }
      .rmdp-header {
        height: 48px;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid #dfdfdf;
        border-radius: 4px;
        > div {
          height: 100%;
          padding: 0 24px;
          align-items: center;
        }
        .rmdp-header-values {
          color: #001a21;
          font-size: 14px;
          font-weight: 600;
          span {
            padding-left: 0;
            &:first-child {
              &:after {
                content: '년';
              }
            }
          }
        }
      }
      .rmdp-calendar {
        padding: 0;
      }
      .rmdp-ep-arrow {
        display: none !important;
      }
      .rmdp-arrow-container {
        width: 24px;
        height: 24px;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        > i {
          display: none;
        }
        &.rmdp-left {
          background: url(${IcoLeft}) no-repeat center;
          &:hover {
            background-image: url(${IcoLeftH});
          }
        }
        &.rmdp-right {
          background: url(${IcoRight}) no-repeat center;
          &:hover {
            background-image: url(${IcoRightH});
          }
        }
      }
      .rmdp-day-picker {
        padding: 20px;
      }
      .rmdp-week-day {
        color: #001a21;
        font-size: 14px;
        font-weight: 600;
      }
      .rmdp-day {
        &.rmdp-selected {
          .sd {
            background: #00b074;
            color: #fff;
          }
          &.rmdp-today {
            .sd {
              color: #fff;
              background: #00b074;
              &:after {
                position: absolute;
                display: block;
                content: '';
                width: 4px;
                height: 4px;
                background: #ff7600;
                border-radius: 50%;
                top: calc(100% - 6px);
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
        &.rmdp-today {
          .sd {
            color: #001a21;
            background: none;
            &:after {
              position: absolute;
              display: block;
              content: '';
              width: 4px;
              height: 4px;
              background: #ff7600;
              border-radius: 50%;
              top: calc(100% - 5px);
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
        &:hover {
          .sd {
            background: #31c16e33;
          }
          &.rmdp-selected {
            .sd {
              background: #00b074;
              color: #fff;
            }
          }
        }
        .sd {
          color: #001a21;
          border-radius: 2px;
        }
      }
    }
  `,
  Dash: styled.span`
    width: 10px;
    height: 2px;
    background: #d3d3d4;
    margin: 0 10px;
  `,
};

const RangePicker = ({ date, onChangeDate }: { date: { from: DateObject; to: DateObject }; onChangeDate: (e, key) => void }) => {
  return (
    <RangePickerStyle.Wrap>
      <DatePicker
        months={['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']}
        weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
        showOtherDays={true}
        headerOrder={['LEFT_BUTTON', 'YEAR_MONTH', 'RIGHT_BUTTON']}
        monthYearSeparator=" "
        value={date?.from}
        onChange={(e) => onChangeDate(e, 'from')}
      />
      <RangePickerStyle.Dash></RangePickerStyle.Dash>
      <DatePicker
        months={['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']}
        weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
        showOtherDays={true}
        headerOrder={['LEFT_BUTTON', 'YEAR_MONTH', 'RIGHT_BUTTON']}
        monthYearSeparator=" "
        value={date?.to}
        onChange={(e) => onChangeDate(e, 'to')}
      />
    </RangePickerStyle.Wrap>
  );
};

export default RangePicker;
