import React, { useEffect, useState } from 'react';
import { NextStep, Section } from '../../../../components/atoms/TabContent';
import HelpMessage from '../../../../components/atoms/HelpMessage';
import IcoHelp from '../../../../assets/images/etc/ico-help-message.svg';
import FileButton from '../../../../components/atoms/FileButton';
import Checkbox from '../../../../components/atoms/Checkbox';
import ScrollTable from '../../../../components/atoms/ScrollTable';
import { useRecoilState } from 'recoil';
import { confirmState } from '../../../../store/Atoms';
import Button from '../../../../components/atoms/Button';
import IcoStepNext from '../../../../assets/images/button/ico-step-next.svg';
import IcoStepDone from '../../../../assets/images/button/ico-step-done.svg';
import { useNavigate } from 'react-router-dom';
import CsvTemplate from '../../../../assets/작물코드_csv_템플릿.csv';
import IcoStepDoneDisabled from '../../../../assets/images/button/ico-step-done-disabled.svg';

const Step2 = () => {
  const navigate = useNavigate();
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [codeData, setCodeData] = useState<any>([{ LAND_LAYER_NM1: '', LAND_LAYER_CODE1: '', LAND_LAYER_NM2: '', LAND_LAYER_CODE2: '', LAND_LAYER_NM3: '', LAND_LAYER_CODE3: '', checked: false }]);
  const [colList, setColList] = useState<any>(['LAND_LAYER_CODE1', 'LAND_LAYER_CODE2', 'LAND_LAYER_CODE3', 'LAND_LAYER_NM1', 'LAND_LAYER_NM2', 'LAND_LAYER_NM3']);
  const [isAllCheck, setIsAllCheck] = useState(false);

  //전체 선택/해제
  const onCheckAll = () => {
    const newArr = [...codeData];
    if (newArr.length === newArr.filter(({ checked }) => checked).length) {
      newArr.map((i) => {
        i.checked = false;
      });
    } else {
      newArr.map((i) => {
        i.checked = true;
      });
    }
    setCodeData(newArr);
  };

  //체크박스 클릭
  const onCheck = (id) => {
    const newArr = [...codeData];
    newArr.find((i, index) => index === id)!.checked = !newArr.find((i, index) => index === id)?.checked;
    setCodeData(newArr);
  };

  //체크된수 === 전체 수 같은지 확인 후 전체체크박스 체크선택/해제
  useEffect(() => {
    if (codeData.filter(({ checked }) => checked).length === codeData.length) {
      setIsAllCheck(true);
    } else {
      setIsAllCheck(false);
    }
  }, [codeData.filter(({ checked }) => checked).length]);

  //csv 읽어오기
  const onOpenCsv = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const arr = (e.target as any).result.replaceAll('\r', '').split('\n');
      const step2Arr: any = [];
      const step3Arr: any = [];

      arr.map((str) => {
        step2Arr.push(str.split(','));
      });
      let obj = {};

      step2Arr.map((i, index) => {
        if (index !== 0) {
          colList.map((th, idx) => {
            obj = { ...obj, [th]: i[idx], checked: false };
          });
          step3Arr.push(obj);
        }
      });
      colList.map((th, idx) => {
        obj = { ...obj, [th]: '', checked: false };
      });
      step3Arr.push(obj);

      setCodeData(step3Arr);
    };
    reader.readAsText(file);
  };

  //행 추가
  const onRowAdd = () => {
    const newArr = [...codeData];
    let obj = {};
    colList.map((i) => {
      obj = { ...obj, [i]: '', checked: false };
    });
    newArr.push(obj);
    setCodeData(newArr);
  };

  //테이블 내 데이터 변경값 state 변경
  const onChangeValue = (e, key, num) => {
    const val = e.target.value;
    const newArr = [...codeData];
    newArr.filter((i, index) => index === num)[0][key] = val;
    setCodeData(newArr);
  };

  //선택 행 삭제
  const onRemoveCheck = () => {
    setConfirm({
      ...confirm,
      show: true,
      leftText: '취소',
      rightText: '확인',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: () => {
        const newArr = [...codeData];
        setCodeData(newArr.filter(({ checked }) => !checked));
        setConfirm({ ...confirm, show: false });
      },
      message: '선택한 항목을 삭제하시겠습니까?',
    });
  };

  //저장하기
  const onSubmit = () => {
    setConfirm({
      ...confirm,
      show: true,
      leftText: '취소',
      rightText: '확인',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightFunc: () => {
        //TODO 저장 API 연결
        setConfirm({ ...confirm, show: false });
        navigate('/client');
      },
      message: '저장하시겠습니까?',
    });
  };
  return (
    <>
      <Section style={{ width: '1272px', height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 16 }}>
          <HelpMessage message={'작물 분류를 정해진 양식의 CSV로 작성해 일괄 추가할 수 있습니다  &nbsp;&nbsp;&nbsp;'} icon={IcoHelp} />
          <a
            href={CsvTemplate}
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: '#00b074',
              textDecoration: 'underline',
              marginRight: 'auto',
            }}
          >
            작물 분류 기준 csv 양식 다운로드
          </a>
          <FileButton id={'csvOpen'} text={'CSV 불러오기'} onChange={onOpenCsv} $isShowFileName={true} />
        </div>
        <ScrollTable
          $hasBorder={true}
          $minHeight={'calc(100% - 194px)'}
          caption={'필지 정보'}
          colgroup={['auto', 235, 160, 235, 160, 235, 160]}
          thead={
            <tr>
              <th scope={'col'}>
                <Checkbox onChange={onCheckAll} id={'checkAll'} checked={isAllCheck} />
              </th>
              <th scope={'col'}>대분류</th>
              <th scope={'col'}>대분류 코드</th>
              <th scope={'col'} className={'shadow'}>
                중분류
              </th>
              <th scope={'col'}>중분류 코드</th>
              <th scope={'col'} className={'shadow'}>
                작물명
              </th>
              <th scope={'col'}>작물 코드</th>
            </tr>
          }
          tbody={
            <>
              {codeData.map((i: any, index: number) => {
                return (
                  <tr key={index} className={i.checked ? 'checked' : undefined}>
                    <td>
                      <Checkbox id={'code_' + index} checked={i.checked} onChange={() => onCheck(index)} />
                    </td>
                    <td>
                      <input type={'text'} value={i.LAND_LAYER_NM1} onChange={(e) => onChangeValue(e, 'LAND_LAYER_NM1', index)} placeholder={'입력'} />
                    </td>
                    <td>
                      <input type={'text'} value={i.LAND_LAYER_CODE1} onChange={(e) => onChangeValue(e, 'LAND_LAYER_CODE1', index)} placeholder={'입력'} />
                    </td>
                    <td className={'shadow'}>
                      <input type={'text'} value={i.LAND_LAYER_NM2} onChange={(e) => onChangeValue(e, 'LAND_LAYER_NM2', index)} placeholder={'입력'} />
                    </td>
                    <td>
                      <input type={'text'} value={i.LAND_LAYER_CODE2} onChange={(e) => onChangeValue(e, 'LAND_LAYER_CODE2', index)} placeholder={'입력'} />
                    </td>
                    <td className={'shadow'}>
                      <input type={'text'} value={i.LAND_LAYER_NM3} onChange={(e) => onChangeValue(e, 'LAND_LAYER_NM3', index)} placeholder={'입력'} />
                    </td>
                    <td>
                      <input type={'text'} value={i.LAND_LAYER_CODE3} onChange={(e) => onChangeValue(e, 'LAND_LAYER_CODE3', index)} placeholder={'입력'} />
                    </td>
                  </tr>
                );
              })}
              <tr className={'noHover'}>
                <td colSpan={7}>
                  <button type={'button'} className={'rowAdd'} onClick={onRowAdd}>
                    + 추가하기
                  </button>
                </td>
              </tr>
            </>
          }
          $hasTotal={true}
          $totalBtnName={'삭제'}
          checkLength={codeData.filter(({ checked }) => checked).length}
          onRemove={onRemoveCheck}
        />

        <NextStep>
          <Button $buttonType={'large'} text={'저장하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={onSubmit} $iconDisabled={IcoStepDoneDisabled} />
        </NextStep>
      </Section>
    </>
  );
};

export default Step2;
