import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Logo from '../../../assets/images/login/header-logo.svg';
import Select, { SelectStyle } from '../../atoms/Select';
import query from '../../../hooks/RQuery/utils';
import IcoStatistics from '../../../assets/images/button/ico-statistics.svg';
import IcoStatisticsA from '../../../assets/images/button/ico-statistics-active.svg';
import Statistics from './Statistics';
import { useRecoilState, useRecoilValue } from 'recoil';
import { confirmState, loadingState, stdgCdState } from 'store/Atoms';
import useDelayedLoadingText from 'hooks/useDelayedLoadingText';
const HeaderStyle = {
  Contain: styled.header`
    position: absolute;
    z-index: 12;
    left: 16px;
    top: 16px;
    display: flex;
    align-items: center;
  `,
  Logo: styled.h1`
    width: 122px;
    height: 48px;
    background: #024751 url(${Logo}) no-repeat center;
  `,
  StatisticsWrap: styled.div`
    position: relative;
    margin-left: 10px;
    display: flex;
    ${SelectStyle.Wrap} {
      margin-left: 0 !important;
    }
    ${SelectStyle.Selected} {
      border-radius: 0 4px 4px 0;
    }
  `,
  StatisticsBtn: styled.button`
    width: 48px;
    height: 48px;
    border-right: 1px solid #e9e7e7;
    background: #fff url(${IcoStatistics}) no-repeat center;
    border-radius: 4px 0 0 4px;
    &.active {
      background-image: url(${IcoStatisticsA});
    }
  `,
};

const Header = ({ isObjectReady, objectFarmMap, setOverlayInfo, round, setRound, setShowCropInfo, setIsReloadShow }) => {
  const chartRef = useRef<any>(null);
  const memberId = sessionStorage.getItem('memberId');
  const projectId = sessionStorage.getItem('projectId');
  const stdgCdInfo = useRecoilValue(stdgCdState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [statisticsStatus, setStatisticsStatus] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const {
    data: farmMapData,
    refetch: refetchFarmMapData,
    isFetching: fetchingFarmMapData,
  } = query.survey.useMapData({
    surveyId: round.filter(({ active }) => active)[0]?.value,
    stdgCd: stdgCdInfo?.code,
    //  lastPnu: param,
    //  limit: 1000,
  });
  interface projectItemProps {
    projectId: string;
    projectName: string;
    startDate: string;
    endDate: string;
  }
  // 사업장 리스트 조회
  const { data, isFetching: isFetchingProjects } = query.member.useGetProjects(memberId);
  const { datas: projects } = data || {};

  // 사업의 회차 리스트 조회
  const { data: projRounds, isFetching } = query.project.useGetRounds(projectId);
  const { inProgressSurveyNo, surveyList } = projRounds || {};

  // 사업장 변경시, 지도 데이터 재호출
  useEffect(() => {
    if (!!stdgCdInfo.code) {
      refetchFarmMapData();
    }
  }, [projectId]);

  // 로딩중 텍스트
  const loadingText_project = useDelayedLoadingText(isFetchingProjects, 500, '사업장 조회중');
  const loadingText = useDelayedLoadingText(isFetching, 500, '조회중');

  // 사업장 리스트 option형태로 변경
  useEffect(() => {
    if (!!projects && projects?.length > 0) {
      let newArr = projects.map((ele: projectItemProps) => ({ ...ele, text: ele.projectName, value: ele.projectId, active: ele.projectId === projectId }));

      setSiteList(newArr);
    }
  }, [projects, projectId]);

  // 회차 리스트 option형태로 변경
  useEffect(() => {
    if (surveyList?.length > 0) {
      // 현재 진행중인 회차와 같은 항목 active, 없으면 첫번째 항목 active
      let isMatchItem = surveyList.filter((ele) => ele?.surveyNo == inProgressSurveyNo)?.length;
      let newArr = surveyList.map((ele, idx) => ({ ...ele, text: `${String(ele?.surveyNo)}회차`, value: ele?.surveyId, active: isMatchItem ? ele?.surveyNo == inProgressSurveyNo : idx === 0 }));

      setRound(newArr);
    } else {
      setRound([]);
    }
  }, [surveyList, inProgressSurveyNo]);

  // select  변경 이벤트
  const onChange = (e, option, setOption, type) => {
    const value = e.target.ariaValueText;
    const newArr: any = [];
    option.map((opt) => {
      newArr.push({ ...opt, text: opt.text, value: opt.value, active: value === String(opt.value) });
    });
    setOption(newArr);
    // 사업장 변경시
    if (type === 'project') {
      sessionStorage.setItem('projectId', value); // 선택한 프로젝트ID 세션에 저장
      setShowCropInfo(false);
    }
    // 회차 변경시
    if (type === 'round') {
      setShowCropInfo(false);
    }
  };

  // // 지도에 farmmap 필지 폴리곤 올리기
  // useEffect(() => {
  //   if (isObjectReady) {
  //     farmMapData.mutate(param, {
  //       onSuccess: (res: any) => {
  //         objectFarmMap?.draw(res);
  //         objectFarmMap?.addClickFeature();
  //         // console.log(res.datas[0].output.farmmapData.data[0].geometry);
  //       },
  //     });
  //   }
  // }, [isObjectReady, siteList.filter(({ active }) => active)[0]?.value]);
  //첫번째 페이지
  useEffect(() => {
    if (!fetchingFarmMapData && farmMapData && isObjectReady) {
      const data = farmMapData.datas;
      objectFarmMap?.draw(data);
      objectFarmMap?.addClickFeature(setOverlayInfo);
      setLoading({ show: false });
      setTimeout(() => {
        setIsReloadShow(false);
      }, 500);
      if (!data.length) {
        //데이터 없을때 얼럿
        setConfirm({ ...confirm, show: true, leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }), message: '선택한 지역에 등록된 필지가 없습니다.' });
      }
    }
    if (fetchingFarmMapData) {
      // console.log('asldmnsa');
      setLoading({ ...loading, show: true, isGif: true });
    }
  }, [farmMapData, isObjectReady, fetchingFarmMapData]);

  //회차 변경시 초기화
  useEffect(() => {
    objectFarmMap?.destroy();
  }, [round.filter(({ active }) => active)[0]?.value]);

  //외부영역 클릭시 차트 닫기
  const handleClickOutSide = (e: MouseEvent) => {
    if (statisticsStatus && !chartRef.current?.contains(e.target as Element)) {
      setStatisticsStatus(false);
    }
  };

  useEffect(() => {
    if (statisticsStatus) document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });
  return (
    <>
      <HeaderStyle.Contain>
        {sessionStorage.getItem('auth') === 'ROLE_ADMIN' ? null : <HeaderStyle.Logo onClick={() => window.location.reload()}></HeaderStyle.Logo>}
        <Select
          radius={sessionStorage.getItem('auth') === 'ROLE_ADMIN' ? '4px' : '0 4px 4px 0'}
          width={264}
          height={48}
          option={siteList}
          $isHeader={true}
          onChange={(e) => onChange(e, siteList, setSiteList, 'project')}
          placeHolder={loadingText_project}
        />
        <HeaderStyle.StatisticsWrap ref={chartRef}>
          <HeaderStyle.StatisticsBtn type={'button'} className={statisticsStatus ? 'active' : ''} onClick={() => setStatisticsStatus(!statisticsStatus)}>
            <span className={'hidden'}>통계 보기</span>
          </HeaderStyle.StatisticsBtn>
          {statisticsStatus ? <Statistics round={round} /> : null}
          <Select width={104} height={48} option={round} style={{ marginLeft: 10 }} onChange={(e) => onChange(e, round, setRound, 'round')} placeHolder={loadingText} />
        </HeaderStyle.StatisticsWrap>
      </HeaderStyle.Contain>
    </>
  );
};

export default Header;
