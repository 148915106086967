import React, { useEffect, useState } from 'react';
import { NextStep, Section } from '../../../../components/atoms/TabContent';
import LabelInput, { LabelInputStyle } from '../../../../components/atoms/LabelInput';
import Button from '../../../../components/atoms/Button';
import IcoStepNext from '../../../../assets/images/button/ico-step-next.svg';
import { useRecoilState } from 'recoil';
import { confirmState, formState } from '../../../../store/Atoms';
import Input from '../../../../components/atoms/Input';
import query from '../../../../hooks/RQuery/utils';
import IcoStepDone from '../../../../assets/images/button/ico-step-done.svg';
import IcoStepDoneDisabled from '../../../../assets/images/button/ico-step-done-disabled.svg';
import { useNavigate } from 'react-router-dom';
import { checkTel } from '../../../../util/regCheck';
const initForm = {
  name: '',
  role: '',
  addr: '',
  detailAddr: '',
  zipcode: null,
  stdg: '',
  tel: '',
  telCheck: false,
  email: '',
  deptName: '',
};
const Step1 = ({ onTogglePopup, onNextStep }) => {
  const companyId = sessionStorage.getItem('companyId');
  const navigate = useNavigate();
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [form, setForm] = useRecoilState(formState);
  const addClient = query.client.useAddClient({ ...form, addr: form.addr + '&' + (form?.detailAddr || ''), companyId: companyId });
  // 다음 단계 버튼 disabled 값
  const isDisabled = () => {
    return !(form.name && form.role && form.zipcode && form.addr && form.stdg && form.tel && form.email && form.deptName);
  };

  //발주처 추가
  const onSubmit = () => {
    setConfirm({
      ...confirm,
      show: true,
      message: '발주처를 등록하시겠습니까?',
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: () => {
        console.log(form);
        addClient.mutate(
          { ...form, addr: form.addr + '&' + (form.detailAddr || ''), companyId: companyId },
          {
            onSuccess: (res: any) => {
              setForm({});
              setConfirm({
                ...confirm,
                show: true,
                message: res?.message,
                rightText: '확인',
                leftText: undefined,
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                  navigate('/client');
                },
              });
            },
            onError: (err) => {
              setConfirm({
                ...confirm,
                show: true,
                // message: '발주처 등록이 실패했습니다.<br/> 관리자에게 문의하세요.',
                message: (err as any)?.response?.data?.message,
                leftText: undefined,
                rightText: '확인',
                rightFunc: () => {
                  setConfirm({ ...confirm, show: false });
                },
              });
            },
          },
        );
      },
    });
  };
  return (
    <div style={{ height: '100%', width: 1130 }}>
      <div className={'cScroll'} style={{ height: 'calc(100% - 102px)' }}>
        <Section>
          <LabelInput
            label={'발주처(기관명)'}
            inputWidth={532}
            inputHeight={48}
            required={true}
            placeholder={'발주처명을 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, name: e.target.value });
            }}
            value={form?.name || ''}
          />
        </Section>
        <Section>
          <LabelInput
            label={'기관 유형'}
            inputWidth={532}
            inputHeight={48}
            required={true}
            placeholder={'기관 유형을 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, role: e.target.value });
            }}
            value={form?.role || ''}
          />
        </Section>
        <Section>
          <LabelInputStyle.Wrap $alignItem={'flex-start'}>
            <LabelInputStyle.Key required={true} $alignItem={'flex-start'}>
              주소
            </LabelInputStyle.Key>
            <div>
              <LabelInputStyle.Wrap style={{ marginBottom: 6 }}>
                <Input readOnly={true} value={Number(form?.zipcode) || ''} width={160} height={48} placeholder={'우편번호'} />
                <Button $buttonType={'small'} text={'주소찾기'} onClick={onTogglePopup} height={48} fontSize={14} padding={20} style={{ marginLeft: 6 }} />
              </LabelInputStyle.Wrap>
              <LabelInputStyle.Wrap style={{ marginBottom: 6 }}>
                <Input readOnly={true} value={form?.addr?.split('&')[0] || ''} width={532} height={48} placeholder={'도로명 또는 지번'} />
              </LabelInputStyle.Wrap>
              <LabelInputStyle.Wrap>
                <Input
                  width={532}
                  height={48}
                  placeholder={'상세 주소'}
                  onChange={(e) => {
                    setForm({ ...form, detailAddr: e.target.value });
                  }}
                  value={form?.detailAddr || ''}
                />
              </LabelInputStyle.Wrap>
            </div>
          </LabelInputStyle.Wrap>
        </Section>
        <Section>
          <LabelInput
            label={'행정구역'}
            inputWidth={532}
            inputHeight={48}
            required={true}
            placeholder={'행정구역을 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, stdg: e.target.value });
            }}
            value={form?.stdg || ''}
          />
        </Section>
        <Section>
          <LabelInput
            label={'전화번호'}
            inputWidth={532}
            inputHeight={48}
            required={true}
            placeholder={'전화번호를 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, tel: e.target.value, telCheck: checkTel(e.target.value) });
            }}
            value={form?.tel || ''}
          />
        </Section>
        <Section>
          <LabelInput
            label={'이메일'}
            inputWidth={532}
            inputHeight={48}
            required={true}
            placeholder={'이메일을 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, email: e.target.value });
            }}
            value={form?.email || ''}
          />
        </Section>
        <Section>
          <LabelInput
            label={'담당부서'}
            inputWidth={532}
            inputHeight={48}
            required={true}
            placeholder={'담당부서를 입력하세요'}
            onChange={(e) => {
              setForm({ ...form, deptName: e.target.value });
            }}
            value={form?.deptName || ''}
          />
        </Section>
      </div>
      <Section>
        <NextStep>
          <Button $buttonType={'large'} text={'저장하기'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={onSubmit} disabled={isDisabled()} $iconDisabled={IcoStepDoneDisabled} />
        </NextStep>
      </Section>
    </div>
  );
};

export default Step1;
