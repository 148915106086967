import React, { useEffect, useState } from 'react';
import PopupTemplate from './PopupTemplate';
import Button from 'components/atoms/Button';
import styled from 'styled-components';
import HelpMessage from 'components/atoms/HelpMessage';
import IcoHelp from 'assets/images/etc/ico-help-message.svg';
import LabelSelect from 'components/atoms/LabelSelect';
import SearchInput from 'components/molcures/SearchInput';
import ScrollTable from 'components/atoms/ScrollTable';
import Checkbox from 'components/atoms/Checkbox';
import query from 'hooks/RQuery/utils';

const Content = {
  Inner: styled.div`
    padding: 24px 0;
  `,
  Tip: styled.div`
    width: 100%;
    border-bottom: 1px solid #e5e5ec;
    padding-bottom: 20px;
    margin-bottom: 12px;
    strong {
      color: #001a21;
      font-weight: 600;
      font-size: 16px;
      b {
        color: #00b074;
        font-weight: 600;
      }
    }
  `,
  SearchWrap: styled.div`
    height: 48px;
    margin-bottom: 12px;
  `,
  TableWrap: styled.div`
    height: 420px;
  `,
};

/*  작물 정보 불러오기 팝업 */
function PopupAddCrop({ onClose, inspectorCompanyId, inspectorCompanyName, setCodeData }) {
  const [searchText, setSearchText] = useState(''); // 검색어
  const [cropList, setCropList] = useState<any[]>([]); //팝업 내부에서 사용할 작물 리스트(checked)
  const [isAllCheck, setIsAllCheck] = useState(false); // 전체 선택 유무
  // 발주처별 작물코드 조회
  const { data: inspectorCropList } = query.client.useGetCrops({ inspectorCompanyId });

  useEffect(() => {
    const cropArr = inspectorCropList?.filter(({ name1 }) => name1 !== '기타');
    console.log(cropArr);
    if (cropArr?.length > 0) {
      setCropList(cropArr?.map((ele) => ({ ...ele, checked: false, isShow: true })));
    }
  }, [inspectorCropList]);

  // 체크된수 === 전체 수 같은지 확인 후 전체체크박스 체크선택/해제
  useEffect(() => {
    const isAllCheck = cropList?.length > 0 && cropList.filter(({ checked }) => checked).length === cropList?.length;
    if (isAllCheck) {
      setIsAllCheck(true);
    } else {
      setIsAllCheck(false);
    }
  }, [cropList]);

  //전체 선택/해제
  const onCheckAll = (e) => {
    const { checked } = e.target;
    setCropList((prev) => [...prev]?.map((ele) => ({ ...ele, checked })));
  };

  //체크박스 클릭
  const onCheck = (e) => {
    const { id, checked } = e.target;
    setCropList((prev) => [...prev]?.map((ele, index) => (`crop_code_${index}` === id ? { ...ele, checked } : ele)));
  };

  // 검색창 입력값 저장
  const onChangeSearch = (e) => {
    setSearchText(e.target.value);
  };

  // 검색버튼
  const onClickSearch = () => {
    if (searchText) {
      setCropList((prev) => [...prev].map((ele) => ({ ...ele, isShow: ele?.name3?.includes(searchText) || ele?.code3?.includes(searchText) })));
    } else {
      setCropList((prev) => [...prev].map((ele) => ({ ...ele, isShow: true })));
    }
  };

  // 추가하기 버튼
  const onClickConfirm = () => {
    let newItems = cropList
      ?.filter(({ checked }) => checked)
      ?.map((ele) => ({
        CODE1: ele.code3.substring(0, 2),
        CODE2: ele.code3.substring(0, 4),
        CODE3: ele.code3,
        DISPLAY_NM: ele.name3,
        NM1: ele.name1,
        NM2: ele.name2,
        NM3: ele.name3,
        inspectorCropId: ele.inspectorCropId,
        checked: false,
        isDuplicated: false,
      }));
    // 부모 전달
    setCodeData((prev) => [...prev, ...newItems]);
    // 창닫기
    onClose();
  };

  return (
    <>
      <PopupTemplate
        //   wrapStyle={{ overflow: 'visible' }}
        title={'작물 정보 추가'}
        width={442}
        isDimmed={true}
        onClose={onClose}
        footer={<Button width={'full'} text={`추가하기(${cropList?.filter(({ checked }) => checked)?.length})`} onClick={onClickConfirm} />}
        content={
          <Content.Inner>
            <Content.Tip>
              <strong>
                발주처 <b>{`[${inspectorCompanyName}]`}</b>의 작물 분류를 기본으로 가져옵니다.
              </strong>
            </Content.Tip>
            <Content.SearchWrap>
              <SearchInput inputWidth={319} height={48} placeholder={'작물명 또는 작물코드로 검색'} onChange={onChangeSearch} onClick={onClickSearch} />
            </Content.SearchWrap>
            <Content.TableWrap>
              <ScrollTable
                $hasBorder={false}
                $minHeight={'calc(100%)'}
                $maxHeight={'calc(100%)'}
                caption={'작물 정보'}
                colgroup={[40, 'auto', 175]}
                thead={
                  <tr>
                    <th scope={'col'} style={{ paddingLeft: 24 }}>
                      <Checkbox id={'checkAll_addCrop'} onChange={onCheckAll} checked={isAllCheck} />
                    </th>
                    <th scope={'col'} style={{ paddingLeft: 16 }}>
                      작물명
                    </th>
                    <th scope={'col'}>작물코드</th>
                  </tr>
                }
                tbody={
                  <>
                    {cropList?.map((ele, idx) => (
                      <tr key={`crop_${idx}`} style={{ display: ele.isShow ? 'table-row' : 'none' }}>
                        <td style={{ paddingLeft: 24 }}>
                          <Checkbox size={18} id={`crop_code_${idx}`} checked={ele.checked} onChange={onCheck} />
                        </td>
                        <td style={{ paddingLeft: 16 }}>
                          <p>{ele.name3}</p>
                        </td>
                        <td>
                          <p>{ele.code3}</p>
                        </td>
                      </tr>
                    ))}
                  </>
                }
              />
            </Content.TableWrap>
          </Content.Inner>
        }
      />
    </>
  );
}

export default PopupAddCrop;
