import React, { useEffect, useState } from 'react';
import AdminSubContainer from '../../../components/templates/Container/AdminSubContainer';
import { useRecoilState } from 'recoil';
import { confirmState, formState } from '../../../store/Atoms';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PopupPostCode from '../../../popup/PopupPostCode';
import { NextStep, Section, TabContentStyle } from '../../../components/atoms/TabContent';
import LabelValue from '../../../components/atoms/LabelValue';
import Button from '../../../components/atoms/Button';
import LabelInput from '../../../components/atoms/LabelInput';
import LabelSelect from '../../../components/atoms/LabelSelect';
import IcoStepDone from '../../../assets/images/button/ico-step-done.svg';
import query from '../../../hooks/RQuery/utils';
import { checkEmail, checkPassword } from '../../../util/regCheck';

const InspectorDetail = () => {
  const companyId = sessionStorage.getItem('companyId');
  const navigate = useNavigate();
  const location = useLocation();
  const [form, setForm] = useRecoilState(formState);
  const [isEditMode, setIsEditMode] = useState(false);
  const [confirm, setConfirm] = useRecoilState(confirmState);
  const [checkId, setCheckId] = useState({
    isError: false,
    message: '',
  });
  const removeUser = query.member.useRemoveMember({ memberIds: [form.memberId] });
  const modifyUser = query.member.useModifyMember({ ...form, companyId });
  const checkDuplicateEmail = query.member.useDuplicateEmail(form.email);

  useEffect(() => {
    setForm({ ...location.state.info, password: null, passwordConfirm: null, passwordCheck: true, emailCheck: false });
    return () => {
      setForm({});
    };
  }, []);

  //수정모드 토글
  const onToggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  //이메일 중복체크
  const onCheckEmail = () => {
    if (checkEmail(form.email)) {
      checkDuplicateEmail.refetch();
    } else {
      setConfirm({ ...confirm, show: true, message: '이메일 형식이 올바르지 않습니다.', leftText: undefined, rightText: '확인', rightFunc: () => setConfirm({ ...confirm, show: false }) });
    }
  };

  useEffect(() => {
    if (form.email) {
      if (checkDuplicateEmail.isSuccess) {
        setForm({ ...form, emailCheck: true });
        setCheckId({ isError: false, message: checkDuplicateEmail.data.message });
      } else if (checkDuplicateEmail.isError) {
        setForm({ ...form, emailCheck: false });
        setCheckId({ isError: true, message: (checkDuplicateEmail.error as any)?.response?.data?.message });
      }
    }
  }, [checkDuplicateEmail.data, checkDuplicateEmail.error]);

  //계정삭제
  const onRemove = () => {
    setConfirm({
      ...confirm,
      show: true,
      message: `'${form.email}'<br/>계정을 삭제하시겠습니까?`,
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: () => {
        removeUser.mutate(form, {
          onSuccess: () =>
            setConfirm({
              ...confirm,
              show: true,
              leftText: undefined,
              rightText: '확인',
              message: '삭제하였습니다.',
              rightFunc: () => {
                setConfirm({ ...confirm, show: false });
                navigate('/inspector');
              },
            }),
          onError: () => setConfirm({ ...confirm, show: true, leftText: undefined, rightText: '확인', message: '삭제 실패. 관리자에게 문의하세요.', rightFunc: () => setConfirm({ ...confirm, show: false }) }),
        });
      },
    });
  };

  //정보 수정
  const onModify = () => {
    setConfirm({
      ...confirm,
      show: true,
      message: `'${form.email}'<br/>계정을 수정하시겠습니까?`,
      leftText: '취소',
      leftFunc: () => setConfirm({ ...confirm, show: false }),
      rightText: '확인',
      rightFunc: () => {
        modifyUser.mutate(
          { ...form, companyId },
          {
            onSuccess: () =>
              setConfirm({
                ...confirm,
                show: true,
                leftText: undefined,
                rightText: '확인',
                message: '수정하였습니다.',
                rightFunc: () => {
                  setForm({});
                  setConfirm({ ...confirm, show: false });
                  navigate('/inspector');
                },
              }),
            onError: () => setConfirm({ ...confirm, show: true, leftText: undefined, rightText: '확인', message: '수정 실패. 관리자에게 문의하세요.', rightFunc: () => setConfirm({ ...confirm, show: false }) }),
          },
        );
      },
    });
  };

  //버튼 disabled값
  const buttonState = () => {
    if (!!form.password) {
      return !(form.passwordCheck && form.password === form.passwordConfirm);
    } else {
      return false;
    }
  };

  return (
    <AdminSubContainer
      title={isEditMode ? '검수자 정보 수정' : '검수자 정보'}
      onNavigate={() => navigate('/inspector')}
      content={
        <TabContentStyle.Content style={{ height: 'calc(100% - 40px)' }}>
          <TabContentStyle.Inner>
            <TabContentStyle.ContBox>
              {isEditMode ? (
                <>
                  <Section style={{ width: 446 }}>
                    <LabelInput
                      label={'이메일(ID)'}
                      $labelWidth={126}
                      inputWidth={217}
                      inputHeight={48}
                      required={true}
                      placeholder={'이메일을 입력하세요'}
                      $error={checkId.isError}
                      message={checkId.message}
                      value={form?.email || ''}
                      onChange={(e) => {
                        setForm({ ...form, email: e.target.value, emailCheck: false });
                        setCheckId({ isError: true, message: '' });
                      }}
                      rightButton={<Button $buttonType={'medium'} text={'중복 확인'} width={92} height={48} style={{ marginLeft: 6 }} onClick={onCheckEmail} disabled={form.emailCheck} />}
                    />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelInput
                      label={'이름'}
                      $labelWidth={126}
                      inputWidth={315}
                      inputHeight={48}
                      required={true}
                      placeholder={'이름을 입력하세요'}
                      value={form?.name || ''}
                      onChange={(e) => {
                        setForm({ ...form, name: e.target.value });
                      }}
                    />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelValue label={'발주처'} value={location.state?.info?.companyName || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={126} fontSize={16} />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelInput
                      type={'password'}
                      label={'비밀번호'}
                      $labelWidth={126}
                      inputWidth={315}
                      inputHeight={48}
                      required={true}
                      placeholder={'비밀번호를 입력하세요'}
                      value={form?.password || ''}
                      // $error={form.password && !form.passwordCheck}
                      // message={form.password ? (form.passwordCheck ? '사용 가능한 비밀번호 입니다.' : '영문/숫자( / 특수문자) 조합 8자리 이상 조합') : ''}
                      onChange={(e) => {
                        setForm({ ...form, password: e.target.value === '' ? null : e.target.value, passwordCheck: true });
                      }}
                    />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelInput
                      type={'password'}
                      label={'비밀번호 확인'}
                      $labelWidth={126}
                      inputWidth={315}
                      inputHeight={48}
                      required={true}
                      placeholder={'비밀번호 확인'}
                      value={form?.passwordConfirm || ''}
                      disabled={!form.password}
                      onChange={(e) => {
                        setForm({ ...form, passwordConfirm: e.target.value === '' ? null : e.target.value });
                      }}
                    />
                    <NextStep>
                      <Button $buttonType={'large'} text={'수정 완료'} $icoPosition={'right'} icon={IcoStepDone} $iconSize={16} $iconSpacing={6} onClick={onModify} disabled={buttonState()} />
                    </NextStep>
                  </Section>
                </>
              ) : (
                <>
                  <Section style={{ width: 446 }}>
                    <LabelValue label={'이메일(ID)'} value={location.state?.info?.email || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelValue label={'이름'} value={location.state?.info?.name || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelValue label={'발주처'} value={location.state?.info?.companyName || ''} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
                  </Section>
                  <Section style={{ width: 446 }}>
                    <LabelValue label={'비밀번호'} value={'● ● ● ● ● ● ● ●'} $labelColor={'#001A21'} $valueColor={'#001A21'} $labelWidth={155} fontSize={16} />
                  </Section>
                  <div style={{ display: 'flex', marginTop: 20 }}>
                    <Button $buttonType={'large'} text={'수정하기'} style={{ marginRight: 8 }} onClick={onToggleEditMode} />
                    <Button $colorType={'orange'} text={'계정 삭제'} onClick={onRemove} />
                  </div>
                </>
              )}
            </TabContentStyle.ContBox>
          </TabContentStyle.Inner>
        </TabContentStyle.Content>
      }
    />
  );
};

export default InspectorDetail;
