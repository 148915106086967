import React, { useState, useEffect } from 'react';

function useDelayedLoadingText(isFetching, delay = 1000, text = '로딩중') {
  const [loadingText, setLoadingText] = useState('');

  useEffect(() => {
    let timeoutId;

    if (isFetching) {
      // 딜레이 후 로딩 텍스트 설정
      timeoutId = setTimeout(() => {
        setLoadingText(text);
      }, delay);
    } else {
      // fetching이 끝나면 로딩 텍스트 초기화
      setLoadingText('');
      clearTimeout(timeoutId);
    }

    return () => clearTimeout(timeoutId); // 클린업 함수로 타임아웃 제거
  }, [isFetching, delay, text]);

  return loadingText;
}

export default useDelayedLoadingText;
