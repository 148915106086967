import React from 'react';
import styled from 'styled-components';
import IcoReload from '../../../assets/images/button/ico-reload.svg';

const ReloadMap = styled.button`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: 0.4s;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 50px;
  padding: 0 20px;
  transform: translateX(-50%);
  height: 48px;
  background: #00b074;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  z-index: 1;
  &.active {
    visibility: visible;
    opacity: 1;
    transition: 0.4s 0.4s;
  }
  &:before {
    content: '';
    width: 24px;
    height: 24px;
    background: url(${IcoReload}) no-repeat center;
    margin-right: 6px;
  }
  &:hover {
    background: #008d5d;
  }
  &:active {
    background: #0e7d5f;
  }
`;
const ReloadMapData = ({ onClick, isReloadShow }) => {
  return (
    <ReloadMap type={'button'} onClick={onClick} className={isReloadShow ? 'active' : ''}>
      필지 데이터 새로고침
    </ReloadMap>
  );
};

export default ReloadMapData;
