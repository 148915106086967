import OlCore from '../OlCore2';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import FarmMapModule from '../Layer/FarmMapModule2';
import Style from 'ol/style/Style';
import { Fill } from 'ol/style.js';
import { FARM_STYLE } from '../Style/PolygonStyle';
import Select from 'ol/interaction/Select.js';
import { click } from 'ol/events/condition';
import { Overlay } from 'ol';
import WKT from 'ol/format/WKT';

class ObjectFarmMap extends FarmMapModule {
  private Farm01Layer!: VectorLayer<VectorSource>;
  private Farm01Source: VectorSource = new VectorSource();
  private clickEvent = new Select();

  constructor(core: OlCore) {
    super(core);

    //팜맵
    this.Farm01Layer = new VectorLayer<VectorSource>({
      properties: {
        id: 'Farm01',
      },
      visible: true,
      zIndex: 90,
      // minZoom: 15.6,
      // maxZoom: Infinity,
      style: (feature, resolution) => {
        return FARM_STYLE.DEFAULT(feature, resolution, this.core.mapInstance.getView().getZoom() as number, true);
      },
    });
    this.setLayers([this.Farm01Layer]);
  }

  public draw(data) {
    const polygonGroup: any = [];
    data?.map((i) => {
      // i.geometry.map((geom) => {
      //   const newArr: any = [];
      //   geom.xy.map((coords: any) => {
      //     newArr.push([coords.x, coords.y]);
      //   });
      //   polygonGroup.push({ ...i, geometry: [[newArr]] });
      // });
      const wktFormat = new WKT();
      const wktString = i.geometry;
      console.log(i);
      const feature = wktFormat.readFeature(wktString);
      feature.setId(i.info.id);
      feature.setProperties({ property: i });
      polygonGroup.push(feature);
    });
    // const features = this.createFarmMapFeatures(polygonGroup);
    this.Farm01Source = this.createSource(polygonGroup);
    this.Farm01Layer.setSource(this.Farm01Source);

    //  !this.Farm01Source.getExtent().includes(Infinity) && this.core.mapInstance.getView().fit(this.Farm01Source.getExtent(), { duration: 400, maxZoom: 16 });
  }

  public addFeatures(data) {
    const polygonGroup: any = [];
    data.data.map((i) => {
      i.geometry.map((geom) => {
        const newArr: any = [];
        geom.xy.map((coords: any) => {
          newArr.push([coords.x, coords.y]);
        });
        polygonGroup.push({ ...i, geometry: [[newArr]] });
      });
    });
    const features = this.createFarmMapFeatures(polygonGroup);
    this.Farm01Source.addFeatures(features);
  }

  public destroy() {
    this.Farm01Source = new VectorSource();
    this.Farm01Layer.setSource(this.Farm01Source);
  }

  public addClickFeature(callback?: any) {
    this.core.mapInstance.removeInteraction(this.clickEvent); // 초기화
    this.clickEvent = new Select({
      condition: click,
      layers: [this.Farm01Layer],
      style: (feature, resolution) => FARM_STYLE.ACTIVE(feature, resolution, this.core.mapInstance.getView().getZoom() as number),
    });

    this.clickEvent.on('select', (e) => {
      const targetCollection = e.target.getFeatures().getArray();
      if (targetCollection.length) {
        // 콜백 함수 TODO 필지정보 형태 확정시 callback 작업 예정
        callback(targetCollection[0].getProperties().property);
        //선택한 폴리곤의 중심점 값
        const interiorPoint = targetCollection[0].getGeometry().getInteriorPoints().getCoordinates()[0];

        //오버레이 위치 이동
      } else {
        //콜백 함수
        callback(null);
        //오버레이 위치 제거
      }
    });

    this.core.mapInstance.addInteraction(this.clickEvent);
  }

  public clearClickFeature() {
    this.clickEvent.getFeatures().clear();
  }
  public moveTo(id) {
    const feature = this.Farm01Source.getFeatures().filter((feature) => feature.getProperties().property.surveyFarmMapId === id)[0];
    if (feature) {
      this.core.mapInstance.getView().fit((feature?.getGeometry() as any)?.getExtent(), { duration: 500, maxZoom: 19 });
    }
  }

  public moveToLast(id) {
    console.log(id);
  }

  public moveToAll(datas) {}
}

export default ObjectFarmMap;
