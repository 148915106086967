import OlCore from '../OlCore';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import FarmMapModule from '../Layer/FarmMapModule';
import Style from 'ol/style/Style';
import { Fill } from 'ol/style.js';
import { FARM_STYLE } from '../Style/PolygonStyle';
import Select from 'ol/interaction/Select.js';
import { click } from 'ol/events/condition';
import { Overlay } from 'ol';
import WKT from 'ol/format/WKT';

class ObjectFarmMap extends FarmMapModule {
  private Farm01Layer!: VectorLayer<VectorSource>;
  private Farm01Source: VectorSource = new VectorSource();
  private clickEvent = new Select();
  private lotOverlay: Overlay = new Overlay({
    positioning: 'bottom-center',
    element: document.getElementById('lotOverlay') as any,
  });
  constructor(core: OlCore) {
    super(core);

    //팜맵
    this.Farm01Layer = new VectorLayer<VectorSource>({
      properties: {
        id: 'Farm01',
      },
      visible: true,
      zIndex: 90,
      // minZoom: 15.6,
      // maxZoom: Infinity,
      style: (feature, resolution) => {
        return FARM_STYLE.DEFAULT(feature, resolution, this.core.mapInstance.getView().getZoom() as number);
      },
    });
    this.core.mapInstance.addOverlay(this.lotOverlay);
    this.setLayers([this.Farm01Layer]);
  }

  public draw(data) {
    const polygonGroup: any = [];
    data?.map((i, index) => {
      const wktFormat = new WKT();
      const wktString = i.geometry;
      const feature = wktFormat.readFeature(wktString);
      feature.setId(i.surveyFarmMapId);
      feature.setProperties({ property: i });
      polygonGroup.push(feature);
    });
    this.Farm01Source = this.createSource(polygonGroup);
    this.Farm01Layer.setSource(this.Farm01Source);
    console.log(this.Farm01Source.getExtent());

    !this.Farm01Source.getExtent().includes(Infinity) && this.core.mapInstance.getView().fit(this.Farm01Source.getExtent(), { duration: 400, maxZoom: 14.5 });
  }

  public addFeatures(data) {
    const polygonGroup: any = [];
    data.map((i) => {
      i.geometry.map((geom) => {
        const newArr: any = [];
        geom.xy.map((coords: any) => {
          newArr.push([coords.x, coords.y]);
        });
        polygonGroup.push({ ...i, geometry: [[newArr]] });
      });
    });
    const features = this.createFarmMapFeatures(polygonGroup);
    this.Farm01Source.addFeatures(features);
  }

  public destroy() {
    this.Farm01Source = new VectorSource();
    this.Farm01Layer.setSource(this.Farm01Source);
  }

  public addClickFeature(callback?: any) {
    this.core.mapInstance.removeInteraction(this.clickEvent); // 초기화
    this.clickEvent = new Select({
      condition: click,
      layers: [this.Farm01Layer],
      style: (feature, resolution) => FARM_STYLE.ACTIVE(feature, resolution, this.core.mapInstance.getView().getZoom() as number),
    });

    this.clickEvent.on('select', (e) => {
      const targetCollection = e.target.getFeatures().getArray();
      if (targetCollection.length) {
        // 콜백 함수 TODO 필지정보 형태 확정시 callback 작업 예정
        callback(targetCollection[0].getProperties().property);
        //선택한 폴리곤의 중심점 값
        const interiorPoint = targetCollection[0].getGeometry().getInteriorPoints().getCoordinates()[0];

        //오버레이 위치 이동
        this.lotOverlay.setPosition(interiorPoint);
        this.core.mapInstance.getView().fit([interiorPoint[0], interiorPoint[1], interiorPoint[0], interiorPoint[1]], { duration: 400, maxZoom: this.core.mapInstance.getView().getZoom() });
      } else {
        //콜백 함수
        callback(null);
        //오버레이 위치 제거
        this.lotOverlay.setPosition(undefined);
      }
    });

    this.core.mapInstance.addInteraction(this.clickEvent);
  }

  public clearClickFeature() {
    this.clickEvent.getFeatures().clear();
    this.lotOverlay.setPosition(undefined);
  }

  public changeStatus(list, type, isCount) {
    //이미지 갯수를 변경할때
    if (isCount) {
      list.map((i) => {
        const sameFeature = this.Farm01Source.getFeatures().find((j) => j.getProperties().property.surveyFarmMapId === i.surveyFarmMapId);
        sameFeature?.setProperties({ ...sameFeature.getProperties(), property: { ...sameFeature.getProperties().property, wideImageCount: 1, zoomImageCount: 1 } });
      });
    } else {
      //필지 상태를 변경할 때
      list.map((i) => {
        const sameFeature = this.Farm01Source.getFeatures().find((j) => j.getProperties().property.surveyFarmMapId === i.surveyFarmMapId);
        sameFeature?.setProperties({ ...sameFeature.getProperties(), property: { ...sameFeature.getProperties().property, status: type === 1 ? 'FILL_RATE' : type === 2 ? 'REMAIN_RATE' : 'INSPECT' } });
      });
    }
    console.log(this.Farm01Source.getFeatures());
  }
}

export default ObjectFarmMap;
